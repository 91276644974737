<template>
  <div>

    <Timeline v-if="history && history.length" :value="history">
      <template #opposite="{ item }">
        <div
          v-for="one in item.list"
          :key="one.at"
        >
          <div class="block text-left font-bold">{{ one.path }}</div>
          <br/>
          <div class="grid">
            <div class="col-6 text-left bg-pink-100 p-text-secondary">{{one.from}}</div>
            <div class="col-6 text-left bg-green-100">{{one.to}}</div>
          </div>
          <Divider />  
        </div>
      </template>

      <template #content="{ item }">
        <div class="text-caption">{{ $t('forms.createdBy') }} {{ item.user.username }}</div>
        <small class="p-text-secondary">{{ $elapsedTime(item.at) }}</small>
      </template>

    </Timeline>
    <div v-else>
      <p class="text-center p-text-secondary">
        No history available
      </p>
    </div>
  </div>
</template>

<script>
import Timeline from 'primevue/timeline'
import Divider from 'primevue/divider'

export default {
  name: 'EditingHistory',
  components: { Timeline, Divider },
  props: ['docProperty'],
  data: () => ({
    history: []
  }),
  async mounted () {
    let docId = this.docProperty?.id || this.$route.params.id
    if (!this.$pxstream.tools.isGeneratedId(docId)) {
      const data = await this.$pxstream.portalmanager.getMediaHistory(docId)
      if (data) {
        this.history = data.list
      }
    }
  }
  
}
</script>
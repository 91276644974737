<template>
  <div id="mediaMore__details">
    <h4>{{ mediaFileMore.filename }}</h4>
    <div class="p-datatable-wrapper">
      <table role="table" class="p-datatable-table">
        <tbody class="p-datatable-tbody" role="rowgroup">

          <div class="p-datatable-separation" />
          <ViewRow label="Lab" field="name" :data="mediaFileMore.lab" />
          <ViewRow label="Codec" :data="mediaFileMore.content.codec" />
          <ViewBitrateRow label="Bitrate" :bitrate="mediaFileMore.content.bitrate" />

          <div class="p-datatable-separation" />
          <ViewFlagRow label="Audio Track" field="id" :flags="[mediaFileMore.content.language]" flagWidth="25px" flagHeight="20px"/>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ViewRow from '../tableRows/ViewRow.vue'
import ViewFlagRow from '../tableRows/ViewFlagRow.vue'
import ViewBitrateRow from '../tableRows/ViewBitrateRow.vue'

export default {
  props: {
    mediaFileMore: {
    type: Object,
    default: () => {}
    }
  },
  components: {
    ViewRow,
    ViewFlagRow,
    ViewBitrateRow
  }
}
</script>

<style lang="postcss" scoped>
  h4 {
    font-weight: bold;
  }

  .p-datatable-table * {
    border : 0px !important;
  }

  .p-datatable-separation {
  margin-top: 1rem;
  }

</style>
<template>
  <div>
    <Dialog
    v-model:visible="showIt"
    style="width:400px"
    @hide="hide();resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Add Episode</h3>
        </div>
      </template>

      <div class="flex justify-content-center mb-2">
        <SelectButton
        class="p-primary-button"
          v-model="mode" :options="modes"
        />
      </div>

      <form class="p-fluid" @keyup.enter="createOrAdd">

        <div class="fluid" v-if="!addNew">

          <div class="field">
            <label for="title" :class="{'p-error':mediaError}">{{ $t('forms.title') }}</label>
            <AutoCompleteSearch
              v-model="media"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              :suggestions="listingMedias"
              :suggestionsTotal="listingMediasTotal"
              @complete="searchMedia($event)"
            />
            <small v-if="mediaError" class="p-error">{{mediaError}}</small>
          </div>
        </div>

        <div class="fluid" v-if="addNew">
          <div class="field">
            <label for="media" :class="{'p-error':mediaError}">{{ $t('forms.title') }}</label>
            <InputText id="media" type="text"
              v-model="media"
              :class="{'p-invalid': mediaError}"
              :disabled="isLoading"
            />
            <small v-if="mediaError" class="p-error">{{mediaError}}</small>
          </div>
        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="px-button-cancel p-button-text" @click="showIt = false"/>
          <Button :label="addNew ? $t('actions.create') : $t('actions.add')" icon="pi pi-check" :loading="isLoading" @click="createOrAdd"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import SelectButton from 'primevue/selectbutton';
import InputText from "primevue/inputtext"
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import Message from "primevue/message"
import { mapGetters } from 'vuex'
import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"

import { useFlowBuilder } from '@/compositions/useFlowEditor'
import Log from '@/services/logger'

const EXISTING_MEDIA = 'Existing Media'
const NEW_MEDIA = 'New Media'

const KFlow = "portalmanager:media"

export default {
  name: 'EpisodeNew',
  inject: {
    updateRuntime: {
      default: () => {}
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  props: ['modelValue', 'doc', 'editorKey', 'editorRoot'],
  emits: ['update:modelValue', 'onEpisodeAdded'],
  components: {
    InputText, Message, SelectButton, AutoCompleteSearch
  },
  data () {
    return {
      contentFormat: '',
      contentType: '',
      media: null,
      defaultLanguage: '',
      mode: EXISTING_MEDIA,
      modes: [EXISTING_MEDIA, NEW_MEDIA],
      listingMedias: [],
      listingMediasTotal: 0,
      showIt: false,
      isLoading: false,
      errorCaught: ''
    }
  },

  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.media = null
      this.defaultLanguage = this.doc.defaultLanguage
      this.contentType = this.tvFormat.contentTypes.find((t) => t.name.includes('Simple'))
    },

    async searchMedia ({ query }) {
      if (query.length >= 3) {
        try {
          const qryBuilder = this.$pxstream.tools.createQueryBuilder()
          qryBuilder.setFilterSearch(query)
          // TODO: C'est horrible ô mon dieu...
          qryBuilder.addFilter('contentType.name', 'contains', ['TV Simple'])
          qryBuilder.sortField = 'name'
          qryBuilder.sortOrder = 1
          const {data, total} = await this.$pxstream.portalmanager.getMediaList(qryBuilder.build())
          this.listingMedias = data
          this.listingMediasTotal = total
        } catch (err) {
          this.$toast.add({severity: 'error', summary: 'Failed to get medias', detail: err.toString(), life: 4000})
        }
      }
    },

    addNewMedia () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.portalmanager.createEmptyMedia({
        name: this.media,
        contentType: this.contentType.id,
        defaultLanguage: this.doc.defaultLanguage.id
      })
      .then(({data}) => {

        data.id = 'new'
        const { saveFlowBuild } = useFlowBuilder(KFlow, data, {
          editorMain: this.editorKey,
          initFields: {
            from: this.editorRoot,
            list: [
              { field: 'media.year' },
              { field: 'media.cast' },
              { field: 'media.countries' },
              { field: 'media.directors' },
              { field: 'media.genres' },
              { field: 'media.rating' },
              { field: 'media.ratingUS' },
              { field: 'media.hasSex' },
              { field: 'media.hasViolence' },
              { field: 'media.hasOffensiveLanguage' },
              { field: 'media.version' },
              { field: 'media.trailer' },
              { field: 'licensor' },
              { field: 'contentOwner' },
              { field: 'provider' },
              { field: 'copyright' },
            ]
          },
          onCreate: function onCreate (doc) {
            return {
              name: doc.name,
              contentType: doc.contentType.id,
              defaultLanguage: doc.defaultLanguage.id
            }
          },
          onFieldChange: (field, value, editorKey) => {
            if (field === 'media.runtime') {
              this.updateRuntime(editorKey, value)
            }
          },
          actionFields: [
            { field: 'media.mediafiles', action: 'links-to-ids' },
            { field: 'media.tracks', action: 'links-to-ids' },
            { field: 'media.seasons', action: 'links-to-ids' }
          ]
        })

        const req = saveFlowBuild()
        this.$emit('onEpisodeAdded', req)

        this.hide()
      })
      .catch((err) => {
        Log.Error(err)
        this.errorCaught = err.message
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    addExistingMedia () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.portalmanager.getMediaOne(this.media.id)
      .then(({data}) => {

        const { saveFlowBuild } = useFlowBuilder(KFlow, data, {
          editorMain: this.editorKey,
          onFieldChange: (field, value, editorKey) => {
            if (field === 'media.runtime') {
              this.updateRuntime(editorKey, value)
            }
          },
          onCreate: function onCreate (doc) {
            return {
              name: doc.name,
              contentType: doc.contentType.id,
              defaultLanguage: doc.defaultLanguage.id
            }
          },
          actionFields: [
            { field: 'media.mediafiles', action: 'links-to-ids' },
            { field: 'media.tracks', action: 'links-to-ids' },
            { field: 'media.seasons', action: 'links-to-ids' }
          ]
        })

        const req = saveFlowBuild()
        this.$emit('onEpisodeAdded', req)

        this.hide()
      })
      .catch (({message}) => {
        this.errorCaugth = message
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    createOrAdd () {
      if (this.mode === NEW_MEDIA) {
        this.addNewMedia()
      } else if (this.mode === EXISTING_MEDIA) {
        this.addExistingMedia()
      }
    },
    hide () {
      this.$emit('update:modelValue', false)
    }
  },

  computed: {
    ...mapGetters('resource', ['tvFormat']),
    mediaError () {
      if (!this.v$.media.$dirty) return ''
      if (this.v$.media.required.$invalid) return this.mode === NEW_MEDIA ? 'Title is required.' : 'Media is required.'
      return ''
    },
    addNew () {
      return this.mode === NEW_MEDIA
    },
    addExisting () {
      return this.mode === EXISTING_MEDIA
    }
  },

  validations: {
    media: { required }
  },

  watch: {
    mode (value) {
      if (value === NEW_MEDIA) {
        this.resetFields()
      }
    },
    showIt (show) {
      if (show) {
        this.resetFields()
      }
    },
    modelValue: function (val) {
      this.showIt = val
    }
  }
}
</script>

import { createRouter, createWebHashHistory } from 'vue-router'

export const LOGIN_PAGE = 'AccountLogin'

export const DASHBOARD_PAGE = 'Dashboard'

export const AIRSAS_PAGE = 'Airsas'
// Admin
export const ADMIN_USER_LISTING_PAGE = 'AdminUsersListing'
export const ADMIN_USER_EDITING_PAGE = 'AdminUsersEditing'
// PortalManager
export const PM_MEDIA_LIBRARY_PAGE = 'PortalManagerMediaLibrary'
export const PM_MEDIA_EDITING_PAGE = 'PortalManagerMediaEditing'
export const PM_MENUS_LISTING_PAGE = 'PortalManagerMenusListing'
export const PM_MENUS_EDITING_PAGE = 'PortalManagerMenusEditing'
export const PM_MENUS_EDITING_PAGE_PREVIOUS = 'PortalManagerMenusEditingPrevious'

// Projects
export const PROJECTS_LISTING_PAGE = 'ProjectsListing'
export const PROJECTS_EDITING_PAGE = 'ProjectsEditing'
// Resources
export const RES_ARTISTS_LISTING_PAGE = 'ResourcesArtists'
export const RES_ARTISTS_EDITING_PAGE = 'ResourcesArtistEditing'
export const RES_ACTORS_LISTING_PAGE = 'ResourcesActors'
export const RES_ACTORS_EDITING_PAGE = 'ResourcesActorEditing'
export const RES_GENRES_LISTING_PAGE = 'ResourcesGenres'
export const RES_GENRES_EDITING_PAGE = 'ResourcesGenreEditing'
export const RES_LANGUAGES_LISTING_PAGE = 'ResourcesLanguages'
export const RES_LANGUAGES_EDITING_PAGE = 'ResourcesLanguageEditing'

const routes = [
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ './layouts/Empty.vue'),
    children: [
      {
        path: 'login',
        name: LOGIN_PAGE,
        component: () => import(/* webpackChunkName: "account" */ './pages/account/Login.vue'),
        meta: {
          guest: true,
          requiresNotLogged: true,
        }
      }
    ]
  },
  {
    path: '/',
    name: 'Root',
    component: () => import(/* webpackChunkName: "root" */ './layouts/Default.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: DASHBOARD_PAGE,
        component: () => import(/* webpackChunkName: "root" */ './pages/Home.vue'),
      },
      {
        path: 'airsas/formats',
        name: AIRSAS_PAGE,
        component: () => import(/* webpackChunkName: "airsas" */ './pages/airsas/FormatListing.vue'),
      },
      {
        path: 'admin/users',
        name: ADMIN_USER_LISTING_PAGE,
        component: () => import(/* webpackChunkName: "admin" */ './pages/admin/users/UsersListing.vue'),
      },
      {
        path: 'admin/users/:id',
        name: ADMIN_USER_EDITING_PAGE,
        component: () => import(/* webpackChunkName: "admin" */ './pages/admin/users/UsersEditing.vue'),
      },
      {
        path: 'portal-manager/media-library',
        name: PM_MEDIA_LIBRARY_PAGE,
        component: () => import(/* webpackChunkName: "portalmanager" */ './pages/portalmanager/media/MediaListing.vue'),
      },
      {
        path: 'portal-manager/media/:id',
        name: PM_MEDIA_EDITING_PAGE,
        component: () => import(/* webpackChunkName: "portalmanager" */ './pages/portalmanager/media/MediaEditing.vue'),
      },
      {
        path: 'portal-manager/menus',
        name: PM_MENUS_LISTING_PAGE,
        component: () => import(/* webpackChunkName: "portalmanager" */ './pages/portalmanager/menus/MenuListing.vue')
      },
      {
        path: 'portal-manager/menus/:id',
        name: PM_MENUS_EDITING_PAGE,
        component: () => import(/* webpackChunkName: "portalmanager" */ './pages/portalmanager/menus/MenuEditing.vue')
      },
      {
        path: 'portal-manager/menus/:id/previous',
        name: PM_MENUS_EDITING_PAGE_PREVIOUS,
        component: () => import(/* webpackChunkName: "portalmanager" */ './pages/portalmanager/menus/MenuEditing.vue')
      },
      {
        path: '/projects/',
        name: PROJECTS_LISTING_PAGE,
        component: () => import(/* webpackChunkName: "projects" */ './pages/projects/ProjectListing.vue')
      },
      {
        path: '/projects/:id',
        name: PROJECTS_EDITING_PAGE,
        component: () => import(/* webpackChunkName: "projects" */ './pages/projects/ProjectEditing.vue')
      },
      {
        path: 'resources/artists',
        name: RES_ARTISTS_LISTING_PAGE,
        component: () => import(/* webpackChunkName: "resources" */ './pages/resources/artists/ArtistListing.vue'),
      },
      {
        path: 'resources/artists/:id',
        name: RES_ARTISTS_EDITING_PAGE,
        component: () => import(/* webpackChunkName: "resources" */ './pages/resources/artists/ArtistEditing.vue'),
      },
      {
        path: 'resources/actors',
        name: RES_ACTORS_LISTING_PAGE,
        component: () => import(/* webpackChunkName: "resources" */ './pages/resources/actors/ActorListing.vue')
      },
      {
        path: 'resources/actors/:id',
        name: RES_ACTORS_EDITING_PAGE,
        component: () => import(/* webpackChunkName: "resources" */ './pages/resources/actors/ActorEditing.vue')
      },
      {
        path: 'resources/genres',
        name: RES_GENRES_LISTING_PAGE,
        component: () => import(/* webpackChunkName: "resources" */ './pages/resources/genres/GenreListing.vue')
      },
      {
        path: 'resources/genres/:id',
        name: RES_GENRES_EDITING_PAGE,
        component: () => import(/* webpackChunkName: "resources" */ './pages/resources/genres/GenreEditing.vue')
      },
      {
        path: 'resources/languages',
        name: RES_LANGUAGES_LISTING_PAGE,
        component: () => import(/* webpackChunkName: "resources" */ './pages/resources/languages/LanguageListing.vue'),
      },
      {
        path: 'resources/languages/:id',
        name: RES_LANGUAGES_EDITING_PAGE,
        component: () => import(/* webpackChunkName: "resources" */ './pages/resources/languages/LanguageEditing.vue'),
      },
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

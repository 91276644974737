import { useFlowBuilder } from './useFlowEditor'

const KFlow = 'portalmanager:menu-item'

export function useFlowPMMenuItemBuilder({propDoc, editorMain, onFieldChange, onUpdateChange}) {
  return useFlowBuilder(KFlow, propDoc, {
    editorMain,
    onCreate: function onCreate (doc) {
      return {
        name: doc.name,
        type: doc.type.id
      }
    },
    onFieldChange: (field, value) => {
      onFieldChange({field, value})
    },
    onUpdateChange: ({hasUpdate, at}) => {
      onUpdateChange({hasUpdate, at})
    },
    actionFields: [
      { field: 'i18n', action: 'to-i18n' },
      { field: 'data.medias', action: 'links-to-ids' },
      { field: 'data.mediafiles', action: 'links-to-ids' },
      { field: 'data.mediafilesTree', action: 'to-raw' },
      { field: 'data.mediafilesSettings', action: 'links-to-ids' }
    ]
  })
}

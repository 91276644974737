<template>
  <InputText :value="notEmpty(modelValue) ? modelValue : ''" @input="$emit('update:modelValue', $event.target.value)" id="year" type="text" class="w-full" />
</template>

<script>
import InputText from 'primevue/inputtext'

export default {
  components: {
    InputText
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  methods: {
    notEmpty (value) {
      return (value && value !== '0')
    }
  }
}
</script>

<template>
  <div class="drag-handler flex flex-row align-items-center">
    <i class="pi pi-bars"></i>
  </div>
</template>

<style scoped>
  .pi-bars {
    font-size: 1.2rem;
    padding: 0 .5rem;
  }
</style>

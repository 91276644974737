<template>
  <tr v-if="value && value !== null" role="row">
    <td class="column-title" role="cell">
      <span>{{label}}:</span>
    </td>
    <td class="column-data" role="cell">
      <span>{{ value }} </span>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, String, Array, Number, Boolean],
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ""
    }
  },
  computed: {
    value () {
      if (typeof this.data == 'string' || typeof this.data == 'number' || typeof this.data == 'boolean' ) {
        return this.data
      } else if (Array.isArray(this.data)) {
        let element = []
        const fields = this.field?.split('.') || []
        try {
          for (let y = 0; y < this.data.length; y++) {
            let currentData = this.data[y]
            if (fields) {
              for (let i = 0; i < fields.length; i++) {
              currentData = currentData[fields[i]]
              }
            }

            element.push(currentData)
          }
        } catch (e) {
          return null
        }
        return element.join(', ')
      } else {
        return this.data[this.field]
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>

import { useFlowBuilder } from './useFlowEditor'

const KFlow = 'portalmanager:menu-mediafile-settings'

export function useFlowPMMenuMediafileSettingsBuilder({propDoc, editorMain, onFieldChange, onUpdateChange}) {
  return useFlowBuilder(KFlow, propDoc, {
    editorMain,
    onCreate: function onCreate (doc) {
      return {
        media: doc.media.id,
        mediafile: doc.mediafile.id
      }
    },
    onFieldChange: (field, value) => {
      onFieldChange({field, value})
    },
    onUpdateChange: ({hasUpdate, at}) => {
      onUpdateChange({hasUpdate, at})
    },
    actionFields: []
  })
}

import pxstream from '@/services/pxstream'
import Log from '@/services/logger'

// https://medium.com/woost/generic-vuex-store-module-1001766a2c83

function __apply (doc, fields, value) {
  if (fields.length === 1) {
    doc[fields[0]] = value
  } else {
    const one = fields.shift()
    __apply(doc[one], fields, value)
  }
}

function apply (doc, field, value) {
  __apply(doc, field.split('.'), value)
}

function __objpath(doc, fields, gen) {
  if (fields.length === 1) {
    if (! doc[fields[0]] || typeof doc[fields[0]] !== 'object') {
      doc[fields[0]] = gen()
    }
    return doc[fields[0]]
  }
  const one = fields.shift()
  doc[one] = doc[one] || {}
  return __objpath(doc[one], fields, gen)
}

function objpath(doc, field) {
  return __objpath(doc, field.split('.'), function () {
    return {}
  })
}

function arraypath(doc, field) {
  return __objpath(doc, field.split('.'), function () {
    return []
  })
}

export default function (flow, onUpdateField, onCreate) {
  return  {
    namespaced: true,
    state: () => ({
      ori: {},
      wip: {},
      byRole: {},
      hasUpdate: false,
      isSaving: false,
      isLoading: false,
      isNew: false,
      hooks: {}
    }),
    getters: {
      doc: state => state.wip,
      isSaving: state => state.isSaving,
      isLoading: state => state.isLoading,
      fieldGet: state => path => {
        const fields = path.split('.')
        let cursor = state.wip
        try {
          for (let i = 0; i < fields.length; i++) {
            cursor = cursor[fields[i]]
          }
        } catch (e) {
          Log(e)
          return null
        }

        return cursor
      },
      hasUpdate: state => state.hasUpdate,
      isNew: state => state.isNew
    },
    mutations: {
      SET_DOC (state, {data, byRole}) {
        state.ori = data
        state.wip = JSON.parse(JSON.stringify(data))
        state.byRole = byRole
      },
      SET_IS_NEW (state, val) {
        state.isNew = val
      },
      SET_IS_SAVING (state, val) {
        state.isSaving = val
      },


      FIELD_SET (state, {field, value}) {
        Log('FIELD_SET', field, value)
        value = onUpdateField(field, value)
        apply(state.wip, field, value)
      },
      FIELD_OBJ_SET (state, {field, key, value}) {
        Log('FIELD_OBJ_SET', field, key, value)
        const ref = objpath(state.wip, field)
        ref[key] = value
      },
      FIELD_OBJ_DEL (state, {field, key}) {
        Log('FIELD_OBJ_DEL', field, key)
        const ref = objpath(state.wip, field)
        delete ref[key]
      },
      FIELD_PUSH (state, {field, value}) {
        Log('FIELD_PUSH', field, value)
        const ref = arraypath(state.wip, field)
        ref.push(value)
      },
      FIELD_SPLICE (state, {field, filter}) {
        Log('FIELD_SPLICE', field, filter)
        const ref = arraypath(state.wip, field)
        apply(state.wip, field, ref.filter(filter))
      },

      CHECK_HAS_UPDATE (state) {
        if (JSON.stringify(state.ori).localeCompare(JSON.stringify(state.wip)) !== 0) {
          state.hasUpdate = true
        } else {
          state.hasUpdate = false
        }
        const patch = pxstream.tools.buildPatch(state.ori, state.wip)
        Log(JSON.parse(JSON.stringify(patch)))
      },
      // Hooks
      REGISTER_ACTION (state, {hook, action, priority}) {
        if (!state.hooks[hook]) {
          state.hooks[hook] = {}
        }
        if (!Array.isArray(state.hooks[hook][priority])) {
          state.hooks[hook][priority] = []
        }
        state.hooks[hook][priority].push(action)
      },
      UNREGISTER_ACTION (state, { hook, priority, index }) {
        if (state.hooks[hook] && state.hooks[hook][priority]) {
          state.hooks[hook][priority].splice(index, 1)
        }
      },
      REGISTER_HOOK (state, hook) {
        if (!state.hooks[hook]) {
          state.hooks[hook] = {}
        }
      },
    },
    actions: {
      fieldSet ({commit}, args) {
        commit('FIELD_SET', args)
        commit('CHECK_HAS_UPDATE')
      },
      fieldObjSet ({commit}, args) {
        commit('FIELD_OBJ_SET', args)
        commit('CHECK_HAS_UPDATE')
      },
      fieldObjDel ({commit}, args) {
        commit('FIELD_OBJ_DEL', args)
        commit('CHECK_HAS_UPDATE')
      },
      fieldPush ({commit}, args) {
        commit('FIELD_PUSH', args)
        commit('CHECK_HAS_UPDATE')
      },
      fieldSplice ({commit}, args) {
        commit('FIELD_SPLICE', args)
        commit('CHECK_HAS_UPDATE')
      },

      update ({commit}, args) {
        commit('FIELD_SET', args)
        commit('CHECK_HAS_UPDATE')
      },
      refresh ({state}, cb) {
        if (cb) cb(state.ori)
      },
      async getDoc ({state, commit, dispatch}, id) {
        state.isLoading = true
        return new Promise((resolve, reject) => {
          if (id === 'new') {
            try {
              const item =  sessionStorage.getItem(`${flow}:new`)
              const data = JSON.parse(item)
              commit('SET_DOC', data)
              commit('CHECK_HAS_UPDATE')
              commit('SET_IS_NEW', true)
              dispatch('refresh')
              state.isLoading = false
              resolve(data)
            } catch (e) {
              Log(`${flow}:new`)
              state.isLoading = false
              reject(e)
            }
          } else {
            pxstream
            .getOne(flow, id)
            .then( ({data}) => {
              commit('SET_DOC', data)
              commit('CHECK_HAS_UPDATE')
              commit('SET_IS_NEW', false)
              dispatch('refresh')
              state.isLoading = false
              resolve(data)
            })
            .catch( err => {
              state.isLoading = false
              reject(err)
            })
          }
        })
      },
      async save ({state, dispatch}) {
        if (state.isNew) {
          return dispatch('__create')
        } else {
          return dispatch('__save')
        }
      },
      async __save ({state, commit, dispatch}) {
        commit('SET_IS_SAVING', true)
        return new Promise((resolve, reject) => {
          const patch = pxstream.tools.buildPatch(state.ori, state.wip)
          pxstream
          .saveOne(flow, state.ori.id, patch)
          .then(() => {
            dispatch('getDoc', state.ori.id)
            .then(() => {
              commit('CHECK_HAS_UPDATE')
              commit('SET_IS_SAVING', false)
              resolve({})
            })
            .catch(err => {
              reject(err)
              commit('SET_IS_SAVING', false)
            })
          })
          .catch(err => {
            reject(err)
            commit('SET_IS_SAVING', false)
          })
        })
      },
      async __create ({state, commit, dispatch}) {
        commit('SET_IS_SAVING', true)
        return new Promise((resolve, reject) => {
          const {patch} = pxstream.tools.buildPatch(state.ori, state.wip)
          const toCreate = onCreate(state.ori)
          pxstream
          .createOne(flow, toCreate, patch)
          .then(({data}) => {
            Log(data)
            dispatch('getDoc', data.id)
            .then(() => {
              commit('CHECK_HAS_UPDATE')
              commit('SET_IS_SAVING', false)
              resolve({redirect: data.id})
            })
            .catch(err => {
              reject(err)
              commit('SET_IS_SAVING', false)
            })
          })
          .catch(err => {
            reject(err.response ? err.response.data : err)
            commit('SET_IS_SAVING', false)
          })
        })
      },
      // Hooks
      addAction ({ state, commit }, {hook, action, priority = 10}) {
        commit('REGISTER_ACTION', {hook, priority, action})
        const index = state.hooks[hook][priority].length - 1

        return {
          unsubscribe() {
            commit('UNREGISTER_ACTION', {hook, priority, index})
          },
        }
      },
      registerHook ({commit}, args) {
        commit('REGISTER_HOOK', args)
      },
      async applyHook ({ state }, hook) {
        if (state.hooks[hook]) {

          const doByPriority = async (priorities) => {
            for (const priority of priorities) {
              await Promise.all(state.hooks[hook][priority].map(async (handler) => {
                return await handler()
              }))
            }
          }

          return doByPriority(Object.keys(state.hooks[hook]))
        }
        return
      }
    }
  }
}

<template>
  <div class="grid">
    <div class="col-12">
      <!-- Name -->
      <div class="field grid">
        <label for="content" class="col-12 md:col-1 font-bold">Content:</label>
        <div class="col md:col-3">
          <span class="p-fluid">
            <AutoComplete id="content" :suggestions="mediaMenuItemSuggestions" v-model="mediaMenuItemSelected" @complete="searchMenuItems($event)" field="name" itemValue="id" dropdown />
          </span>
        </div>
        <div class="col md:col-3 flex justify-content-center" v-tooltip.top="{ value: 'Please select a menu item to pursue', disabled: hasSelectedMenuItem }">
          <Button icon="pi pi-plus" label="Add Media From Library" class="uppercase p-button-outlined" :disabled="!hasSelectedMenuItem || isPreviousCycle" @click="addMediaFromLibrary = true" />
        </div>
        <div class="col md:col-5 flex justify-content-end">
          <Button type="button" icon="pi pi-plus" label="Create new media in library" class="uppercase p-button-outlined" @click="createMedia()" :disabled="isPreviousCycle" />
        </div>
      </div>
    </div>

    <div class="col-12" v-if="hasMediafiles">
      <div class="field grid">
        <div class="col-3 md:col-2">
          <SelectButton id="tableMode" class="p-primary-button" v-model="tableMode" :options="tableModes" />
        </div>
      </div>
    </div>

    <Loader v-if="isSectionLoading" />
    <MenuMediasTableMediafiles v-else-if="hasMediafiles && isMediafileMode" :editorKey="menuItemEditorKey"/>
    <MenuMediasTableMedias v-else-if="hasMediafiles && !isMediafileMode" :editorKey="menuItemEditorKey"/>

    <AddMediaFromLibraryModal v-model:visible="addMediaFromLibrary" :editorMain="editorKey" :editorKey="menuItemEditorKey"/>

    <EditParametersModal v-model:visible="isEditingParameters" :editorKey="settingsEditorKey"/>

    <MediaNew v-model="ui.showCreateMedia" :tabOpen="true"/>
  </div>
</template>

<script>
import { ref, computed, watch, provide, inject } from 'vue'
import { useFlowEditor, useFlowPMMenuItemBuilder, useFlowPMMenuMediafileSettingsBuilder, useFlowCruder } from '@/compositions'
import { useToast } from 'primevue/usetoast'
import AutoComplete from 'primevue/autocomplete'
import SelectButton from 'primevue/selectbutton'

import AddMediaFromLibraryModal from './components/AddMediaFromLibraryModal.vue'
import EditParametersModal from './components/EditParametersModal.vue'

import MenuMediasTableMedias from './components/MenuMediasTableMedias.vue'
import MenuMediasTableMediafiles from './components/MenuMediasTableMediafiles.vue'

import MediaNew from '@/pages/portalmanager/media/components/MediaNew.vue'
import Loader from '@/components/resource/Loader.vue'
import Log from '@/services/logger'

const async = require('async')

const KFlowPortalmanagerMedia = 'portalmanager:media'
const KFlowPortalmanagerMediafile = 'portalmanager:mediafile'

export default {
  name: 'MenuMedias',
  components: {
    AutoComplete,
    SelectButton,
    AddMediaFromLibraryModal,
    EditParametersModal,
    MenuMediasTableMedias,
    MenuMediasTableMediafiles,
    MediaNew,
    Loader
  },
  props: { editorKey: String },
  setup (props) {
    const toast = useToast()
    const { flowGet } = useFlowCruder()
    const isPreviousCycle = inject('isPreviousCycle')
    const addMediaFromLibrary = ref(false)
    const isEditingParameters = ref(false)
    const settingsEditorKey = ref(null)
    const mediaMenuItemSelected = ref(null)
    const fatherType = ref(null)
    const mediaMenuItemSuggestions = ref([])
    const tableModeMedia = 'Media'
    const tableModeMediafiles = 'Mediafiles'
    const tableModes = ref([
      tableModeMedia,
      tableModeMediafiles
    ])
    const tableMode = ref(tableModeMediafiles)

    const mediaLoading = ref({})

    const ui = ref({
      showCreateMedia: false
    })

    const { fieldGet } = useFlowEditor(props.editorKey)

    const mediaMenuItems = computed({
      get () { return Object.values(fieldGet('cycle.sections') || {}).filter(val => val.dataType === 'media') }
    })

    const mediaMenuItemsTree = computed({
      get () { return Object.values(fieldGet('cycle.sectionsTree') || []) }
    })

    const hasSelectedMenuItem = computed(() => {
      return !!mediaMenuItemSelected.value?.id
    })

    const isMediafileMode = computed(() => { return tableMode.value === tableModeMediafiles })

    const menuItemEditor = computed(() => {
      return hasSelectedMenuItem.value ? useFlowPMMenuItemBuilder({
        propDoc: mediaMenuItemSelected.value,
        editorMain: props.editorKey,
        onFieldChange: () => {},
        onUpdateChange: () => {}
      }) : null
    })

    const menuItemEditorKey = computed(() => {
      return menuItemEditor.value ? menuItemEditor.value.editorKey : ''
    })

    const hasMediafiles = computed(() => {
      return menuItemEditor.value?.fieldGet('data.mediafiles')?.length > 0 ? true : false
    })

    const isSectionLoading = computed(() => {
      return mediaMenuItemSelected.value?.id ? mediaLoading.value[mediaMenuItemSelected.value.id].isLoading : false
    })

    const searchMenuItems = ({query}) => {
      mediaMenuItemSuggestions.value = mediaMenuItems.value.filter(val => val.name.includes(query)).sort((a, b) => {
        let found
        const searchSectionsTree = (val) => {
          if (found) {
            return
          }
          if (val.id === a.id || val.id === b.id) {
            found = val
            return
          }
          val.items.forEach(searchSectionsTree)
        }
        mediaMenuItemsTree.value.forEach(searchSectionsTree)

        if (found && found.id === a.id) {
          return -1
        } else if (found && found.id === b.id) {
          return 1
        }
        return 0
      })
    }

    const createMedia = () => {
      ui.value.showCreateMedia = true
    }

    const editSettings = async (data) => {
      const localMenuItemEditor = menuItemEditor.value
      const promises = []


      const settingsEditor = useFlowPMMenuMediafileSettingsBuilder({
        propDoc: data.settings,
        editorMain: localMenuItemEditor.editorKey,
        onFieldChange: (/*{field, value}*/) => {
          // DO NOTHING
          // localMenuItemEditor.fieldSet({field: `data.mediafilesSettings.${field}`, value})
        },
        onUpdateChange: () => {
          // DO NOTHING
        }
      })

      fatherType.value = data.type
      
      if (settingsEditor.fieldGet('trailer') && !settingsEditor.fieldGet('trailer.content')) {
        promises.push(new Promise((resolve, reject) => {
          flowGet(KFlowPortalmanagerMediafile, settingsEditor.fieldGet('trailer.id')).then((data) => {
            settingsEditor.fieldSetOri({field: 'trailer', value: data.data})
            resolve()
          }).catch(reject)
        }))
      }

      if (settingsEditor.fieldGet('preRollMedia1') && !settingsEditor.fieldGet('preRollMedia1.media')) {
        promises.push(new Promise((resolve, reject) => {
          flowGet(KFlowPortalmanagerMedia, settingsEditor.fieldGet('preRollMedia1.id')).then((data) => {
            settingsEditor.fieldSetOri({field: 'preRollMedia1', value: data.data})
            resolve()
          }).catch(reject)
        }))
      }

      if (settingsEditor.fieldGet('preRollMediafile1') && !settingsEditor.fieldGet('preRollMediafile1.content')) {
        promises.push(new Promise((resolve, reject) => {
          flowGet(KFlowPortalmanagerMediafile, settingsEditor.fieldGet('preRollMediafile1.id')).then((data) => {
            settingsEditor.fieldSetOri({field: 'preRollMediafile1', value: data.data})
            resolve()
          }).catch(reject)
        }))
      }

      if (settingsEditor.fieldGet('preRollMedia2') && !settingsEditor.fieldGet('preRollMedia2.media')) {
        promises.push(new Promise((resolve, reject) => {
          flowGet(KFlowPortalmanagerMedia, settingsEditor.fieldGet('preRollMedia2.id')).then((data) => {
            settingsEditor.fieldSetOri({field: 'preRollMedia2', value: data.data})
            resolve(data.data)
          }).catch(reject)
        }))
      }

      if (settingsEditor.fieldGet('preRollMediafile2') && !settingsEditor.fieldGet('preRollMediafile2.content')) {
        promises.push(new Promise((resolve, reject) => {
          flowGet(KFlowPortalmanagerMediafile, settingsEditor.fieldGet('preRollMediafile2.id')).then((data) => {
            settingsEditor.fieldSetOri({field: 'preRollMediafile2', value: data.data})
            resolve(data.data)
          }).catch(reject)
        }))
      }

      try {
        await Promise.all(promises)

      } catch (e) {
        Log.Error(e)
      }

      settingsEditorKey.value = settingsEditor.editorKey
      isEditingParameters.value = true
    }

    const applyMediaMediafilesFromMediaData = (fullData, medias, mediafiles, mediafilesSettings, mediafileTreeItem) => {
      switch (mediafileTreeItem.collection) {
        case KFlowPortalmanagerMedia: {
          for (let i = 0; i < medias.length; i++) {
            if (medias[i].id === fullData.id) {
              medias[i] = fullData
              break
            }
          }
          for (let i = 0; i < mediafilesSettings.length; i++) {
            if (mediafilesSettings[i].media.id === fullData.id) {
              mediafilesSettings[i].media = fullData
              break
            }
          }

          switch (fullData.mediaType) {
            case 'audio-album': {
              const toRemoveIdx = []
              mediafileTreeItem.children.forEach((child, idx) => {
                let isTrackFound = false
                fullData.media.tracks.forEach(track => {
                  if (child.id === track.id) {
                    applyMediaMediafilesFromMediaData(track, medias, mediafiles, mediafilesSettings, child)
                    mediafileTreeItem.children[idx] = child
                    isTrackFound = true
                  }
                })
                if (!isTrackFound) {
                  toRemoveIdx.push(idx)
                }
              })

              mediafileTreeItem.children = mediafileTreeItem.children.filter((child, idx) => {
                return !toRemoveIdx.includes(idx)
              })
              break
            }
            case 'tv-series': {
              const toRemoveIdx = []
              mediafileTreeItem.children.forEach((child, idx) => {
                let isTrackFound = false
                fullData.media.seasons.forEach(season => {
                  season.episodes.forEach(episode => {
                    if (child.id === episode.id) {
                      applyMediaMediafilesFromMediaData(episode, medias, mediafiles, mediafilesSettings, child)
                      mediafileTreeItem.children[idx] = child
                      isTrackFound = true
                    }
                  })
                })
                if (!isTrackFound) {
                  toRemoveIdx.push(idx)
                }
              })

              mediafileTreeItem.children = mediafileTreeItem.children.filter((child, idx) => {
                return !toRemoveIdx.includes(idx)
              })
              break
            }
            default: {
              mediafileTreeItem.children.forEach(child => {
                fullData.media.mediafiles.forEach(mediafile => {
                  if (child.id === mediafile.id) {
                    applyMediaMediafilesFromMediaData(mediafile, medias, mediafiles, mediafilesSettings, child)
                  }
                })
              })
            }
          }
          break
        }
        case KFlowPortalmanagerMediafile: {
          for (let i = 0; i < mediafiles.length; i++) {
            if (mediafiles[i].id === fullData.id) {
              mediafiles[i] = fullData
              break
            }
          }
          for (let i = 0; i < mediafilesSettings.length; i++) {
            if (mediafilesSettings[i].mediafile.id === fullData.id) {
              mediafilesSettings[i].mediafile = fullData
              break
            }
          }
          break
        }
      }
    }

    provide('editSettings', editSettings)
    provide('menuItemEditorKey', menuItemEditorKey)
    provide('menuEditorKey', props.editorKey)
    provide('fatherType', fatherType)

    watch(mediaMenuItemSelected, () => {
      tableMode.value = tableModeMediafiles
      const menuItemID = mediaMenuItemSelected.value?.id
      const localMenuItemEditor = menuItemEditor.value

      if (menuItemID) {
        if (!mediaLoading.value[menuItemID]) {
          mediaLoading.value[menuItemID] = {
            isLoading: true
          }
          let medias = localMenuItemEditor.fieldGet('data.medias')
          let mediafiles = localMenuItemEditor.fieldGet('data.mediafiles')
          let mediafilesSettings = localMenuItemEditor.fieldGet('data.mediafilesSettings')
          let mediafilesTree = localMenuItemEditor.fieldGet('data.mediafilesTree')

          const tasks = []

          // GET ALL
          mediafilesTree.forEach(rootElement => {
            tasks.push(function (pCb) {
              flowGet(KFlowPortalmanagerMedia, rootElement.id)
              .then(data => {
                pCb(null, data.data)
              })
              .catch(pCb)
            })
          })

          mediafilesSettings.forEach(settings => {
            tasks.push(function (pCb) {
              if (settings.overchargedMediafile) {
                flowGet(KFlowPortalmanagerMediafile, settings.overchargedMediafile.id)
                .then(data => {
                  pCb(null, data.data)
                })
                .catch(pCb)
              } else {
                pCb(null, null)
              }
            })
          })

          async.parallel(tasks, function (pErr, pResults) {
            if (pErr) {
              toast.add({severity: 'error', summary: 'Failed to get media for current section', detail: pErr.toString(), life: 4000})
              delete mediaLoading.value[menuItemID]
              return
            }

            const toRemoveIdx = []
            pResults.forEach((result, idx) => {
              if (idx < mediafilesTree.length) {
                const mediafileTreeItem = mediafilesTree[idx]
                applyMediaMediafilesFromMediaData(result, medias, mediafiles, mediafilesSettings, mediafileTreeItem)
                mediafilesTree[idx] = mediafileTreeItem
                if (mediafilesTree[idx].children.length === 0) {
                  toRemoveIdx.push(idx)
                }
              } else {
                const settingIndex = idx - mediafilesTree.length
                mediafilesSettings[settingIndex].overchargedMediafile = result
              }
            })

            mediafilesTree = mediafilesTree.filter((item, idx) => {
              return !toRemoveIdx.includes(idx)
            })

            localMenuItemEditor.pauseUpdateChecking()
            localMenuItemEditor.fieldSetOri({ field: 'data.medias', value: medias })
            localMenuItemEditor.fieldSetOri({ field: 'data.mediafiles', value: mediafiles })
            localMenuItemEditor.fieldSetOri({ field: 'data.mediafilesSettings', value: mediafilesSettings })
            localMenuItemEditor.fieldSetOri({ field: 'data.mediafilesTree', value: mediafilesTree })
            localMenuItemEditor.resumeUpdateChecking()
            mediaLoading.value[menuItemID].isLoading = false
          })
        }
      }
    })

    if (mediaMenuItems.value.length > 0) {
      mediaMenuItemSelected.value = mediaMenuItems.value[0]
    }

    return {
      fatherType,
      isPreviousCycle,
      addMediaFromLibrary,
      isEditingParameters,
      settingsEditorKey,
      mediaMenuItemSelected,
      mediaMenuItemSuggestions,
      tableModes,
      tableMode,
      ui,
      mediaMenuItems,
      hasSelectedMenuItem,
      isMediafileMode,
      hasMediafiles,
      isSectionLoading,
      searchMenuItems,
      menuItemEditorKey,
      createMedia
    }
  }
}
</script>

import { useFlowBuilder } from './useFlowEditor'

const KSeason = 'portalmanager:media-tv-season'

export function useFlowPMTVSeasonBuilder(propDoc, editorMain, onFieldChange) {
  return useFlowBuilder(KSeason, propDoc, {
    editorMain,
    onFieldChange: (field, value) => {
      onFieldChange({field, value})
    },
    onCreate: function onCreate (doc) {
      return {
        defaultLanguage: doc.defaultLanguage.id
      }
    },
    actionFields: [
      { field: 'episodes', action: 'links-to-ids' }
    ]
  })
}


import http from './http'
import qs from 'query-string'
import { diff } from 'deep-diff'

// import jsonDiff from 'json-diff'
// import diffJson from 'diff-json'

import PortalManager from './pxstream.portalmanager'
import Resource from './pxstream.resource'
import Project from './pxstream.project'
import Service from './pxstream.service'
import Log from './logger'


class ListingQueryBuilder {
  constructor (args) {
    this.sortField = args.sortField || null
    this.sortOrder = args.sortOrder || null
    this.first = args.first || 0
    this.rows = args.rows || 10
    this.filters = args.filters || {}
  }

  setFilterSearch (val) {
    this.filters.search = {value: val}
  }

  addFilter (key, matchMode, value) {
    this.filters[key] = {
      matchMode,
      value
    }
  }

  build () {
    return {
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      first: this.first,
      rows: this.rows,
      filters: this.filters,
    }
  }
}

class Tools {

  createQueryBuilder (args) {
    const defaultOptions = {
      rows: 35,
      sortField: 'name',
      sortOrder: 1
    }
    return new ListingQueryBuilder(Object.assign(defaultOptions, args))
  }

  buildListingQuery ({sortField, sortOrder, first, rows, filters}) {
    const qry = {}
    if (sortField) {
      qry['sort'] = (sortOrder !== 1 ? '-' : '')+sortField
    }

    qry['page'] = (first / rows ) || 0
    qry['perPage'] = rows

    if (filters) {
      Object.keys(filters).forEach(key => {
        if (filters[key].value) {
          if (key === 'search') {
            qry[`${key}`] = filters[key].value
          } else {
            qry[`${key}__${filters[key].matchMode}`] = filters[key].value.join(',')
          }
        }
      })
    }

    return qs.stringify(qry)
  }

  getFromPath(doc, path) {
    let val = doc
    path.forEach(seg => {
      val = val[seg]
    })

    return val
  }

  buildPatch (ori, wip) {
    const patch = []
    // const list = jsonDiff.diff(ori, wip)
    const list = diff(ori, wip)
    // Log('-------------------------------------------------------------------')
    // Log(list)
    // Log('-------------------------------------------------------------------')
    // Log(l)
    // Log('-------------------------------------------------------------------')
    if (!list) {
      return null
    }

    const arrayAddedToPatch = {}

    list.forEach(one => {
      switch (one.kind) {
        case 'E':
          patch.push({
            op: 'replace',
            path: one.path.join('.'),
            value: this.getFromPath(wip, one.path)
          })
          break
        case 'A':
          if (!arrayAddedToPatch[one.path.join('.')]) {
            arrayAddedToPatch[one.path.join('.')] = true
            patch.push({
              op: 'array',
              path: one.path.join('.'),
              value: this.getFromPath(wip, one.path)
            })
          }
          break
        case 'N':
          patch.push({
            op: 'new',
            path: one.path.join('.'),
            value: this.getFromPath(wip, one.path)
          })
          break
        case 'D':
          patch.push({
            op: 'delete',
            path: one.path.join('.')
          })
          break;
        default:
          console.warn('UnknownKind:', one)
      }
    })

    // N - indicates a newly added property/element
    // D - indicates a property/element was deleted
    // E - indicates a property/element was edited
    // A - indicates a change occurred within an array

    return { patch }
  }

  /**
   * Format date to YYYY-MM-DD
   * @param {Date} date
   */
  toSimpleDate (date) {
    return date ? date.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }) : ''
  }

  /**
   * Create Date from YYYY-MM-DD format
   * @param {String} dateStr
   */
  fromSimpleDate (dateStr) {
    if (dateStr == null || dateStr === "") return null
    return new Date(dateStr)
  }

  isGeneratedId (id) {
    return (typeof id === "string" && id.startsWith('_'))
  }

  downloadFile(url) {
    const a = document.createElement('a')
    a.href = url
    a.download = url.split('/').pop()
    a.style.display = 'none';
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  avoidRenderingStuck (f) {
    setTimeout(f, 50)
  }

}

class PXStream {
  constructor () {
    const tools = new Tools()
    this.tools = tools
    this.portalmanager = new PortalManager(http, tools)
    this.resource = new Resource(http, tools)
    this.project = new Project(http, tools)
    this.service = new Service(http, tools)
  }

  toFlowPath (flow) {
    switch (flow) {
      case 'portalmanager:media':
        return 'portalmanager/media'
    }
    return flow.replace(':', '/')+'s'
  }

  async getUsers (args) {
    Log(args)
    const query = this.buildListingQuery(args || {})
    Log(query)
    return http.core.get(`/flow/admin/users?${query}`)
  }

  async createOneUser (args) {
    return http.core.post(`/flow/admin/users`, args)
  }

  async getOneUser (id) {
    return http.core.get(`/flow/admin/users/${id}`)
  }

  async saveOneUser (id, data) {
    return http.core.put(`/flow/admin/users/${id}`, data)
  }

  async getOne (flow, id) {
    return http.core.get(`/flow/${this.toFlowPath(flow)}/${id}`)
  }

  async createOne (flow, data, { patch, links }) {
    data.patch = patch
    data.links = links
    return http.core.post(`/flow/${this.toFlowPath(flow)}`, data)
  }

  async saveOne (flow, id, data) {
    return http.core.put(`/flow/${this.toFlowPath(flow)}/${id}`, data)
  }

  async deleteOne (flow, id) {
    return http.core.delete(`/flow/${this.toFlowPath(flow)}/${id}`)
  }

  async getMediaList (flow, params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/${this.toFlowPath(flow)}?${qry}`)
    return data
  }

  async getPortalManagerMedia () {
    return http.core.get(`/flow/portalmanager/media`)
  }

  async getPortalManagerMediaStrapi () {
    return http.axios.get(`http://localhost:1337/portal-manager-medias`)
  }

  async getRscContentTypes () {
    return http.core.get(`/flow/resource/content-types`)
  }

  async getRscLanguages () {
    return http.core.get(`/flow/resource/languages`)
  }

  async getRscLicensors () {
    return http.core.get(`/flow/resource/licensors`)
  }

}

export default new PXStream()

import pxstream from '../services/pxstream'

const K_DEFAULT_LANGUAGE = "en"

const app = {
  namespaced: true,
  state: () => ({
    languages: [],
    contentTypes: null,
    contentFormats: null,
    licensors: [],
    isInitialized: false
  }),
  getters: {
    languages: state => state.languages,
    defaultInitLanguage: state => state.languages.find((lang) => (lang.iso_639_1 === K_DEFAULT_LANGUAGE)),
    languageById: state => id => {
      for (let i = 0; i < state.languages.length; i++) {
        if (state.languages[i].id == id) {
          return state.languages[i]
        }
      }

      return null
    },
    contentTypes: state => state.contentTypes,
    contentFormats: state => state.contentFormats,
    audioFormat: state => state.contentFormats.find((f) => f.name === "Audio"),
    tvFormat: state => state.contentFormats.find((f) => f.name === "TV"),
    licensors: state => state.licensors,
    isReady: state => state.isInitialized
  },
  mutations: {
    setLanguages (state, {data}) {
      state.languages = data.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    },
    setContentTypes (state, {data}) {
      const formatsById = {}
      state.contentTypes = data.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      state.contentTypes.forEach(one => {
        if (!formatsById[one.format.id]) {
          formatsById[one.format.id] = one.format
        }
        formatsById[one.format.id].contentTypes = formatsById[one.format.id].contentTypes || []
        const {id, name} = one
        formatsById[one.format.id].contentTypes.push({id, name})
      })

      state.contentFormats = Object.keys(formatsById)
        .map(id => formatsById[id])
        .sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      // console.log(state.contentFormats)
    },
    setLicensors (state, {data}) {
      state.licensors = data.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    },
    initializated (state) {
      state.isInitialized = true
    }
  },
  actions: {
    async initResource ({commit}) {
      let err = null

      try {
        const res = await Promise.all([
          pxstream.getRscLanguages(),
          pxstream.getRscContentTypes(),
          pxstream.getRscLicensors()
        ])
        commit('setLanguages', res[0].data)
        commit('setContentTypes', res[1].data)
        commit('setLicensors', res[2].data)
        commit('initializated')
      } catch (e) {
        err = e
      }

      return new Promise((resolve, reject) => {
        err ? reject(err) : resolve()
      })
    }
  }
}

export default app

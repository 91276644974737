import { ref, reactive } from 'vue'
import pxstream from '../services/pxstream'
import Log from '@/services/logger'
import deepEqual from "deep-equal"

let editors = {}

const KNewKey = '$$NEW$$'

function genKey (flow, id) {
  return `${flow}:${id}`
}

export function genNewId () {
  return KNewKey + Math.random().toString(36).slice(2)
}

function initFromMain (childKey, {from, list}) {
  if (!list) return
  if (!editors[childKey]) return
  if (!editors[from]) return

  list.forEach(({field}) => {
    const value = editors[from].fieldGet(field)
    editors[childKey].fieldSet({field, value})
  })
}

export function useFlowReset () {
  editors = {}
}

export function useFlowBuilder (flow, data, args) {
  if (data.id === 'new') {
    data.id = genNewId()
  }

  const editorKey = genKey(flow, data.id)

  function cleanup () {
    Log('Cleaning '+editorKey)
    delete editors[editorKey]
  }

  if (editors[editorKey]) {
    // console.error("Alreadybuild:"+editorKey)
    return { editorKey, cleanup, ...editors[editorKey] }
  }

  editors[editorKey] = __useFlowEditor(editorKey, flow, data, args || {})

  if (args.initFields) {
    initFromMain (editorKey, args.initFields)
  }

  return { editorKey, cleanup, ...editors[editorKey] }
}

export function useFlowEditor (editorKey) {
  if (!editors[editorKey]) {
    console.error("Call useFlowBuilder before : "+editorKey)
    return {}
  }

  return editors[editorKey]
}

function __useFlowEditor (editorKey, flow, data, args) {
  args.metadata = args.metadata || {}
  args.onCreate = args.onCreate || function () { return {}}
  args.onUpdateChange = args.onUpdateChange || function () { return {}}
  args.onFieldChange = args.onFieldChange || function () { return {}}
  args.actionFields = args.actionFields || []
  args.editorMain = args.editorMain || ''

  const mainEditor = editors[args.editorMain] || null

  const ori = JSON.parse(JSON.stringify(data))
  const wip = reactive(JSON.parse(JSON.stringify(data)))

  const isSaving = ref(false)
  const hasUpdate = ref(false)
  const hasError = ref(false)
  const updatedAt = ref(new Date())
  const error = ref(null)
  const isInLiveUpdateCheckingMode = ref(true)

  const updatesPending = ref([])

  const groupErrors = reactive({})
  const linksErrors = reactive({})
  const linksToUpdate = reactive({})

  const reIsNew = RegExp(KNewKey.replace(/\$/g, '\\$'))
  const isNew = reIsNew.test(editorKey)
  let patchActions = []

  const hasLinksError = function (ids) {
    if (ids === '*') {
      ids = Object.keys(linksErrors)
      ids = ids.concat(Object.keys(linksToUpdate))
    }

    if (typeof ids === 'string') {
      ids = [ids]
    }

    ids = ids || Object.keys(linksErrors)
    for (let i = 0; i < ids.length; i++) {
      const key = ids[i]
      if (linksErrors[key]) {
        return true
      }
      if (editors[key] && editors[key].hasLinksError()) {
        return true
      }
    }

    return false
  }

  function pauseUpdateChecking () {
    isInLiveUpdateCheckingMode.value = false
  }

  function resumeUpdateChecking () {
    isInLiveUpdateCheckingMode.value = true
    updatesPending.value.forEach(update => {
      if (update.func) {
        update.func(update.args)
      }
    })
    updatesPending.value = []

    checkHasUpdate()
  }

  function _fieldSet ({field, value}) {
    apply(wip, field, value)
    args.onFieldChange(field, value, editorKey)
  }

  function fieldSet ({field, value}) {
    Log('FIELD_SET', field, value)
    const funcArgs = {field, value}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldSet, args: funcArgs})
    } else {
      _fieldSet(funcArgs)
      checkHasUpdate()
    }
  }

  function _fieldSetOri ({field, value}) {
    apply(ori, field, value)
    apply(wip, field, value)
  }

  function fieldSetOri ({field, value}) {
    Log('FIELD_SET_ORI', field, value)
    const funcArgs = {field, value}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldSetOri, args: funcArgs})
    } else {
      _fieldSetOri(funcArgs)
      checkHasUpdate()
    }
  }

  function _fieldPush ({field, value}) {
    const one = arraypath(wip, field)
    one.push(value)
    args.onFieldChange(field, fieldGet(field), editorKey)
  }

  function fieldPush ({field, value}) {
    Log('FIELD_PUSH', field, value)
    const funcArgs = {field, value}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldPush, args: funcArgs})
    } else {
      _fieldPush(funcArgs)
      checkHasUpdate()
    }
  }

  function _fieldAddToSet ({field, value, key}) {
    const one = arraypath(wip, field)
    key = key || 'id'
    for (let i = 0; i < one.length; i++) {
      if (one[i][key] === value[key]) {
        one[i] = value
        return
      }
    }
    one.push(value)
    args.onFieldChange(field, fieldGet(field), editorKey)
  }

  function fieldAddToSet ({field, value, key}) {
    Log('FIELD_ADD_TO_SET', field, value)
    const funcArgs = {field, value, key}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldAddToSet, args: funcArgs})
    } else {
      _fieldAddToSet(funcArgs)
      checkHasUpdate()
    }
  }

  function _fieldSplice ({field, filter}) {
    const one = arraypath(wip, field)
    apply(wip, field, one.filter(filter))
    args.onFieldChange(field, fieldGet(field), editorKey)
  }

  function fieldSplice ({field, filter}) {
    const funcArgs = {field, filter}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldSplice, args: funcArgs})
    } else {
      _fieldSplice(funcArgs)
      checkHasUpdate()
    }
  }

  function _fieldSetArrayItem ({field, index, value}) {
    const path = `${field}.${index}`
    apply(wip, path, value)
    args.onFieldChange(path, fieldGet(path), editorKey)
  }

  function fieldSetArrayItem ({field, index, value}) {
    const funcArgs = {field, index, value}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldSetArrayItem, args: funcArgs})
    } else {
      _fieldSetArrayItem(funcArgs)
      checkHasUpdate()
    }
  }

  function _fieldReduce({field, reduce, initialVal}){
    const one = arraypath(wip, field)
    apply(wip, field, one.reduce(reduce, initialVal))
    args.onFieldChange(field, fieldGet(field), editorKey)
  }

  function fieldReduce({field, reduce, initialVal}){
    const funcArgs = {field, reduce, initialVal}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldReduce, args: funcArgs})
    } else {
      _fieldReduce(funcArgs)
      checkHasUpdate()
    }
  }

  function _fieldObjSet({field, key, value}) {
    const ref = objpath(wip, field)
    ref[key] = value
    args.onFieldChange(field, fieldGet(field), editorKey)
  }

  function fieldObjSet({field, key, value}) {
    Log('FIELD_OBJ_SET', field, key, value)
    const funcArgs = {field, key, value}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldObjSet, args: funcArgs})
    } else {
      _fieldObjSet(funcArgs)
      checkHasUpdate()
    }
  }

  function _fieldObjDel ({field, key}) {
    const ref = objpath(wip, field)
    delete ref[key]
    args.onFieldChange(field, fieldGet(field), editorKey)
  }

  function fieldObjDel ({field, key}) {
    const funcArgs = {field, key}
    if (!isInLiveUpdateCheckingMode.value) {
      updatesPending.value.push({func: _fieldObjDel, args: funcArgs})
    } else {
      _fieldObjDel(funcArgs)
      checkHasUpdate()
    }
  }

  function fieldGet (path) {
    return __fieldGet(wip, path)
  }

  function __fieldGet (doc, path) {
    const fields = path.split('.')
    let cursor = doc
    try {
      for (let i = 0; i < fields.length; i++) {
        cursor = cursor[fields[i]]
      }
    } catch (e) {
      Log(`! fieldGet::${path} not found`)
      return null
    }

    return cursor
  }

  function getError () {
    return error.value
  }

  function setError (err, componentName) {
    error.value = err
    hasError.value = !!err

    if  (componentName) {
      if (err) {
        groupErrors[componentName] = err
      } else {
        delete groupErrors[componentName]
      }
    }

    if (mainEditor) {
      mainEditor.setLinksError(editorKey, err)
    }
  }

  function hasErrorFrom (componentName) {
    return !!groupErrors[componentName]
  }

  function setLinksError (editorKey, err) {
    linksErrors[editorKey] = err
  }

  function saveFlowBuild () {
    const wipDoc = JSON.parse(JSON.stringify(wip))
    const patch = __buildPatch()
    const links = __getLinksToSave()
    let hasLinkError = false
    try{
      for (let i = 0; i < links.length; i++) {
        if (links[i].error) {
          hasLinkError = true
          break
        }
      }

      if (isNew) {
        const newDoc = args.onCreate(ori, args.metadata)
        return {
          id: fieldGet('id'),
          doc: wipDoc,
          flow: flow,
          data: newDoc,
          type: 'new',
          links: links,
          hasLinkError: hasLinkError,
          patch: patch,
          error: error.value
        }
      } else {
        return {
          id: fieldGet('id'),
          doc: wipDoc,
          flow: flow,
          type: 'patch',
          links: links,
          hasLinkError: hasLinkError,
          patch: patch,
          error: error.value
        }
      }

    } catch (error) {
      console.error(error)
    }

  }

  function checkHasUpdate () {
    if (isNew) {
      hasUpdate.value = true
    } else if (Object.keys(linksToUpdate).length > 0) {
      hasUpdate.value = true
    } else if (!deepEqual(ori, wip, {strict: true})) {
      hasUpdate.value = true
    } else {
      hasUpdate.value = false
    }

    updatedAt.value = new Date()

    if (mainEditor) {
      if (hasUpdate.value) {
        // Déplacer le build au moment de l'appel à saveFlowBuild, le check update ne doit pas build un patch c bcp trop lourd
        mainEditor.saveFlowAddLink(saveFlowBuild())
      } else {
        mainEditor.saveFlowDeleteLink(editorKey)
      }
    }

    args.onUpdateChange({hasUpdate: hasUpdate.value, at: updatedAt.value})
  }

  function setIsSaving (val) {
    isSaving.value = val
  }

  function saveFlowAddLink (req) {
    delete req.doc
    linksToUpdate[`${req.flow}:${req.id}`] = req
    checkHasUpdate()
  }

  function saveFlowDeleteLink (linkEditorKey) {
    delete linksToUpdate[linkEditorKey]
    checkHasUpdate()
  }

  function saveFlowDeleteLinkElement (req) {
    const linkID = `${req.flow}:${req.id}`
    setLinksError(linkID, null)
    if (linksToUpdate[linkID] && linksToUpdate[linkID].type === 'new') {
      delete linksToUpdate[linkID]
    } else {
      linksToUpdate[linkID] = {
        ...req,
        type: 'delete'
      }
    }
    checkHasUpdate()
  }

  function saveFlowDeleteLinkElements (reqs) {
    if (reqs && Array.isArray(reqs)) {
      reqs.forEach(req => {
        const linkID = `${req.flow}:${req.id}`
        setLinksError(linkID, null)
        if (linksToUpdate[linkID] && linksToUpdate[linkID].type === 'new') {
          delete linksToUpdate[linkID]
        } else {
          linksToUpdate[linkID] = {
            ...req,
            type: 'delete'
          }
        }
        checkHasUpdate()
      })
    }
  }

  function __buildPatchWithRawValue ({field, pathPrefix}) {
    const oriValue = __fieldGet(ori, (pathPrefix ? `${pathPrefix}.` : '') + field) || null
    const wipValue = __fieldGet(wip, (pathPrefix ? `${pathPrefix}.` : '') + field) || null

    let patch = null

    if (JSON.stringify(oriValue) !==
      JSON.stringify(wipValue)) {
      patch = {
        op: 'replace',
        path: field,
        value: wipValue
      }
    }

    if (patch) {
      return {patch, ignoreIt: true}
    }

    return {ignoreIt: false}
  }

  /**
   * Custom Patch builder for objects like i18n or ifeSystems.
   * -- property can be a lang id (for i18n) or an ifeSystem id
   *
   * return a patch for each object's keys (ids)
   */
  function __buildPatchForObjectProperties ({ field, op, actionFields, pathPrefix, fieldId, defaultValue }) {

    const oriValue = __fieldGet(ori, (pathPrefix ? `${pathPrefix}.` : '') + field) || defaultValue || {}
    const wipValue = __fieldGet(wip, (pathPrefix ? `${pathPrefix}.` : '') + field) || defaultValue || {}

    let patch = null

    const patchValue = []

    if (Array.isArray(wipValue)) {

      fieldId = fieldId || ((f) => f.id)

      const findById = (arr, id) => {
        return !Array.isArray(arr) ? null : arr.find(item => fieldId(item) === id)
      }

      wipValue.map((wipItem) => fieldId(wipItem)).forEach((id, index) => {
        const res = pxstream.tools.buildPatch(findById(oriValue, id) || {}, findById(wipValue, id))
        if (res && res.patch && res.patch.length > 0) {
          patchValue.push({
            id,
            type: findById(oriValue, id) ? 'replace' : 'new',
            patch: actionFields ? __buildPatchWithActions(res, actionFields, (pathPrefix ? `${pathPrefix}.` : '') + `${field}.${index}`) : res.patch
          })
        }
      })

      if (oriValue) {
        oriValue.map((oriItem) => fieldId(oriItem)).forEach((id) => {
          if (!findById(wipValue, id)) {
            patchValue.push({
              id,
              type: 'delete'
            })
          }
        })
      }

      if (patchValue.length > 0) {
        patch = {
          op,
          path: field,
          value: patchValue
        }
      }

    } else if (typeof wipValue === "object") {
      // Check wip fields
      Object.keys(wipValue || {}).forEach(property => {
        // Check diff between origin and wip field. If new or replacing value
        const res = pxstream.tools.buildPatch(oriValue[property] || {}, wipValue[property])
        if (res && res.patch && res.patch.length > 0) {
          patchValue.push({
            id: property,
            type: oriValue[property] ? 'replace' : 'new',
            patch: actionFields ? __buildPatchWithActions(res, actionFields, (pathPrefix ? `${pathPrefix}.` : '') + `${field}.${property}`) : res.patch
          })
        }
      })
      // Check ori fields
      Object.keys(oriValue || {}).forEach(property => {
        // Field has been deleted
        if (!wipValue[property]) {
          patchValue.push({
            id: property,
            type: 'delete'
          })
        }
      })

      if (patchValue.length > 0) {
        patch = {
          op,
          path: field,
          value: patchValue
        }
      }
    }

    if (patch) {
      return {patch, ignoreIt: true}
    }

    return {ignoreIt: false}
  }

  function __buildPatchConvertLinksToIds ({field}, action) {
    const oriLinks = __fieldGet(ori, field) || []
    const wipLinks = __fieldGet(wip, field) || []

    let path = 'id'
    // Function to restore object before ids reducing
    // Useful for dynamic subs and keep CC field
    let mapper = null
    // Liste des elements wip for language
    let list = null

    // Save original object before reducing to id only
    const byId = {}

    switch (action) {
      case 'languages-to-ids': {
        list = wipLinks
        path = 'language.id'
        mapper = function (id) {
          return byId[id]
        }
      }
    }

    const isArray = Array.isArray(wipLinks)
    let oriIds
    let wipIds

    if (!isArray) {
      oriIds = Object.keys(oriLinks || {})
      wipIds = Object.keys(wipLinks || {})
    } else { // array
      oriIds = oriLinks.map(one => __fieldGet(one, path))
      wipIds = wipLinks.map(one => {
        const id = __fieldGet(one, path)
        byId[id] = one
        return id
      })
    }

    const ignoreIt = true
    const patch = {
      "op": "replace",
      "path": field,
      "value": wipIds
    }

    if (oriIds.length != wipIds.length) {
      return { patch, ignoreIt, mapper, list }
    }

    for (let i = 0; i < wipIds.length; i++) {
      if (oriIds[i] != wipIds[i]) {
        return { patch, ignoreIt, mapper, list }
      }
    }

    return { ignoreIt, list }
  }

  function __buildPatchAction (args) {
    switch (args.action) {
      case 'links-to-ids':
      case 'languages-to-ids': {
        patchActions.push((args.pathPrefix ? `${args.pathPrefix}.` : '') + args.field)
        // TODO faire le changement niveau back pour pouvoir envoyer seulement des ids
        const { patch, ignoreIt, mapper, list } = __buildPatchConvertLinksToIds(args, args.action)
        if (patch && mapper) {
          patch.value = patch.value.map(mapper)
        }
        return { patch, ignoreIt, list }
      }
      case 'to-raw':
        patchActions.push((args.pathPrefix ? `${args.pathPrefix}.` : '') + args.field)
        return __buildPatchWithRawValue(args)
      case 'genre-overload':
        return __buildPatchForObjectProperties({ ...args, op: 'genre-overload', customId: (item) => item.genre.id , fieldId: (field) => (field.genre.id), defaultValue: []})
      case 'ife-system':
        patchActions.push((args.pathPrefix ? `${args.pathPrefix}.` : '') + args.field)
        return __buildPatchForObjectProperties({ ...args, op: 'ife-system' })
      case 'to-i18n':
        return __buildPatchForObjectProperties({ ...args, op: 'i18n' })
      case 'ignore-it':
        return {ignoreIt: false}
    }

    return {ignoreIt: false}
  }

  function __buildPatch () {
    const res = pxstream.tools.buildPatch(ori, wip)
    if (!res) { return [] }
    patchActions = []
    return __buildPatchWithActions(res, args.actionFields)
  }

  function __buildPatchWithActions (res, actionFields = [], pathPrefix = '') {
    const actionPatch = {}

    let { patch } = res
    patch = patch.filter(one => {
      for (let i = 0; i < actionFields.length; i++) {
        if (((one.path === actionFields[i].field || one.path.startsWith(`${actionFields[i].field}.`)))
        && !patchActions.includes((pathPrefix ? `${pathPrefix}.` : '') + actionFields[i].field)) {
          const { patch, ignoreIt, list } = __buildPatchAction({ ...actionFields[i], pathPrefix })
          // Apply patch only if action field matchs exatly to patch one
          if (patch && actionFields[i].field === one.path) {
            actionPatch[actionFields[i].field] = patch
          // Else if path has been modified set all entries for this path
          } else if (list) {
            actionPatch[actionFields[i].field] = {
              "op": "replace",
              "path": actionFields[i].field,
              "value": list
            }
          // Apply patch only if action field starts with this path
          } else if (one.path.startsWith(actionFields[i].field)) {
            actionPatch[actionFields[i].field] = patch
          }
          return !ignoreIt
        } else if ((one.path === actionFields[i].field || one.path.startsWith(`${actionFields[i].field}.`))
          && patchActions.includes((pathPrefix ? `${pathPrefix}.` : '') + actionFields[i].field)) {
          return false
        }
      }
      return true
    })

    return patch.concat(Object.keys(actionPatch).map(key => actionPatch[key]))
  }

  function __getLinksToSave () {
    return Object.keys(linksToUpdate).map(key => linksToUpdate[key])
  }

  checkHasUpdate()

  return {
    KNewKey,
    doc: wip,
    isNew,

    hasUpdate,
    hasError,
    updatedAt,
    isSaving,

    setIsSaving,

    pauseUpdateChecking,
    resumeUpdateChecking,

    fieldGet,
    fieldSet,
    fieldSetOri, // DO NOT USE UNLESS YOU HAVE TO LAZY LOAD SOME CONTENT
    fieldPush,
    fieldAddToSet,
    fieldSetArrayItem,
    fieldSplice,
    fieldReduce,
    fieldObjSet,
    fieldObjDel,
    checkHasUpdate,

    getError,
    setError,
    hasErrorFrom,
    setLinksError,
    hasLinksError,

    saveFlowBuild,
    saveFlowAddLink,
    saveFlowDeleteLink,
    saveFlowDeleteLinkElement,
    saveFlowDeleteLinkElements
  }
}


// https://medium.com/woost/generic-vuex-store-module-1001766a2c83

function __apply (doc, fields, value) {
  if (fields.length === 1) {
    doc[fields[0]] = value
  } else {
    const one = fields.shift()
    __apply(doc[one], fields, value)
  }
}

function apply (doc, field, value) {
  __apply(doc, field.split('.'), value)
}

function __objpath(doc, fields, gen) {
  if (fields.length === 1) {
    if (! doc[fields[0]] || typeof doc[fields[0]] !== 'object') {
      doc[fields[0]] = gen()
    }
    return doc[fields[0]]
  }
  const one = fields.shift()
  doc[one] = doc[one] || {}
  return __objpath(doc[one], fields, gen)
}

function arraypath(doc, field) {
  return __objpath(doc, field.split('.'), function () {
    return []
  })
}

function objpath(doc, field) {
  return __objpath(doc, field.split('.'), function () {
    return {}
  })
}

<template>
  <tr v-if="currentVideoBitrate" role="row">
    <td class="column-title" role="cell">
      <span>{{ label }}</span>
    </td>
    <td class="column-data flex" role="cell">
      {{ currentVideoBitrate }}
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    bitrate: String,
    label: String,
  },
  setup(props) {
    const RscVideoBitrates = [
      {id: "M1_5", name: "1.5 Mbps"}
    ]

    const currentVideoBitrate = computed(() => {
      return RscVideoBitrates.find(rscBitrate => rscBitrate.id === props.bitrate)?.name || ''
    })

    return {
      currentVideoBitrate
    }
  },
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>

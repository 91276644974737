<template>
  <div>
    <Dialog 
    v-model:visible="showIt" 
    style="width:400px"
    @hide="hide();resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Add Track</h3>
        </div>
      </template>

      <div class="flex justify-content-center mb-2">
        <SelectButton
        class="p-primary-button"
          v-model="mode" :options="modes"
        />
      </div>

      <form class="p-fluid" @keyup.enter="createOrAdd">
        
        <div class="fluid" v-if="!addNew">
          <div class="field">
            <label for="artists">Artist</label>
            <AutoCompleteSearch
                v-model="artists"
                itemValue="id"
                field="name"
                dropdown
                dropdownIcon="search"
                :multiple="false"
                :suggestions="listingArtists"
                :suggestionsTotal="listingArtistsTotal"
                @complete="searchArtists($event)"
              />
          </div>

          <div class="field">
            <label for="name" :class="{'p-error':nameError}">{{ $t('forms.title') }}</label>
            <AutoCompleteSearch
              v-model="media"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              :suggestions="listingMedias"
              :suggestionsTotal="listingMediasTotal"
              @complete="searchMedia($event)"
            />
            <small v-if="nameError" class="p-error">{{nameError}}</small>
          </div>
        </div>

        <div class="fluid" v-if="addNew">
          <div class="field">
            <label for="name" :class="{'p-error':nameError}">{{ $t('forms.title') }}</label>
            <InputText id="name" type="text"
              v-model="name" 
              :class="{'p-invalid': nameError}"
              :disabled="isLoading"
            />
            <small v-if="nameError" class="p-error">{{nameError}}</small>
          </div>
        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="px-button-cancel p-button-text" @click="showIt = false"/>
          <Button :label="addNew ? $t('actions.create') : $t('actions.add')" icon="pi pi-check" :loading="isLoading" @click="createOrAdd"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import SelectButton from 'primevue/selectbutton';
import InputText from "primevue/inputtext"
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import Message from "primevue/message"
import { mapGetters } from 'vuex'
import { requiredUnless } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"

const EXISTING_MEDIA = 'Existing Media'
const NEW_MEDIA = 'New Media'

const KFlow = "portalmanager:media"


import { useFlowBuilder } from '@/compositions/useFlowEditor'

export default {
  name: 'TrackNew',
  setup: () => ({ v$: useVuelidate() }),
  props: ['modelValue', 'doc', 'editorMain'],
  emits: ['update:modelValue', 'onTrackAdded'],
  components: { 
    InputText, Message, SelectButton, AutoCompleteSearch
  },
  data () {
    return {
      contentFormat: '',
      contentType: '',
      name: '',
      media: null,
      artists: null,
      defaultLanguage: '',
      mode: EXISTING_MEDIA,
      modes: [EXISTING_MEDIA, NEW_MEDIA],
      listingArtists: [],
      listingArtistsTotal: 0,
      listingMedias: [],
      listingMediasTotal: 0,
      showIt: false,
      isLoading: false,
      errorCaught: ''
    }
  },

  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.name = ''
      this.media = null
      this.defaultLanguage = this.doc.defaultLanguage
      this.contentType = this.audioFormat.contentTypes.find((t) => t.name.includes('Audio'))
      this.artists = this.doc.media.artists?.length === 1 ? this.doc.media.artists[0] : null
    },

    async searchArtists ({ query }) {
      if (query.length >= 3) {
        try {
          const qryBuilder = this.$pxstream.tools.createQueryBuilder()
          qryBuilder.setFilterSearch(query)
          qryBuilder.sortField = 'name'
          qryBuilder.sortOrder = 1
          const {data, total} = await this.$pxstream.resource.getArtists(qryBuilder.build())
          this.listingArtists = data
          this.listingArtistsTotal = total
        } catch (err) {
          this.$toast.add({severity: 'error', summary: 'Failed to get artists', detail: err.toString(), life: 4000})
        }
      }
    },

    async searchMedia ({ query }) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        // TODO: C'est horrible ô mon dieu...
        qryBuilder.addFilter('contentType.name', 'contains', ['Audio Simple'])
        if (this.artists) {
          qryBuilder.addFilter('media.artists.id', 'contains', [this.artists.id])
        }
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.portalmanager.getMediaList(qryBuilder.build())
        this.listingMedias = data
        this.listingMediasTotal = total
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get medias', detail: err.toString(), life: 4000})
      }
    },

    addNewMedia () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.portalmanager.createEmptyMedia({
        name: this.name,
        contentType: this.contentType.id,
        defaultLanguage: this.doc.defaultLanguage.id
      })
      .then(({data}) => {
        
        data.id = 'new'
        const { saveFlowBuild } = useFlowBuilder(KFlow, data, {
          editorMain: this.editorMain,
          initFields: {
            from: this.editorMain,
            list: [
              { field: 'media.year' },
              { field: 'media.artists' },
              { field: 'media.genres' },
              { field: 'media.rating' },
              { field: 'media.countries' },
              { field: 'media.hasOffensiveLanguage' },
              { field: 'media.hasViolence' },
              { field: 'licensor' },
              { field: 'contentOwner' },
              { field: 'provider' },
              { field: 'copyright' },
            ]
          },
          onCreate: function onCreate (doc) {
            // const isSeason = (d) => ('episodes' in d)
            return {
              name: doc.name,
              contentType: doc.contentType.id,
              defaultLanguage: doc.defaultLanguage.id
            }
          },
          actionFields: [
            { field: 'media.mediafiles', action: 'links-to-ids' },
            { field: 'media.tracks', action: 'links-to-ids' },
            { field: 'media.seasons', action: 'links-to-ids' }
          ]
        })

        const req = saveFlowBuild()
        this.$emit('onTrackAdded', req)
        
        this.hide()
      })
      .catch(({message}) => {
        this.errorCaught = message
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    addExistingMedia () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.portalmanager.getMediaOne(this.media.id)
      .then(({data}) => {

        const { saveFlowBuild } = useFlowBuilder(KFlow, data, {
          editorMain: this.editorMain,
          onCreate: function onCreate (doc) {
            // const isSeason = (d) => ('episodes' in d)
            return {
              name: doc.name,
              contentType: doc.contentType.id,
              defaultLanguage: doc.defaultLanguage.id
            }
          },
          actionFields: [
            { field: 'media.mediafiles', action: 'links-to-ids' },
            { field: 'media.tracks', action: 'links-to-ids' },
            { field: 'media.seasons', action: 'links-to-ids' }
          ]
        })

        const req = saveFlowBuild()
        this.$emit('onTrackAdded', req)

        this.hide()
      })
      .catch (({message}) => {
        this.errorCaugth = message
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    createOrAdd () {
      if (this.mode === NEW_MEDIA) {
        this.addNewMedia()
      } else if (this.mode === EXISTING_MEDIA) {
        this.addExistingMedia()
      }
    },
    hide () {
      this.$emit('update:modelValue', false)
    }
  },

  computed: {
    ...mapGetters('resource', ['audioFormat']),
    contentFormatError () {
      if (!this.v$.contentFormat.$dirty) return ''
      if (this.v$.contentFormat.required.$invalid) return 'Content Format is required.'
      return ''
    },
    contentTypeError () {
      if (!this.v$.contentType.$dirty) return ''
      if (this.v$.contentType.required.$invalid) return 'Content Type is required.'
      return ''
    },
    nameError () {
      if (!this.v$.name.$dirty) return ''
      if (this.v$.name.required.$invalid) return 'Name is required.'
      return ''
    },
    defaultLanguageError () {
      if (!this.v$.defaultLanguage.$dirty) return ''
      if (this.v$.defaultLanguage.required.$invalid) return 'Default language is required.'
      return ''
    },
    addNew () {
      return this.mode === NEW_MEDIA
    },
    addExisting () {
      return this.mode === EXISTING_MEDIA
    }
  },

  validations: {
    name: { 
      required: requiredUnless('addExisting')
    },
    media: {
      required: requiredUnless('addNew')
    }
  },

  watch: {
    mode (value) {
      if (value === NEW_MEDIA) {
        this.resetFields()
      }
    },
    showIt (show) {
      if (show) {
        this.resetFields()
      }
    },
    modelValue: function (val) {
      this.showIt = val
    }
  }
}
</script>

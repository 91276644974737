<template>
  <div>
    <Button
      icon="pi pi-trash"
      :class="btnClass"
      :disabled="disabled"
      :loading="loading"
      @click="$refs.panel.toggle($event)"
    />
    <OverlayPanel ref="panel" class="delete-overlay" :dismissable="true">
      <span @click="confirmRemove($event)" class="cursor-pointer underline text-danger">{{ $t('actions.remove') }}?</span>
    </OverlayPanel>
  </div>
</template>

<script>
import Button from 'primevue/button'
import OverlayPanel from 'primevue/overlaypanel'

export default {
  props: {
    btnClass: {
      type: [String, Object],
      default: 'p-button-rounded p-button-danger p-button-outlined'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: Boolean
  },
  components: {
    OverlayPanel,
    Button
  },
  methods: {
    confirmRemove () {
      this.$emit('confirm', this.confirmAsked)
      this.$refs.panel.toggle()
    }
  }
}
</script>
<template>
  <div v-if="editorKey">
    <div class="grid">
      <div class="col-12 md:col-5">
        <Loader v-if="isTreeLoading" />
        <TreeMenuSections v-if="isMounted" @rendered="onTreeRendered" :keyVal="sectionTreeKey" :editorKey="editorKey"  />
      </div>
      <div class="col-12 md:col-6 md:col-offset-1" v-if="currentSection">
        <SectionEditor :editorMain="editorKey" 
          :section="currentSection" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, provide, computed, onMounted, defineAsyncComponent } from 'vue'

import { useFlowEditor } from '@/compositions'

import SectionEditor from './components/SectionEditor.vue'
import Loader from '@/components/resource/Loader.vue'

const TreeMenuSections = defineAsyncComponent(() => import('./components/TreeMenuSections.vue'))

export default {
  name: 'MenuMediaSections',
  props: { editorKey: String },
  components: {
    TreeMenuSections,
    SectionEditor,
    Loader,
  },
  setup (props) {
    const currentSection = ref(null)
    const isTreeLoading = ref(true)
    const isMounted = ref(false)
    const sectionTreeKey = ref(0)
    const { fieldGet, fieldObjDel, fieldReduce, saveFlowDeleteLinkElement, ...flowEditorAPI } = useFlowEditor(props.editorKey)

    const editSection = (section) => {
      currentSection.value = null
      setTimeout(() => { // TODO: THIS is ugly, to replace with a loading solution
        currentSection.value = section
      }, 0)
    }

    const recalculateTree = () => {
      sectionTreeKey.value++
    }

    const onMediaSectionRemove = (id, KFlow) => {
      const reduceData = (newArr, val) => {
        val.items = val.items ? val.items.reduce(reduceData, [])  : []
        // SI L'ID EN PARAMETRE !== L'ID COURANT
        if(val.id !== id || val.items.length >= 1){
          newArr.push(val)
        }
        return newArr
      }
      fieldObjDel({ field: 'cycle.sections', key: id })
      fieldReduce({ field: 'cycle.sectionsTree', reduce: reduceData, initialVal: [] })
      saveFlowDeleteLinkElement({
        flow: KFlow,
        id
      })
      currentSection.value = null
      recalculateTree()
    }
    const languagesIds = computed({
      get: () => (fieldGet('cycle.languages').map((l) => l.id))
    })
    const defaultLanguage = computed({
      get: () => (fieldGet('cycle.defaultLanguage'))
    })

    const onTreeRendered = () => {
      isTreeLoading.value = false
    }

    onMounted(() => {
      isMounted.value = true
    })

    provide('editSection', editSection)
    provide('currentSection', currentSection)

    provide('languages', languagesIds)
    provide('defaultLanguage', defaultLanguage)

    provide('onMediaSectionRemove', onMediaSectionRemove)

    return {
      isMounted,
      isTreeLoading,
      currentSection,
      sectionTreeKey,
      editSection,
      onTreeRendered,
      onMediaSectionRemove,
      fieldGet,
      fieldObjDel,
      saveFlowDeleteLinkElement,
      ...flowEditorAPI,
    }
  },
}
</script>

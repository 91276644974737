<template>
  <div>Static Translations</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useFlowEditor } from '@/compositions'

export default {
  name: 'MenuStaticTranslations',
  props: { editorKey: String },
  setup (props) {
    return {
      ...useFlowEditor(props.editorKey),
      v$: useVuelidate()
    }
  },
  components: {
    // TODO:
  },
  computed: {
    // TODO:
  },
  methods: {
    // TODO:
  },
  validations: {
    // TODO:
  },
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    }
  }
}
</script>

<template>
  <div v-if="editorKey">
    <div class="col-12">
      <!-- Media1 / Mediafile1 -->
      <div class="field grid align-items-baseline">
        <label for="preRollMedia1" class="col-12 md:col-1">Pre-roll 1:</label>
        <div class="col md:col-3">
          <span class="p-fluid">
            <AutoComplete id="preRollMedia1" :suggestions="media1Suggestions" v-model="preRollMedia1" @complete="searchMedia1($event)" @item-select="selectMedia1()" field="name" itemValue="id" dropdown />
          </span>
        </div>
        <div v-if="preRollMediafile1Suggestions.length > 0" class="field grid align-items-baseline col md:col-8">
          <label for="preRollMediafile1" class="col-12 md:col-3 font-bold">Pre-roll 1:</label>
          <div class="col-9">
            <div :class="`col flex align-items-center flex-wrap ${preRollMediafile1Suggestions.length > 1 ? 'mb-2' : ''}`" v-bind:key="val.id" v-for="val in preRollMediafile1Suggestions">
              <RadioButton class name="mediafile1" :value="val" v-model="preRollMediafile1"/>
              <span class="px-2">{{val.filename}}{{val.content.codec ? `.${val.content.codec.toLowerCase()}` : ''}}</span>
              <ViewFlagRow v-if="val.content.audioTracks.length > 0" :flags="val.content.audioTracks" field="language.id" flagWidth="35px" flagHeight="25px" />
              <span v-else class="text-3xl align-self-end font-light pl-3 pr-2"> - </span>
              <span class="text-3xl align-self-end font-light"> / </span>
              <ViewFlagRow v-if="val.content.burnedSubs.length > 0" :flags="val.content.burnedSubs" field="language.id" flagWidth="35px" flagHeight="25px" />
              <span v-else class="text-3xl align-self-end font-light px-2"> - </span>
              <span class="text-3xl align-self-end font-light"> / </span>
              <ViewFlagRow v-if="val.content.dynamicSubs.length > 0" :flags="val.content.dynamicSubs" field="language.id" flagWidth="35px" flagHeight="25px" useCC ccField="CC" />
              <span v-else class="text-3xl align-self-end font-light pl-2"> - </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Media2 / Mediafile2 -->
      <div class="field grid align-items-baseline">
        <label for="preRollMedia2" class="col-12 md:col-1">Pre-roll 2:</label>
        <div class="col md:col-3">
          <span class="p-fluid">
            <AutoComplete id="preRollMedia2" :suggestions="media2Suggestions" v-model="preRollMedia2" @complete="searchMedia2($event)" @item-select="selectMedia2()" field="name" itemValue="id" dropdown />
          </span>
        </div>
        <div v-if="preRollMediafile2Suggestions.length > 0" class="field grid col md:col-8 align-items-baseline">
          <label for="preRollMediafile2" class="col-12 md:col-3 font-bold">Pre-roll 2:</label>
          <div class="col-9">
            <div :class="`col flex align-items-center flex-wrap ${preRollMediafile2Suggestions.length > 1 ? 'mb-2' : ''}`" v-bind:key="val.id" v-for="val in preRollMediafile2Suggestions">
              <RadioButton class name="mediafile2" :value="val" v-model="preRollMediafile2"/>
              <span class="px-2">{{val.filename}}{{val.content.codec ? `.${val.content.codec.toLowerCase()}` : ''}}</span>
              <ViewFlagRow v-if="val.content.audioTracks.length > 0" :flags="val.content.audioTracks" field="language.id" flagWidth="35px" flagHeight="25px" />
              <span v-else class="text-3xl align-self-end font-light pl-3 pr-2"> - </span>
              <span class="text-3xl align-self-end font-light"> / </span>
              <ViewFlagRow v-if="val.content.burnedSubs.length > 0" :flags="val.content.burnedSubs" field="language.id" flagWidth="35px" flagHeight="25px" />
              <span v-else class="text-3xl align-self-end font-light px-2"> - </span>
              <span class="text-3xl align-self-end font-light"> / </span>
              <ViewFlagRow v-if="val.content.dynamicSubs.length > 0" :flags="val.content.dynamicSubs" field="language.id" flagWidth="35px" flagHeight="25px" useCC ccField="CC" />
              <span v-else class="text-3xl align-self-end font-light pl-2"> - </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { getDirective as vueDebounce } from 'vue-debounce'

import { useFlowEditor } from '@/compositions'

import AutoComplete from 'primevue/autocomplete'
import RadioButton from 'primevue/radiobutton'
import ViewFlagRow from '@/pages/portalmanager/menus/components/medias/components/tableRows/ViewFlagRow.vue'

export default {
  name: 'MenuParametersPreroll',
  props: { editorKey: String },
  setup (props) {
    const pxstream = inject('pxstream')

    const media1Suggestions = ref([])
    const media2Suggestions = ref([])

    const parametersEditor = computed(() => useFlowEditor(props.editorKey))

    const preRollMediafile1 = computed({
      get () { return parametersEditor.value?.fieldGet('preRollMediafile1') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'preRollMediafile1', value }) }
    })

    const preRollMedia1 = computed({
      get () { return parametersEditor.value?.fieldGet('preRollMedia1') },
      set (value) {
        parametersEditor.value?.fieldSet({ field: 'preRollMedia1', value })
        preRollMediafile1.value = null
        if(value?.media?.mediafiles?.length > 0 && !preRollMediafile1.value){
          preRollMediafile1.value = value.media.mediafiles[0]
        }
      }
    })

    const preRollMediafile1Suggestions = computed(() => preRollMedia1.value?.media?.mediafiles || [])

    const preRollMediafile2 = computed({
      get () { return parametersEditor.value?.fieldGet('preRollMediafile2') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'preRollMediafile2', value }) }
    })

    const preRollMedia2 = computed({
      get () { return parametersEditor.value?.fieldGet('preRollMedia2') },
      set (value) {
        parametersEditor.value?.fieldSet({ field: 'preRollMedia2', value })
        preRollMediafile2.value = null
        if(value?.media?.mediafiles?.length > 0 && !preRollMediafile2.value){
          preRollMediafile2.value = value.media.mediafiles[0]
        }
      }
    })

    const preRollMediafile2Suggestions = computed(() => preRollMedia2.value?.media?.mediafiles || [])

    const searchMedia = async (mediaSuggestions, query) => {
      try {
        const qryBuilder = pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentType.id', 'contains', ['11'])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data} = await pxstream.portalmanager.getMediaList(qryBuilder.build())
        mediaSuggestions.value = data
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get pre roll medias', detail: err.toString(), life: 4000})
      }
    }

    const selectMedia = async (media) => {
      if (media.value?.id) {
        try {
          const {data} = await pxstream.portalmanager.getMediaOne(media.value?.id)
          media.value = data
        } catch (err) {
          this.$toast.add({severity: 'error', summary: 'Failed to get pre roll medias', detail: err.toString(), life: 4000})
        }
      }
    }

    const searchMedia1 = async ({query}) => {
      searchMedia(media1Suggestions, query)
    }

    const searchMedia2 = async ({query}) => {
      searchMedia(media2Suggestions, query)
    }

    const selectMedia1 = async () => {
      selectMedia(preRollMedia1)
    }

    const selectMedia2 = async () => {
      selectMedia(preRollMedia2)
    }

    return {
      media1Suggestions,
      media2Suggestions,
      preRollMedia1,
      preRollMediafile1,
      preRollMediafile1Suggestions,
      preRollMedia2,
      preRollMediafile2,
      preRollMediafile2Suggestions,
      searchMedia1,
      searchMedia2,
      selectMedia1,
      selectMedia2
    }
  },
  directives: {
    debounce: vueDebounce(3)
  },
  components: {
    AutoComplete,
    RadioButton,
    ViewFlagRow
  }
}
</script>

<template>
  <div>
    <DataTable
      :totalRecords="ifeSystems.length"
      :value="ifeSystems"
      >
      <template #header>
        <div class="flex justify-content-between">
          <Button type="button" icon="pi pi-plus" :label="$t('buttons.addIfeProduct')" class="p-button-outlined" @click="openIfeNewDialog()"/>
        </div>
      </template>

      <template #empty>
        No product found.
      </template>

      <template #loading>
        Loading products. Please wait.
      </template>

      <Column field="ife.name" header="IFE System" />

      <Column header="Product">
       <template #body >
         Portal Manager
       </template>
      </Column>

      <Column field="ife.version" header="Version" />

      <Column field="ife.targets" header="Target">
        <template #body="{data}">
          <div class="flex w-full">
            {{data.ife.targets ? data.ife.targets.map((t) => t.name).join(', ') : '' }}
          </div>
        </template>
      </Column>

      <Column>
        <template #header>
          <div class="flex justify-content-end w-full">
            <div>{{ $t('forms.actions') }}</div>
          </div>
        </template>

        <template #body="slotProps">
          <div class="flex justify-content-end w-full">
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
              :loading="slotProps.data.__isRemoving"
              @click="$refs[`panel-${slotProps.data.ife.id}`].toggle($event)"
            />
            <OverlayPanel :ref="`panel-${slotProps.data.ife.id}`" class="delete-overlay" :dismissable="true">
              <span @click="confirmRemove(slotProps.data.ife, $refs[`panel-${slotProps.data.ife.id}`])" class="cursor-pointer underline text-danger">{{ $t('actions.remove') }}</span>
            </OverlayPanel>
          </div>
        </template>
      </Column>

    </DataTable>

    <IFEProductNew v-model="showIfeNewDialog" :editorKey="editorKey" @add-ife-product="onAddIfeProduct($event)" />
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useFlowEditor } from '@/compositions'
import { invoke, until } from '@vueuse/core'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import IFEProductNew from './IFEProductNew.vue'
import Log from '@/services/logger'

import OverlayPanel from 'primevue/overlaypanel'

export default {
  name: 'ProjectIFEProduct',
  components: {
    DataTable, Column,
    IFEProductNew,
    OverlayPanel
  },
  props: { editorKey: String },
  setup (props) {
    const {fieldGet, fieldSet, fieldObjDel} = useFlowEditor(props.editorKey)
    const showIfeNewDialog = ref(false)
    const pxstream = inject('pxstream')
    const store = useStore()
    const contentTypeIds = []
    invoke(async () => {
      await until(() => store.getters['resource/isReady']).toBe(true)
      const contentTypes = store.getters['resource/contentTypes']
      if (contentTypes) {
        contentTypes.map(({id, format}) => {
          if (format.name === "Audio" || format.name === "Movie" || format.name === "TV") {
            contentTypeIds.push(id)
          }
        })
      }
    })

    const ifeSystems = computed({
      get: () => (Object.keys(fieldGet('ifeSystems')).map((id) =>
        fieldGet(`ifeSystems.${id}`)
      )),
      set: (value) => (fieldSet({field: 'ifeSystems', value }))
    })

    const openIfeNewDialog = () => {
      showIfeNewDialog.value = true
    }

    const onAddIfeProduct = async (doc) => {
      fieldSet({field: `ifeSystems.${doc.ife.id}`, value: {
        rules: [],
        genreOverload: [],
        ...doc
      }})
      try {
        const genreQryBuilder = pxstream.tools.createQueryBuilder({rows: -1})
        genreQryBuilder.addFilter('contentTypes.id', 'contains', contentTypeIds)
        const { data: mGenreList } = await pxstream.resource.getGenres(genreQryBuilder.build())
        fieldSet({ field: `ifeSystems.${doc.ife.id}.genreOverload`, value: mGenreList.reduce((genres, genre) => {
          genres[genre.id] = {id: genre.id, genre}
          return genres
        }, {}) })
      } catch (e) {
        Log.Error(e)
      }
    }

    const confirmRemove = (data, overlayPanel) => {
      fieldObjDel({field: `ifeSystems`, key: data.id })
      if (overlayPanel) overlayPanel.hide()
    }

    return {
      showIfeNewDialog,
      ifeSystems,
      openIfeNewDialog,
      onAddIfeProduct,
      confirmRemove
    }
  }
}
</script>

<style>

</style>


export default class ErrorService {
  static onError (error) {
    console.error("Error Log : ", error.message || error)
    if (error.message) {
      console.groupCollapsed("Details")
      console.log(error)
      console.groupEnd()
    }
  }

  static onWarn (warn) {
    console.warn("Warn Log : ", warn.message || warn)
    if (warn.message) {
      console.groupCollapsed("Details")
      console.log(warn)
      console.groupEnd()
    }
  }

  static ignoreIt () {
    // honey pot
  }
}
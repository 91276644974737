<template>
  <div>
    <Dialog
      v-model:visible="showIt"
      style="width:80%"
      modal
      dismissableMask
      :content-class="{'overflow-y-visible': isOverflowVisible}"
      :closable="true"
      :draggable="false"
      class="customDialogOverFlowVisible">
      <template #header>
        <div class="flex flex-auto text-700">
          <h3 class="text-center">Edit Parameters > {{mediaName}} - {{mediafileName}}</h3>
        </div>
      </template>

      <EditingParametersCore :editorKey="editorKey" @is-general-tab="updatingContentClass($event)"/>

      <template #footer>
        <div class="flex justify-content-between">
          <Button
            label="Close"
            class="p-button-rounded"
            @click="showIt = false"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useFlowEditor } from '@/compositions'

import EditingParametersCore from "./parameters/EditingParametersCore.vue"

export default {
  name: 'EditParametersModal',
  setup: (props, { emit }) => {
    // const toast = useToast()
    const isOverflowVisible = ref(true)

    const mediafileSettingsEditor = computed(() => useFlowEditor(props.editorKey))

    const hasUpdate = computed(() => mediafileSettingsEditor.value?.hasUpdate)

    const showIt = computed({
      get () { return props.visible },
      set (value) { emit('update:visible', value) }
    })

    const mediaName = computed(() => { return mediafileSettingsEditor.value?.fieldGet('media.name') })
    const mediafileName = computed(() => { return mediafileSettingsEditor.value?.fieldGet('mediafile.filename') })

    const updatingContentClass = (e) => {
      // console.log(e)
      isOverflowVisible.value = e
    }

    return {
      isOverflowVisible,
      hasUpdate,
      showIt,
      mediaName,
      mediafileName,
      updatingContentClass
    }
  },
  props: {
    visible: Boolean,
    editorKey: String
  },
  components: {
    EditingParametersCore
  }
}
</script>

<style>
  .customDialogOverFlowVisible::-webkit-scrollbar {
    display: none !important; /* chrome */
  }
  .customDialogOverFlowVisible {
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
  }
</style>
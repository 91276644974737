<template>
  <div>
    <Dialog 
    v-model:visible="showIt" 
    style="width:300px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Add Language</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="create" >
        <div class="fluid p-4">

          <div class="field grid center">
            <LanguageSelector class="w-full" v-model="language" v-bind:excludedLanguages="languagesKeys" />
          </div>

        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text" @click="showIt = false"/>
          <Button :label="$t('actions.add')" icon="pi pi-check" :loading="isLoading" @click="add" :disabled="!language"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import Message from "primevue/message"
import LanguageSelector from '@/components/resource/LanguageSelector.vue'

// import { required, requiredUnless } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { useFlowEditor } from '@/compositions'

export default {
  name: 'DialogAddLang',
  setup: (props) => {
    return {
      language: ref(null),
      showIt: ref(false),
      isLoading: ref(false),
      errorCaught: ref(''),
      v$: useVuelidate(),
      ...useFlowEditor(props.editorKey)
    }
  },
  props: ['modelValue', 'languages'],
  emits: ['languageAdded', 'update:modelValue'],
  components: { 
    Message,
    LanguageSelector
  },

  methods: {
    resetFields () {
      this.v$.$reset()
      this.language = null
      this.errorCaught = ''
    },

    async add () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.resource.getOneLanguage(this.language)
      .then(({data}) => {
        this.showIt = false
        this.$emit('languageAdded', data)
      })
      .catch(({message}) => {
        this.errorCaught = message
      })
      .finally(() => {
        this.isLoading = false
      })
    },
  },
  computed: {
    languagesKeys: {
      get () { return (this.languages || []).map((l) => (l.id ? l.id : l)) }
    }
  },
  validations: {

  },
  watch: {
    modelValue: function (val) {
      this.showIt = val
    }
  }
}
</script>
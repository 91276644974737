<template>
  <div class="grid" v-if="section">
    <component :is="sectionComponent" :hasChildren="hasChildren" :editorKey="editorKey" /></div>
  <div v-else class="grid">
    <div class="col-12 flex justify-content-center"><span>No Selected Section</span></div>
  </div>
</template>

<script>
import { useFlowPMMenuItemBuilder } from '@/compositions'

import EditingSectionMedia from './sectionTypes/EditingSectionMedia.vue'
import EditingSectionFolder from './sectionTypes/EditingSectionFolder.vue'
import EditingSectionExternal from './sectionTypes/EditingSectionExternal.vue'
import EditingSectionPxapp from './sectionTypes/EditingSectionPxapp.vue'

import EditingSectionUnknown from './EditingSectionUnknown.vue'
import Log from '@/services/logger'

export default {
  components: { EditingSectionMedia, EditingSectionFolder, EditingSectionExternal, EditingSectionPxapp, EditingSectionUnknown },
  props: ['section', 'editorMain'],
  setup (props, { emit }) {
    return {
      ...useFlowPMMenuItemBuilder({
        propDoc: props.section,
        editorMain: props.editorMain,
        onFieldChange: data => {
          emit('field-change', data)
        },
        onUpdateChange: data => {
          emit('has-update', data)
        }
      })
    }
  },
  computed: {
    sectionComponent () {
      let sectionComponent = 'EditingSectionUnknown'

      switch (this.fieldGet('dataType')) {
        case 'media': sectionComponent = 'EditingSectionMedia'; break;
        case 'folder': sectionComponent = 'EditingSectionFolder'; break;
        case 'external': sectionComponent = 'EditingSectionExternal'; break;
        case 'pxapp': sectionComponent = 'EditingSectionPxapp'; break;
        default: Log.Debug('Unknown section type:', JSON.stringify(this.doc, null, 2));
      }

      return sectionComponent
    },
    hasChildren () {
      return this.section.items.length > 0
    }
  }
}
</script>

export default {
  install: (app) => {

    const getElapsedTime = (pPreviousDate) => {
      const currentDate = new Date()
      let diff = currentDate - pPreviousDate

      const intervals = {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      }
    
      const days = Math.floor(diff / intervals.day)
      diff -= days * intervals.day
      const hours = Math.floor(diff / intervals.hour)
      diff -= hours * intervals.hour
      const minutes = Math.floor(diff / intervals.minute)
      // diff -= minutes * intervals.minute
      // const secondes = Math.floor(diff / intervals.second)
    
      if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''}, ${hours} and hour${hours > 1 ? 's' : ''} ago`
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''} ago`
      } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
      } else {
        return 'less than one minute'
      }
    }

    app.config.globalProperties.$elapsedTime = getElapsedTime
    app.provide('elapsedTime', getElapsedTime)
  }
}

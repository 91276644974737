<template>
  <div class="card">
    <DataTable
      :value="mediafiles"
      dataKey="id"
      selectionMode="single"
      responsiveLayout="scroll"
      @row-click="edit($event.data)"
      :reorderableColumns="true">

      <template #empty>
        No media files.
      </template>

      <Column field="filename" header="Filename"></Column>
      <Column field="lab" header="Lab">
        <template #body="{data}">
          <div>
            {{ data.lab.name }}
          </div>
        </template>
      </Column>

      <!-- audio -->
      <Column header="Language" v-if="isAudioType">
        <template #body="{data}">
          <div v-if="data.content.language && data.content.language.id !== '0' ">
            <Flag :id="data.content.language.id"/>
          </div>
        </template>
      </Column>
      <!-- /audio -->

      <Column v-if="isVideoType" :header="$t('forms.audioTracks')" >
        <template #body="{data}">
          <div v-if="data && data.content && data.content.audioTracks" class="flex">
            <div v-for="(one, idx) in data.content.audioTracks" :key="idx">
              <Flag :id="one.language.id" class="mr-2"/>
            </div>
          </div>
        </template>
      </Column>

      <Column v-if="isVideoType" :header="$t('forms.burnedSubs')" >
        <template #body="{data}" >
          <div v-if="data && data.content && data.content.burnedSubs" class="flex">
            <div v-for="(one, idx) in data.content.burnedSubs" :key="idx">
              <Flag :id="one.language.id" class="mr-2"/>
            </div>
          </div>
        </template>
      </Column>

      <Column v-if="isVideoType" :header="$t('forms.dynamicSubs')" >
        <template #body="{data}" >
          <div v-if="data && data.content && data.content.dynamicSubs" class="flex">
            <div v-for="(one, idx) in data.content.dynamicSubs" :key="idx">
              <Flag :id="one.language.id" class="mr-2"/>
            </div>
          </div>
        </template>
      </Column>

      <Column>
        <template #header>
          <div class="flex justify-content-end w-full">
            <div>{{ $t('forms.actions') }}</div>
          </div>
        </template>

        <template #body="{data}">
          <div class="flex justify-content-end w-full">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="edit(data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirmRemove(data)" />
          </div>
        </template>
      </Column>

    </DataTable>

    <div class="flex">
      <Button
        label="Add Mediafile"
        class="mt-3 p-button-outlined"
        icon="pi pi-plus"
        :loading="isLoading"
        @click="create"
      />
    </div>

    <Sidebar
      v-model:visible="editMediafileShowIt"
      position="right"
      class="p-sidebar-lg"
      :loading="isLoading"
      :dismissable="false"
      :showCloseIcon="false"
    >
      <editing-mediafiles-editor
        v-model="editMediafileData"
        :mediaId="docId"
        :isInitiating="isInitiating"
        :mediafileInitFrom="firstMediaFileEditorKey"
        @close="editMediafileShowIt = false"
        @child-submit="updateDoc"
      />
    </Sidebar>

    <Dialog :header="`Delete ${selectedMediafile.filename}`" v-model:visible="showRemove" :style="{width: '350px'}" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Are you sure you want to delete this mediafile and all overrides made ?</span>
        
        <div v-if="selectedMediafile && selectedMediafile.menus">
          <p class="mt-4 mb-1">The mediafile is currently present in the menus:</p>

          <ul class="m-0">
            <li v-for="(menu, index) in selectedMediafile.menus.list" :key="`selectedMediafileMenu-${index}`">{{ menu.name }}</li>
            <li v-if="selectedMediafile.menus.total > 20">and {{ selectedMediafile.menus.total - 20 }} others menus</li>
          </ul>          
        </div>

      </div>
      <template #footer>
        <Button @click="closeRemove()" :label="$t('actions.no')" icon="pi pi-times"  class="p-button-text"/>

        <Button @click="onMediafileRemove()" :label="$t('actions.yes')" icon="pi pi-check" class="p-button-text" autofocus />
      </template>
    </Dialog>

  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import Dialog from 'primevue/dialog'

import Flag from '@/components/resource/Flag.vue'
import EditingMediafilesEditor from './EditingMediafilesEditor.vue'

import { useFlowPMMediafileBuilder, useFlowEditor } from '@/compositions'

import { computed, ref, inject } from 'vue'

const KFlowMediafile = 'portalmanager:mediafile'

export default {
  name: 'EditingMediafiles',
  props: { editorKey: String },
  components: {
    DataTable, Column, Button, Sidebar, Dialog,
    Flag, 
    EditingMediafilesEditor
  },
  setup (props) {
    const pxstream = inject('pxstream')

    const isInitiating = ref(false)
    const showRemove = ref(false)
    const selectedMediafile = ref({})

    const mediaEditor = computed(() => useFlowEditor(props.editorKey))
    const mediafiles = computed(() => mediaEditor.value?.fieldGet('media.mediafiles') || [])
    const firstMediaFileEditorKey = computed(() => {
      if (mediafiles.value.length > 0) {
        const {editorKey} = useFlowPMMediafileBuilder({
          propDoc: mediafiles.value[0],
          onFieldChange: () => {},
          onUpdateChange: () => {}
        })
        return editorKey
      }
      return null
    })

    const closeRemove = () => {
      showRemove.value = false
    }

    const confirmRemove = async (data) => {
      showRemove.value = true
      const qryBuilder = pxstream.tools.createQueryBuilder()
      qryBuilder.addFilter('cycle.sections.data.mediafiles', 'contains', [data.id])
      const {data: menus, total} = await pxstream.portalmanager.getMenuList(qryBuilder.build())
      selectedMediafile.value = {...data}
      selectedMediafile.value.menus = null
      if (menus) {
        menus.sort((a, b) => a.name > b.name)
        selectedMediafile.value.menus = {}
        selectedMediafile.value.menus.list = menus.splice(0, 20)
        selectedMediafile.value.menus.total = total
      }
    }

    return {
      isInitiating,
      mediaEditor,
      mediafiles,
      firstMediaFileEditorKey,
      ...useFlowEditor(props.editorKey),
      confirmRemove,
      closeRemove,
      selectedMediafile,
      showRemove
    }
  },
  data: () => ({
    isLoading: false,
    filter: {},
    editMediafileShowIt: false,
    editMediafileData: null
  }),
  computed: {
    docId () {
      return this.fieldGet('id')
    },
    isAudioType () {
      switch (this.fieldGet('mediaType')) {
        case 'audio-simple':
        case 'audio-podcast':
        case 'audio-radio':
        case 'broadcast-bgm':
        case 'broadcast-prams':
          return true
        default: 
          return false
      }
    },
    isVideoType () {
      switch (this.fieldGet('mediaType')) {
        case 'movie':
        case 'tv-simple':
        case 'advert':
        case 'short-movie':
        case 'trailer':
        case 'broadcast-safety':
        case 'broadcast-vpa': 
          return true
        default: 
          return false
      }

    }
  },
  methods: {
    async create () {
      this.isInitiating = true
      this.isLoading = true
      const { data } = await this.$pxstream.portalmanager.createEmptyMediafile({contentType: this.fieldGet('contentType.id')})
      data.id = 'new'
      this.editMediafileData = data
      this.editMediafileShowIt = true
      this.isLoading = false
    },
    edit (mediafile) {
      this.isInitiating = false
      this.editMediafileData = mediafile
      this.editMediafileShowIt = true
    },
    updateDoc (req) {
      if (req.type === 'new') {
        this.fieldAddToSet({field: 'media.mediafiles', value: req.doc, key: 'id'})
      }
      // Update fields info of listing
      for (let i = 0; i < this.mediafiles.length; i++) {
        if (this.mediafiles[i].id === req.id) {
          this.mediafiles[i].filename = req.doc.filename
          this.mediafiles[i].lab = req.doc.lab
          this.mediafiles[i].content = req.doc.content
          break
        }
      }
      this.saveFlowAddLink(req)
      this.editMediafileShowIt = false
    },
    onMediafileRemove () {
      this.fieldSplice({ field: 'media.mediafiles', filter: one => one.id !== this.selectedMediafile.id })
      this.saveFlowDeleteLinkElement({
        flow: KFlowMediafile,
        id: this.selectedMediafile.id
      })
      this.closeRemove()
    }
  }
}
</script>

<template>
  <tr v-if="currentRatio" role="row">
    <td class="column-title" role="cell">
      <span>{{ label }}</span>
    </td>
    <td class="column-data flex" role="cell">
      {{ currentRatio }}
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    ratio: String,
    label: String,
  },
  setup(props) {
    const RscRatios = [
      {id: '_16_9', name: '16x9'},
      {id: '_4_3', name: '4x3'}
    ]

    const currentRatio = computed(() => {
      return RscRatios.find(RscRatios => RscRatios.id === props.ratio)?.name || ''
    })

    return {
      currentRatio
    }
  },
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>

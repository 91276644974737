<template>
  <div v-if="editorKey">
    <div class="field grid">
      <label for="embeddedFilename" class="col-12 md:col-3">Embedded Filename:</label>
      <div class="p-fluid">
        <InputText id="embeddedFilename" :modelValue="embeddedFilename" :placeholder="defaultEmbeddedFilename" v-debounce:300ms="(val) => (embeddedFilename = val)" />
      </div>
    </div>
    <div class="field grid">
      <label for="parentTitle" class="col-12 md:col-3">Parent Title:</label>
      <div class="p-fluid">
        <InputText id="parentTitle" :modelValue="parentTitle" v-debounce:300ms="(val) => (parentTitle = val)" />
      </div>
    </div>
    <div v-if="hasTrailerEnabled" class="field grid">
      <label for="trailerEmbeddedFilename" class="col-12 md:col-3">Embedded Trailer Filename:</label>
      <div class="p-fluid">
        <InputText id="trailerEmbeddedFilename" :modelValue="trailerEmbeddedFilename" :placeholder="defaultTrailerEmbeddedFilename" v-debounce:300ms="(val) => (trailerEmbeddedFilename = val)" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useFlowEditor } from '@/compositions'

import { getDirective as vueDebounce } from 'vue-debounce'

import InputText from "primevue/inputtext"

export default {
  name: 'MenuParametersFilenames',
  props: { editorKey: String },
  setup (props) {
    const parametersEditor = computed(() => useFlowEditor(props.editorKey))

    const embeddedFilename = computed({
      get () { return parametersEditor.value?.fieldGet('embeddedFilename') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'embeddedFilename', value }) }
    })

    const defaultEmbeddedFilename = computed(() => {
      const overcharge = parametersEditor.value?.fieldGet('overchargedMediafile.embeddedFilename')
      const origin = parametersEditor.value?.fieldGet('mediafile.embeddedFilename')

      if (overcharge !== null && overcharge !== undefined) {
        return overcharge
      }

      return origin
    })

    const parentTitle = computed({
      get () { return parametersEditor.value?.fieldGet('parentTitle') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'parentTitle', value }) }
    })

    const trailerEmbeddedFilename = computed({
      get () { return parametersEditor.value?.fieldGet('trailerEmbeddedFilename') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'trailerEmbeddedFilename', value }) }
    })

    const defaultTrailerEmbeddedFilename = computed(() => parametersEditor.value?.fieldGet('trailer.filename') || '')

    const hasTrailerEnabled = computed(() => parametersEditor.value?.fieldGet('hasTrailerEnabled'))

    return {
      embeddedFilename,
      defaultEmbeddedFilename,
      parentTitle,
      trailerEmbeddedFilename,
      defaultTrailerEmbeddedFilename,
      hasTrailerEnabled
    }
  },
  directives: {
    debounce: vueDebounce(3)
  },
  components: {
    InputText
  }
}
</script>

<template>
  <Rating :modelValue="modelValue" @update:modelValue="update" :stars="10" :cancel="modelValue > 0"/>
</template>

<script>
import Rating from 'primevue/rating'

export default {
  components: {
    Rating,
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  methods: {
    update(rate) {
      this.$emit('update:modelValue', rate)
    }
  }
}
</script>

<style>
  .p-rating {
    position: relative;
  }

  .p-rating > .p-rating-icon.p-rating-cancel + .p-rating-icon {
    margin-left: 0;
  }

  .p-rating > .p-rating-icon.p-rating-cancel {
    position: absolute;
    right: 0px;
  }
</style>
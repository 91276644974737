<template>
  <tr v-if="currentBitrate" role="row">
    <td class="column-title" role="cell">
      <span>{{ label }}</span>
    </td>
    <td class="column-data flex" role="cell">
      {{ currentBitrate }}
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    bitrate: String,
    label: String,
  },
  setup(props) {
    const RscBitrates = [
      {id: "K128", name: "128 Kbps"},
      {id: "K192", name: "192 Kbps"},
      {id: "K256", name: "256 Kbps"}
    ]

    const currentBitrate = computed(() => {
      return RscBitrates.find(rscBitrate => rscBitrate.id === props.bitrate)?.name || ''
    })

    return {
      currentBitrate
    }
  },
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>

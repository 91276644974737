<template>
  <div>
    <TabView @tab-change="onTabChange($event)">
      <TabPanel>
        <template #header>
          <span>
            {{ $t('tabs.generalInformation') }}
          </span>
        </template>
        <MenuParametersGeneralInformation :editorKey="editorKey"/>
      </TabPanel>
      <TabPanel>
        <template #header>
          <span>
            Metadata
          </span>
        </template>
        <MenuParametersMetadata :editorKey="editorKey"/>
      </TabPanel>
      <TabPanel>
        <template #header>
          <span>
            Filenames
          </span>
        </template>
        <MenuParametersFilenames :editorKey="editorKey"/>
      </TabPanel>
      <TabPanel v-if="isPreRollTabAvailable">
        <template #header>
          <span>
            Pre-roll
          </span>
        </template>
        <MenuParametersPreRoll :editorKey="editorKey"/>
      </TabPanel>
      <TabPanel>
        <template #header>
          <span>
            Pictures
          </span>
        </template>
        <MenuParametersPictures :editorKey="editorKey"/>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import { computed } from 'vue'

import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

import MenuParametersGeneralInformation from './generalInformation/Main.vue'
import MenuParametersMetadata from './metadata/Main.vue'
import MenuParametersFilenames from './filenames/Main.vue'
import MenuParametersPreRoll from './preroll/Main.vue'
import MenuParametersPictures from './pictures/Main.vue'
import { useFlowEditor } from '@/compositions'

export default {
  name: 'EditingParametersCore',
  props: {
    editorKey: String
  },
  setup (props, { emit }) {
    const editor = computed(() => useFlowEditor(props.editorKey))

    const mediaType = computed(() => editor.value?.fieldGet('media.mediaType'))

    const isPreRollTabAvailable = computed(() => mediaType.value === 'movie' || mediaType.value === 'short-movie' || mediaType.value === 'tv-simple' || mediaType.value === 'tv-series' || mediaType.value === 'trailer')

    const onTabChange = (e) => {
      emit('is-general-tab', e.index === 0)
    }

    return {
      isPreRollTabAvailable,
      mediaType,
      onTabChange
    }
  },
  components: {
    TabView,
    TabPanel,
    MenuParametersGeneralInformation,
    MenuParametersMetadata,
    MenuParametersFilenames,
    MenuParametersPreRoll,
    MenuParametersPictures
  }
}
</script>
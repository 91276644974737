import { createStore, createLogger } from 'vuex'

import app from './app'
import adminUser from './admin-user'
import resource from './resource'
import flowPortalmanagerMedia from './flow/portalmanager-media'
import flowPortalmanagerMenu from './flow/portalmanager-menu'
import flowResourcesActors from './flow/resources-actors'
import flowResourcesArtists from './flow/resources-artists'

const plugins = []

if (process.env.VUE_APP_VUEX_DEBUG) {
  // Enable vuex mutations & actions logs
  const logger = createLogger({
    filter (mutation) {
      return mutation.type !== "app/clickOnWrapper" // fired on each clicks
    },
    logActions: true,
    logMutations: true
  })
  plugins.push(logger)
}

const store = createStore({
  plugins,
  modules: {
    app,
    adminUser,
    resource,
    flowPortalmanagerMedia,
    flowPortalmanagerMenu,
    flowResourcesActors,
    flowResourcesArtists
  }
})

export default store
<template>
  <div class="grid">
    <label class="col-12">Colors:</label>
    <div class="col-12 md:col-6">
      <div class="grid">
        <div class="col-2 text-right col-offset-5">Text</div>
        <div class="col-5">Background</div>
        <div class="col-5 text-right">Primary</div>
        <div class="col-2"><ColorPicker v-model="primaryText" /></div>
        <div class="col-5"><ColorPicker v-model="primaryBackground" /></div>
        <div class="col-5 text-right">Secondary</div>
        <div class="col-2"><ColorPicker v-model="secondaryText" /></div>
        <div class="col-5"><ColorPicker v-model="secondaryBackground" /></div>
        <div class="col-5 text-right">Accent</div>
        <div class="col-2"><ColorPicker v-model="accentText" /></div>
        <div class="col-5"><ColorPicker v-model="accentBackground" /></div>
        <div class="col-5 text-right">Info</div>
        <div class="col-2"><ColorPicker v-model="infoText" /></div>
        <div class="col-5"><ColorPicker v-model="infoBackground" /></div>
      </div>
    </div>
    <div class="col-12 md:col-6">

      <div class="grid">
        <div class="col-2 text-right col-offset-5">Text</div>
        <div class="col-5">Background</div>
        <div class="col-5 text-right">Warning</div>
        <div class="col-2"><ColorPicker v-model="warningText" /></div>
        <div class="col-5"><ColorPicker v-model="warningBackground" /></div>
        <div class="col-5 text-right">Error</div>
        <div class="col-2"><ColorPicker v-model="errorText" /></div>
        <div class="col-5"><ColorPicker v-model="errorBackground" /></div>
        <div class="col-5 text-right">Success</div>
        <div class="col-2"><ColorPicker v-model="successText" /></div>
        <div class="col-5"><ColorPicker v-model="successBackground" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/base/ColorPicker"
import { useFlowEditor } from '@/compositions'

const computedColorFactory = function (color) {
  return {
    get () { return this.fieldGet(color) || '' },
    set (value) { this.fieldSet({ field: color, value })}
  }
}

export default {
  components: { ColorPicker },
  props: { editorKey: String },
  setup (props) {
    return {
      ...useFlowEditor(props.editorKey)
    }
  },
  computed: {
    primaryText: computedColorFactory('cycle.colors.primary.text'),
    primaryBackground: computedColorFactory('cycle.colors.primary.background'),
    secondaryText: computedColorFactory('cycle.colors.secondary.text'),
    secondaryBackground: computedColorFactory('cycle.colors.secondary.background'),
    accentText: computedColorFactory('cycle.colors.accent.text'),
    accentBackground: computedColorFactory('cycle.colors.accent.background'),
    infoText: computedColorFactory('cycle.colors.info.text'),
    infoBackground: computedColorFactory('cycle.colors.info.background'),
    warningText: computedColorFactory('cycle.colors.warning.text'),
    warningBackground: computedColorFactory('cycle.colors.warning.background'),
    errorText: computedColorFactory('cycle.colors.error.text'),
    errorBackground: computedColorFactory('cycle.colors.error.background'),
    successText: computedColorFactory('cycle.colors.success.text'),
    successBackground: computedColorFactory('cycle.colors.success.background'),
  }
}
</script>

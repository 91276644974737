import { ref } from 'vue'
import mitt from 'mitt'
import { auth } from '@/plugins/auth'

import K from '@/K'

const KTopicWsckCoreStatus = 'wsck.core.status'
const KTopicWsckCorePing = 'wsck.core.ping'

const status = ref('')
const emitter = mitt()
const CTX = {
  sock: null,
  lastMessages: {},
  pinger: null,
}

const onOpen = () => {
  status.value = statusToLabel(WebSocket.OPEN)
  emitter.emit(KTopicWsckCoreStatus, { status: status.value })
}

const onClose = () => {
  status.value = statusToLabel(WebSocket.CLOSED)
  emitter.emit(KTopicWsckCoreStatus, { status: status.value })
}

const onMessage = (event) => {
  try {
    const msg = JSON.parse(event.data)
    if (msg.header.topic.startsWith(KTopicWsckCorePing)) {
      return
    }
    CTX.lastMessages[msg.header.topic] = msg.payload
    emitter.emit(msg.header.topic, msg.payload)
  } catch (e) {
    console.error(e)
  }
}

const subscribe = (topic, cb, getLast) => {
  if (getLast && CTX.lastMessages[topic]) {
    cb(CTX.lastMessages[topic])
  }
  emitter.on(topic, cb)

  const close = () => {
    emitter.off(topic, cb)
  }

  return {
    close
  }
}

const getUrl = () => {
  const token = auth.store.token
  if (!token) {
    console.error('Wsck: Empty Token')
    return ''
  }
  return `${K.CORE_WS_URL}?token=${token}`
}

const ping = () => {
  send(KTopicWsckCorePing, {})
}

const send = (topic, payload) => {
  if (CTX.sock && CTX.sock.readyState === WebSocket.OPEN) {
    CTX.sock.send(JSON.stringify({ header: { topic }, payload }))
  }
}

const connect = () => {
  setTimeout(connect.bind(this), 2000)
  const url = getUrl()
  if (!url) {
    return
  }
  if (!CTX.sock || CTX.sock.readyState >= WebSocket.CLOSING) {
    CTX.sock = new WebSocket(url)
    CTX.sock.onmessage = function (event) {
      onMessage(event)
    }
    CTX.sock.onopen = function (event) {
      onOpen(event)
    }
    CTX.sock.onclose = function (event) {
      onClose(event)
    }
    status.value = statusToLabel(WebSocket.CONNECTING)
    emitter.emit(KTopicWsckCoreStatus, { status: status.value })
  }

  if (!CTX.pinger) {
    CTX.pinger = setInterval(ping, 10000)
  }
}

export function useWsckSetup() {
  connect()

  return { status }
}

export function useWsck() {
  return {
    status,
    sendTo: function (topic, payload) {
      send(topic, payload)
    },
  }
}

export function useWsckOn(topic, cb, getLast) {
  return subscribe(topic, cb, getLast)
}

function statusToLabel(status) {
  switch (status) {
    case WebSocket.CONNECTING:
      return 'CONNECTING'
    case WebSocket.OPEN:
      return 'OPEN'
    case WebSocket.CLOSING:
      return 'CLOSING'
    case WebSocket.CLOSED:
      return 'CLOSED'
  }
}
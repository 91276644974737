<template>
  <div>
    <Dropdown
      v-model="selectedLang"
      :options="availableLanguages"
      optionValue="id"
      :class="{'compact': compact}"
      placeholder="Select a lang"
      :showClear="showClear"
      @change="change">

      <template #value="slotProps">
        <div v-if="slotProps && slotProps.value">
          <div class="flex justify-content-between align-items-center">
            <LanguageFlagDisplay :language="languageById(slotProps.value)" />
            <span v-if="!hideLanguageName" class="ml-3">{{languageById(slotProps.value) ? languageById(slotProps.value).name : 'UNK'}}</span>
          </div>
        </div>
        <span v-else>
          {{placeholder}}
        </span>
      </template>

      <template #option="slotProps">
        <div class="flex justify-content-between align-items-center">
          <LanguageFlagDisplay :language="slotProps.option" />
          <span class="ml-3">{{slotProps.option.name}}</span>
        </div>
      </template>

    </Dropdown>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown'
import { mapGetters } from 'vuex'
import LanguageFlagDisplay from './LanguageFlagDisplay.vue'

export default {
  props: {
    modelValue: [String, Object],
    excludedLanguages: {
      type: Array,
      default: () => ([])
    },
    onlyLanguages: {
      type: Array,
      default: () => ([])
    },
    hideLanguageName: Boolean,
    compact: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Function,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Select a lang'
    },
    showClear: {
      type: Boolean,
      default: false
    },
    getOnlyID: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'update:modelValue',
    'change'
  ],
  components: {
    Dropdown,
    LanguageFlagDisplay
  },
  data () {
    return {
      selectedLang: this.modelValue && this.modelValue.id ? this.modelValue.id : this.modelValue
    }
  },
  computed: {
    ...mapGetters('resource', ['languages', 'languageById']),
    availableLanguages: {
      get () {
        let res = []
        if (this.onlyLanguages.length) {
          res = this.languages.filter(lang => this.onlyLanguages.find(langToInclude => langToInclude === lang.id))
        } else {
          res = this.languages.filter(lang => !this.excludedLanguages.find(excludedLang => excludedLang === lang.id))
        }

        if (this.sort) {
          res.sort(this.sort)
        }
        return res
      }
    }
  },
  methods: {
    change () {
      const valToEmit = this.selectedLang && !this.getOnlyID ? this.languageById(this.selectedLang) : this.selectedLang
      this.$emit('update:modelValue', valToEmit)
      this.$emit('change', valToEmit)
    }
  },
  watch: {
    modelValue () {
      this.selectedLang = this.modelValue && this.modelValue.id ? this.modelValue.id : this.modelValue
    }
  }
}
</script>

<style>
/* Compact Style */
.p-dropdown.p-inputwrapper-filled.compact .p-dropdown-label {
  padding: 4px;
}

.p-dropdown.p-inputwrapper-filled.compact .my-flag {
  width: 32px;
  height: 24px;
}
</style>
const CORE_BASE_URL = '/core'
const CORE_BASE_WS = `${CORE_BASE_URL}/ws`
const CORE_HOST = window.location.host
const CORE_WS_PROTOCOL = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

const K = {
  CORE_HTTP_URL: CORE_BASE_URL,
  CORE_WS_URL: `${CORE_WS_PROTOCOL}//${CORE_HOST}${CORE_BASE_WS}`,
}
console.log('host      : ', CORE_HOST)
console.log('protocol  : ', CORE_WS_PROTOCOL)
console.log('CORE URL  : ', K)

// export const isDev = import.meta.env.DEV
// export const isProd = import.meta.env.PROD

export default K

<template>
  <tr v-if="rating !== null && rating >= 1" role="row">
    <td class="column-title" role="cell">
      <span>{{label}}:</span>
    </td>
    <td class="column-data" role="cell">
      <Rating :modelValue="value" :stars="10" :readonly="true" :cancel="false" />
    </td>
  </tr>
</template>

<script>

import Rating from 'primevue/rating'

export default {
  props: {
    rating: {
      type: Number,
      default: 1
    },
    maxRating: {
      type: Number,
      default: 10
    },
    label: {
      type: String,
      default: ''
    },
  },
  components: {
    Rating
  },
  computed: {
    value () {
      return this.rating
    }
  }
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>
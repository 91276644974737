const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const isDebugMode = urlParams.get('debug') === '1'
const isVerboseMode = urlParams.get('verbose') === '1'

const Log = (process.env.NODE_ENV === 'development' || isVerboseMode) ? (...args) => {
    console.log.apply(this, args)
} : () => {/* do nothing */}

Log.Debug = (process.env.NODE_ENV === "development" || isVerboseMode || isDebugMode) ? (...args) => {
    console.log.apply(this, [ "Debug @ ", ...args])
} : () => {/* do nothing */}

Log.Error = (...args) => {
  console.error.apply(this, [ "Error Log : ", ...args])
}

Log.Warn = (...args) => {
  console.warn.apply(this, ["Warn Log : ", ...args])
}

export default Log

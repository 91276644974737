import { reactive } from "@vue/reactivity"

class Breadcrumb {
  constructor () {
    this.store = reactive({
      title: '',
      caption: '',
      badge: '',
      home: {icon: 'pi pi-home', to: '/'},
      items: []
    })
  }

  getTitle () {
    return this.store.title || this.store.items.map(item => item.label).join('_')
  }

  getCaption () {
    return this.store.caption
  }

  getBadge () {
    return this.store.badge
  }

  getHome () {
    return this.store.home
  }

  getItems () {
    return this.store.items
  }

  setCurrent (title, items, opts) {
    opts = opts || {}
    this.store.title = title
    this.store.items = items
    this.store.caption = opts.caption || ''
    this.store.badge = opts.badge || ''
  }

  hasItems () {
    return !!this.store.items.length
  }
}

export default {
  install: (app) => {
    const breadcrumb = new Breadcrumb()
    app.config.globalProperties.$breadcrumb = breadcrumb
    app.provide('breadcrumb', breadcrumb)
  }
}

import 'primevue/resources/themes/bootstrap4-light-purple/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'prismjs/themes/prism-coy.css'
import '@fullcalendar/core/main.min.css'
import '@fullcalendar/daygrid/main.min.css'
import '@fullcalendar/timegrid/main.min.css'
import '@/assets/layout/layout.scss'
import '@/assets/layout/flags/flags.css'

import { createApp } from 'vue'
import router, { LOGIN_PAGE, DASHBOARD_PAGE } from './router'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import ErrorService from '@/services/errors'

import EditingCore from '@/pages/portalmanager/media/components/EditingCore.vue'

import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Ripple from 'primevue/ripple'
import Tooltip from 'primevue/tooltip'

// import CodeHighlight from './AppCodeHighlight';
import store from '@/store'
import http from '@/plugins/http'
import authPlugin, { auth } from '@/plugins/auth'

import breadcrumb from '@/plugins/breadcrumb'
import pxstream from '@/plugins/pxstream'
import elapsedTime from '@/plugins/elapsedTime'

import { useMock } from "@mocks/api"
import useI18n from '@/compositions/useI18n'

if (process.env.NODE_ENV === "development") {
  useMock()
}


router.beforeEach(async function(to, from, next) {
  window.scrollTo(0, 0)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isLogged = await auth.isLogged()
    if (!isLogged) {
      return next({ name: LOGIN_PAGE })
    }
  } else if (to.matched.some(record => record.meta.requiresNotLogged)) {
    const isLogged = await auth.isLogged()
    if (isLogged) {
      return next({ name: DASHBOARD_PAGE })
    }
  }
  next()
})

const { i18n } = useI18n()

const app = createApp(App)

app.config.errorHandler = ErrorService.onError
app.config.warnHandler = ErrorService.onWarn

app.use(PrimeVue, { ripple: false })
app.use(ConfirmationService)
app.use(ToastService)
app.use(router)
app.use(i18n)
app.use(store)
app.use(http)
app.use(authPlugin)
app.use(breadcrumb)
app.use(pxstream)
app.use(elapsedTime)

// Useful components
app.component('Button', Button)
app.component('Dialog', Dialog)
app.component(EditingCore.name, EditingCore)

// app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple)
app.directive('tooltip', Tooltip)
// app.directive('code', CodeHighlight);

app.mount('#app')

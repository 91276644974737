<template>
  <tr v-if="value && value !== null" role="row">
    <td class="column-title" role="cell">
      <span>Explicit:</span>
    </td>
    <td class="column-data" role="cell">
      <span>{{ value }} </span>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object],
      default: () => {}
    },
  },
  computed: {
    value () {
      let explicit = []
      if (this.data.hasOffensiveLanguage) explicit.push('Offensive Language')
      if (this.data.hasViolence) explicit.push('Violence')
      if (this.data.hasSex) explicit.push('Sex')

      return explicit.join(', ')
    }
  }
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>
<template>
  <div id="mediaMore__details">
    <h4>{{ mediaMore.name }}</h4>
    <div class="p-datatable-wrapper">
      <table role="table" class="p-datatable-table">
        <tbody class="p-datatable-tbody" role="rowgroup">

          <div class="p-datatable-separation" />
          <ViewRuntimeRow :runtime="mediaMore.more.media.runtime" />

          <div class="p-datatable-separation" />
          <ViewRow label="Licensor" field="name" :data="mediaMore.more.licensor" />
          <ViewRow label="Content Owner" field="name" :data="mediaMore.more.contentOwner" />
          <ViewRow label="Providor" field="name" :data="mediaMore.more.provider" />
          <ViewRow :label="$t('forms.copyright')" :data="mediaMore.more.copyright" />

          <div class="p-datatable-separation" />
          <ViewRow label="Mediafiles" :data="mediaMore.nbMediafiles" />

          <div class="p-datatable-separation" />
          <ViewFlagRow field="id"  :label="$t('medias.tracks')" :flags="mediaMore.audioTracks" flagWidth="25px" flagHeight="20px" />
          <ViewFlagRow field="id" label="Burned in Sub" :flags="mediaMore.burnedSubs" flagWidth="25px" flagHeight="20px" />
          <ViewFlagRow field="language.id" label="Dynamic Sub" :flags="mediaMore.dynamicSubs" flagWidth="25px" flagHeight="20px" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ViewRow from '../tableRows/ViewRow.vue'
import ViewFlagRow from '../tableRows/ViewFlagRow.vue'
import ViewRuntimeRow from '../tableRows/ViewRuntimeRow.vue'

export default {
  components: {
    ViewRow,
    ViewFlagRow,
    ViewRuntimeRow
  },
  props: {
    mediaMore: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style lang="postcss" scoped>
  h4 {
    font-weight: bold;
  }

  .p-datatable-table * {
    border : 0px !important;
  }

  .p-datatable-separation {
    margin-top: 1rem;
  }
</style>
<template>
  <div v-if="editorKey">
    <div class="grid">
      <div class="col-12 md:col-6">

        <div class="field grid">
          <label for="codec" class="col-12 md:col-4">Codec</label>
          <div class="col md:col-8">
            <Dropdown v-model="codec" :options="rscVideoCodecs" optionValue="id" optionLabel="name" placeholder="Select a codec" class="w-full" />
          </div>
        </div>

        <div class="field grid">
          <label for="isEncrypted" class="col-12 md:col-4">Encrypted</label>
          <div class="col md:col-8">
            <InputSwitch v-model="isEncrypted " id="isEncrypted" />
          </div>
        </div>

        <div class="field grid">
          <label for="systemsCompliance" class="col-12 md:col-4">Systems Compliance</label>
          <div class="col md:col-8">
            <MultiSelect v-model="ife" :options="rscIfeProducts" optionLabel="name" class="w-full" />
          </div>
        </div>
        
        <div class="field grid">
          <label for="embeddedFilename" class="col-12 md:col-4">Embedded filename</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
            </span>
          </div>
        </div>

      </div>
      <div class="col-12 md:col-5 md:col-offset-1">

        <div class="field grid">
          <label for="videoBitrate" class="col-12 md:col-4">Video Bitrate</label>
          <div class="col md:col-8">
            <Dropdown v-model="videoBitrate" :options="rscVideoBitrates" optionValue="id" optionLabel="name" placeholder="Select video bitrate" class="w-full" />
          </div>
        </div>
        
        <div class="field grid">
          <label for="isEncrypted" class="col-12 md:col-4">Lab</label>
          <div class="col md:col-8">
            <AutoComplete
              v-model="lab"
              dropdown
              field="name"
              itemValue="name"
              :suggestions="rscLabs"
              @complete="searchLabs($event)">
            </AutoComplete>
          </div>
        </div>

        <div class="field grid">
          <label for="resolution" class="col-12 md:col-4">Resolution</label>
          <div class="col md:col-8">
            <Dropdown v-model="resolution" :options="rscResolutions" optionValue="id" optionLabel="name" placeholder="Select resolution" class="w-full" />
          </div>
        </div>

        <div class="field grid">
          <label for="ratio" class="col-12 md:col-4">Ratio</label>
          <div class="col md:col-8">
            <Dropdown v-model="ratio" :options="rscRatios" optionValue="id" optionLabel="name" placeholder="Select ratio" class="w-full" />
          </div>
        </div>

        <div class="field grid">
          <label for="boxType" class="col-12 md:col-4">Box Type</label>
          <div class="col md:col-8">
            <Dropdown v-model="boxType" :options="rscBoxTypes" optionValue="id" optionLabel="name" placeholder="Select box type" class="w-full" />
          </div>
        </div>

      </div>
    </div>

    <div>
      <DataTable :value="audioTracks" @row-reorder="onRowReorder" responsiveLayout="scroll" class="p-datatable-sm">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column headerStyle="width: 10rem">
          <template #body="{index}">
            Audio Track {{ index }}
          </template>
        </Column>
        <Column>
          <template #body="{data}">
            <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" class="w-full"/>
          </template>
        </Column>
      </DataTable>
      <br/>
      <div class="flex justify-content-center">
        <Button label="Add audio track" class="p-button-outlined" icon="pi pi-plus-circle" @click="addAudioTrack"/>
      </div>
    </div>

    <br/>

    <div>
      <DataTable :value="burnedSubs" @row-reorder="onRowReorder" responsiveLayout="scroll" class="p-datatable-sm">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column headerStyle="width: 10rem">
          <template #body="{index}">
            Burned In Sub {{ index }}
          </template>
        </Column>
        <Column>
          <template #body="{data}">
            <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" class="w-full"/>
          </template>
        </Column>
      </DataTable>
      <br/>
      <div v-if="burnedSubsLength < 2" class="flex justify-content-center">
        <Button label="Add burned in sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addBurnedSub"/>
      </div>
    </div>

    <br/>

    <div>
      <DataTable :value="dynamicSubs" @row-reorder="onRowReorder" responsiveLayout="scroll" class="p-datatable-sm">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column headerStyle="width: 10rem">
          <template #body="{index}">
            Dynamic Sub {{ index }}
          </template>
        </Column>
        <Column>
          <template #body="{data}">
            <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" class="w-full"/>
          </template>
        </Column>
        <Column>
          <template #body="{data}">
            <div class="grid">
              <label for="isEncrypted" class="col-12 md:col-4">CC</label>
              <div class="col md:col-8">
                <InputSwitch v-model="data.CC" @change="checkHasUpdate()" />
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
      <br/>
      <div class="flex justify-content-center">
        <Button label="Add dynamic sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addDynamicSub"/>
      </div>
    </div>
  </div>

</template>

<script>
import { useFlowEditor } from '@/compositions/useFlowEditor'

export default {
  props: { editorKey: String },
  setup (props) {
    return useFlowEditor(props.editorKey)
  },
  data: () => ({
    rscVideoCodecs: [],
    rscIfeProducts: [],
    rscVideoBitrates: [],
    rscLabs: [],
    rscResolutions: [],
    rscRatios: [],
    rscBoxTypes: []
  }),
  computed:{
    codec: {
      get () { return this.fieldGet('content.codec') },
      set (val) { this.fieldSet({field: 'content.codec', value: val}) }
    },
    isEncrypted: {
      get () { return this.fieldGet('content.encrypted') },
      set (val) { this.fieldSet({field: 'content.encrypted', value: val}) }
    },
    ife: {
      get () { return this.fieldGet('content.systemsCompliance') },
      set (val) { this.fieldSet({field: 'content.systemsCompliance', value: val}) }
    },
    embeddedFilename: {
      get () { return this.fieldGet('embeddedFilename') },
      set (val) { this.fieldSet({field: 'embeddedFilename', value: val}) }
    },
    videoBitrate: {
      get () { return this.fieldGet('content.videoBitrate') },
      set (val) { this.fieldSet({field: 'content.videoBitrate', value: val}) }
    },
    lab: {
      get () { return this.fieldGet('lab') },
      set (val) { this.fieldSet({field: 'lab', value: val}) }
    },
    resolution: {
      get () { return this.fieldGet('content.resolution') },
      set (val) { this.fieldSet({field: 'content.resolution', value: val}) }
    },
    ratio: {
      get () { return this.fieldGet('content.ratio') },
      set (val) { this.fieldSet({field: 'content.ratio', value: val}) }
    },
    boxType: {
      get () { return this.fieldGet('content.boxType') },
      set (val) { this.fieldSet({field: 'content.boxType', value: val}) }
    },
    audioTracks: {
      get () { return this.fieldGet('content.audioTracks') },
      set (val) { this.fieldSet({field: 'content.audioTracks', value: val}) }
    },
    burnedSubs: {
      get () { return this.fieldGet('content.burnedSubs') },
      set (val) { this.fieldSet({field: 'content.burnedSubs', value: val}) }
    },
    dynamicSubs: {
      get () { return this.fieldGet('content.dynamicSubs') },
      set (val) { this.fieldSet({field: 'content.dynamicSubs', value: val}) }
    },
  },
  methods: {
    addAudioTrack () {
      this.fieldPush({field: 'content.audioTracks', value: {
        language: null
      }})
    },
    addBurnedSub () {
      this.fieldPush({field: 'content.burnedSubs', value: {
        language: null
      }})
    },
    addDynamicSub () {
      this.fieldPush({field: 'content.dynamicSubs', value: {
        language: null,
        CC: false
      }})
    },
    async searchVideoCodecs () {
      const {data} = await this.$pxstream.resource.getVideoCodecs()
      this.rscVideoCodecs = data
    },
    async searchLabs ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data} = await this.$pxstream.resource.getLabs(qryBuilder.build())
        this.rscLabs = data
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get labs', detail: err.toString(), life: 4000})
      }
    },
    async searchIfeProducts ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data} = await this.$pxstream.resource.getIfeProducts(qryBuilder.build())
        this.rscIfeProducts = data
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get ife produts', detail: err.toString(), life: 4000})
      }
    },
  },
  mounted () {
    this.searchIfeProducts({})
  }
}
</script>
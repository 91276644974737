
import genericEditor from '../generic/editor'

function updateField (field, value) {
  field
  return value
}

function onCreate (doc) {
  doc
  return {}
}

const generic = genericEditor('portalmanager:menu', updateField, onCreate)

const store = {
  namespaced: true,
  state: () => ({
    ...generic.state(),
  }),
  getters: {
    ...generic.getters,
  },
  mutations: {
    ...generic.mutations,
  },
  actions: {
    ...generic.actions,
  }
}

export default store

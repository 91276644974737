
import revision from '../revision'

console.log("REVISION: ", JSON.stringify(revision))

const app = {
  namespaced: true,
  state: () => ({
    revision: revision,
    menu: menu,
    menuClick: false,
    isDesktop: window.innerWidth > 1024,
    layoutMode: 'static',
    layoutColorMode: 'dark',
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false
  }),
  mutations: {
    setDesktopMode (state, bool) {      
      state.isDesktop = bool
    },
    toggleMenu (state) {
      state.menuClick = true
      if (state.isDesktop) {
        if (state.layoutMode === 'overlay') {
          if(state.mobileMenuActive === true) {
            state.overlayMenuActive = true
          }

          state.overlayMenuActive = !state.overlayMenuActive
          state.mobileMenuActive = false
        } else if (state.layoutMode === 'static') {
          state.staticMenuInactive = !state.staticMenuInactive
        }
      } else {
        state.mobileMenuActive = !state.mobileMenuActive
      }
    },
    clickOnWrapper (state) {
      if (!state.menuClick) {
        state.overlayMenuActive = false
        state.mobileMenuActive = false
      }
      state.menuClick = false
    },
    clickOnMenu (state) {
      state.menuClick = true
    },
    resetMenuClick (state) {
      state.menuClick = false
    },
    clickOnMenuItem (state, args) {
      if (args.item && !args.item.items) {
        state.overlayMenuActive = false;
        state.mobileMenuActive = false;
      }
    },
    updateBodyOverflow (state) {
      if (state.mobileMenuActive) {
        addClass(document.body, 'body-overflow-hidden')
      } else {
        removeClass(document.body, 'body-overflow-hidden')
      }
    },
  },
  getters: {
    revision: state => state.revision,
    isDesktop: state => state.isDesktop,
    isSidebarVisible: state => {
      if (state.isDesktop) {
        if (state.layoutMode === 'static') {
          return !state.staticMenuInactive
        } else if (state.layoutMode === 'overlay') {
          return state.overlayMenuActive
        } else {
          return true
        }
      } else {
        return true
      }
    },
    containerClass: state => {
      return ['layout-wrapper', {
        'layout-overlay': state.layoutMode === 'overlay',
        'layout-static': state.layoutMode === 'static',
        'layout-static-sidebar-inactive': state.staticMenuInactive && state.layoutMode === 'static',
        'layout-overlay-sidebar-active': state.overlayMenuActive && state.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': state.mobileMenuActive,
        // 'p-input-filled': this.$appState.inputStyle === 'filled',
        // 'p-ripple-disabled': this.$primevue.config.ripple === false
      }]
    },
    sidebarClass: state => {
      return ['layout-sidebar', {
        'layout-sidebar-dark': state.layoutColorMode === 'dark',
        'layout-sidebar-light': state.layoutColorMode === 'light'
      }]
    },
    // logo: state => state.layoutColorMode === 'dark' ? "assets/layout/images/logo-white.svg" : "assets/layout/images/logo.svg",
    logo: () => '/assets/logo_pxcom_bw.png',
    menu: state => state.menu
  }
}

export default app

function addClass(element, className) {
  if (element.classList)
  element.classList.add(className);
  else
  element.className += ' ' + className;
}

function removeClass(element, className) {
  if (element.classList)
  element.classList.remove(className);
  else
  element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}

const menu = [
  //{label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
  // {
  //   label: 'Digital Advertising', icon: 'pi pi-briefcase',
  //   items: [
  //     {label: 'Types', xicon: 'pi pi-reply', to: '/airsas/types'},
  //     {label: 'Formats', xicon: 'pi pi-window-maximize', to: '/airsas/formats'},
  //     {label: 'Products', xicon: 'pi pi-paperclip', to: '/airsas/products'},
  //     {label: 'Campaigns', xicon: 'pi pi-sitemap', to: '/airsas/campaigns'}
  //   ]
  // },
  {label: 'Projects', icon: 'pi pi-fw pi-sitemap', to: '/projects'},
  {
    label: 'Portal Manager', icon: 'pi pi-book',
    items: [
      {label: 'Media Library', xicon: 'pi pi-reply', to: '/portal-manager/media-library'},
      {label: 'Menus', xicon: 'pi pi-sitemap', to: '/portal-manager/menus'},
    ]
  },
  {
    label: 'Resources', icon: 'pi pi-images',
    items: [
      { label: 'Artists', icon: '', to: '/resources/artists' },
      { label: 'Actors', icon: '', to: '/resources/actors' },
      { label: 'Genres', icon: '', to: '/resources/genres' },
      //{ label: 'Languages', icon: '', to: '/resources/languages' },
    ]
  },
  {
    label: 'Admin', icon: 'pi pi-briefcase',
    items: [
      {label: 'Users', icon: 'pi pi-users', to: '/admin/users'}
    ]
  }
  // {
  //   label: 'UI Kit', icon: 'pi pi-fw pi-sitemap',
  //   items: [
  //     {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout'},
  //     {label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input'},
  //     {label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel"},
  //     {label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate"},
  //     {label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button'},
  //     {label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
  //     {label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
  //     {label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
  //     {label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
  //     {label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
  //     {label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu'},
  //     {label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
  //     {label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
  //     {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
  //     {label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
  //   ]
  // },
  // {
  //   label: "Utilities", icon:'pi pi-fw pi-globe',
  //   items: [
  //     {label: 'Display', icon:'pi pi-fw pi-desktop', to:'/display'},
  //     {label: 'Elevation', icon:'pi pi-fw pi-external-link', to:'/elevation'},
  //     {label: 'Flexbox', icon:'pi pi-fw pi-directions', to:'/flexbox'},
  //     {label: 'Icons', icon:'pi pi-fw pi-search', to:'/icons'},
  //     {label: 'Grid System', icon:'pi pi-fw pi-th-large', to:'/grid'},
  //     {label: 'Spacing', icon:'pi pi-fw pi-arrow-right', to:'/spacing'},
  //     {label: 'Typography', icon:'pi pi-fw pi-align-center', to:'/typography'},
  //     {label: 'Text', icon:'pi pi-fw pi-pencil', to:'/text'},
  //   ]
  // },
  // {
  //   label: 'Pages', icon: 'pi pi-fw pi-clone',
  //   items: [
  //     {label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'},
  //     {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar'},
  //     {label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
  //     {label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
  //   ]
  // },
  // {
  //   label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
  //   items: [
  //     {
  //       label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
  //       items: [
  //         {
  //           label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
  //           items: [
  //             {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark'},
  //             {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark'},
  //             {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark'},
  //           ]
  //         },
  //         {
  //           label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
  //           items: [
  //             {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark'},
  //             {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark'}
  //           ]
  //         },
  //       ]
  //     },
  //     {
  //       label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
  //       items: [
  //         {
  //           label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
  //           items: [
  //             {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark'},
  //             {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark'},
  //             {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark'},
  //           ]
  //         },
  //         {
  //           label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
  //           items: [
  //             {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark'},
  //             {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark'}
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => {window.location = "#/documentation"}},
  // {label: 'View Source', icon: 'pi pi-fw pi-search', command: () => {window.location = "https://github.com/primefaces/sigma-vue"}}
]

<template>
  <div class="col-12">
    <DataTable
      ref="dt"
      class="p-datatable-sm media-modal-datatable"
      stripedRows
      showGridlines
      filterDisplay="menu"
      responsiveLayout="scroll"
      :value="mediasList"
      @row-reorder="onRowReorder">

      <Column :rowReorder="!isPreviousCycle" headerStyle="width: 3rem"/>

      <Column field="contentType.name" header="Type"></Column>

      <Column field="name" :header="$t('forms.title')"></Column>

      <Column field="artists" :header="$t('medias.artists')" v-if="mediasAreOnlyAudio"></Column>

      <Column field="nbMediafiles" header="Nb Mediafiles"></Column>

      <Column :header="$t('medias.tracks')">
        <template #body="{data}">
          <ViewFlagRow field="id" :flags="data.audioTracks" :max="3" />
        </template>
      </Column>

      <Column header="Burned in Sub" v-if="!mediasAreOnlyAudio">
        <template #body="{data}">
          <ViewFlagRow field="id" :flags="data.burnedSubs" :max="3" />
        </template>
      </Column>

      <Column header="Dynamic Sub" v-if="!mediasAreOnlyAudio">
        <template #body="{data}">
          <ViewFlagRow field="language.id" :flags="data.dynamicSubs" :max="3" useCC />
        </template>
      </Column>

      <Column>
        <template #header>
          <div class="flex w-full justify-content-end">
            <Button type="button" :label="$t('actions.groupRemove')" :class="{ 'uppercase': true, 'p-button-outlined': !isGroupDelete }" @click="toggleGroupDelete()" />
          </div>
        </template>
        <template #body="{data}">
          <div class="flex justify-content-end align-items-center">
            <BtnConfirm
              v-if="!isGroupDelete"
              :btnClass="'p-button-danger'"
              :loading="data.id === removingMediaId"
              :disabled="!!removingMediaId || isPreviousCycle"
              @confirm="onMediaRemove(data)"
            />
            <Checkbox
              v-else
              :value="data"
              v-model="mediasToRemove"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div class="flex mt-2 w-full justify-content-end">
      <div>
        <Button type="button" icon="pi pi-trash" :label="$t('actions.removeSelection')" class="uppercase p-button-outlined" @click="confirmGroupRemove()" :disabled="(mediasToRemove.length === 0)" />
      </div>
    </div>
    <Dialog :header="`${$t('actions.groupRemove')}`" v-model:visible="showConfirmGroupRemove" :style="{width: '350px'}" modal>
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>{{ $t('menus.confirmGroupRemoveForMedias')}}</span>

        <div v-if="mediasToRemove">
          <ul class="mt-2">
            <li v-for="(media, index) in mediasToRemove" :key="`mediafileToRemove-${index}`">{{ media.name }}</li>
          </ul>
        </div>

      </div>
      <template #footer>
        <Button @click="closeConfirmGroupRemove()" :label="$t('actions.no')" icon="pi pi-times"  class="p-button-text"/>
        <Button @click="onMediaGroupRemove()" :label="$t('actions.yes')" icon="pi pi-check" class="p-button-text" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { inject, ref, computed } from 'vue'
import { useFlowEditor } from '@/compositions'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Checkbox from 'primevue/checkbox'

import ViewFlagRow from './tableRows/ViewFlagRow.vue'

import BtnConfirm from '@/components/BtnConfirm.vue'

const KFluxMedia = 'portalmanager:media'
const KFluxMediafile = 'portalmanager:mediafile'
const KFluxMediafileSettings = 'portalmanager:menu-mediafile-settings'

export default {
  name: 'MenuMediasTableMedias',
  setup: (props) => {
    const removingMediaId = ref();
    const pxstream = inject('pxstream')
    const isPreviousCycle = inject('isPreviousCycle')

    const isGroupDelete = ref(false)
    const mediasToRemove = ref([])
    const showConfirmGroupRemove = ref(false)

    const confirmGroupRemove = () => {
      showConfirmGroupRemove.value = true
    }

    const closeConfirmGroupRemove = () => {
      showConfirmGroupRemove.value = false
    }

    const getMediafilesOfMedia = (acc, val) => {
      if (val.collection === KFluxMedia) {
        acc = acc.concat((val.children || []).reduce(getMediafilesOfMedia, []))
      } else if (val.collection === KFluxMediafile) {
        const newMediafile = mediafilesById.value[val.id]
        if (newMediafile) {
          acc.push(newMediafile)
        }
      }
      return acc
    }

    const getAudioTracksFromMediafileContent = (acc, val) => {
      if (val.content.language) {
        if (val.content.language.id !== "0" && !acc.includes(val.content.language.id)) {
          acc.push(val.content.language)
        }
      } else {
        acc = acc.concat(val.content.audioTracks.map(audioTrack => audioTrack.language).filter(audioTrack => !acc.find(val => val.id === audioTrack.id)))
      }
      return acc
    }

    const getBurnedSubsFromMediafileContent = (acc, val) => {
      if (val.content.burnedSubs) {
        acc = acc.concat(val.content.burnedSubs.map(burnedSub => burnedSub.language).filter(burnedSub => !acc.find(val => val.id === burnedSub.id)))
      }
      return acc
    }

    const getDynamicSubsFromMediafileContent = (acc, val) => {
      if (val.content.dynamicSubs) {
        acc = acc.concat(val.content.dynamicSubs.map(dynamicSub => ({
          language: dynamicSub.language,
          cc: dynamicSub.CC
        })).filter(dynamicSub => !acc.find(val => val.language.id === dynamicSub.language.id && val.cc === dynamicSub.cc)))
      }
      return acc
    }

    const editor = computed(() => { return useFlowEditor(props.editorKey) })

    const medias = computed({
      get () { return editor.value?.fieldGet('data.medias') || [] },
      set (value) { editor.value?.fieldSet({ field: 'data.medias', value }) }
    })
    const mediasById = computed(() => {
      return medias.value.reduce((acc, val) => {
        acc[val.id] = val
        return acc
      }, {})
    })

    const mediafiles = computed({
      get () { return editor.value?.fieldGet('data.mediafiles') || [] },
      set (value) { editor.value?.fieldSet({ field: 'data.mediafiles', value }) }
    })
    const mediafilesById = computed(() => {
      return mediafiles.value.reduce((acc, val) => {
        acc[val.id] = val
        return acc
      }, {})
    })

    const mediafilesTree = computed({
      get () { return editor.value?.fieldGet('data.mediafilesTree') || [] },
      set (value) { editor.value?.fieldSet({ field: 'data.mediafilesTree', value }) }
    })

    const mediafilesSettings = computed({
      get () { return editor.value?.fieldGet('data.mediafilesSettings') || [] },
      set (value) { editor.value?.fieldSet({ field: 'data.mediafilesSettings', value }) }
    })

    const mediasList = computed(() => {
      return mediafilesTree.value.map(treeItem => {
        const fullMedia = mediasById.value[treeItem.id]
        const fullMediafilesOfMedia = treeItem.children.reduce(getMediafilesOfMedia, [])
        
        let allArtists = ""
        if (fullMedia.media.artists) {
          fullMedia.media.artists.map(artist => {
            allArtists += artist.name + ', '
          })
          allArtists = allArtists.substring(0, allArtists.length - 2);
        }

        return {
          id: fullMedia.id,
          name: fullMedia.name,
          contentType: fullMedia.contentType,
          artists: allArtists,
          nbMediafiles: fullMediafilesOfMedia.length,
          audioTracks: fullMediafilesOfMedia.reduce(getAudioTracksFromMediafileContent, []),
          burnedSubs: fullMediafilesOfMedia.reduce(getBurnedSubsFromMediafileContent, []),
          dynamicSubs: fullMediafilesOfMedia.reduce(getDynamicSubsFromMediafileContent, [])
        }
      })
    })

    const mediasAreOnlyAudio = computed(() => {
      return mediasList.value.every(media => ['Album', 'Audio Simple', 'Podcast', 'Radio'].includes(media.contentType.name))
    })

    const getThreeFirstElements = (array) => {
      return (array || []).filter((val, idx) => idx < 3)
    }

    const onRowReorder = (e) => {
      const newTree = [...mediafilesTree.value]
      const valToMove = newTree[e.dragIndex]
      newTree.splice(e.dragIndex, 1)
      newTree.splice(e.dropIndex, 0, valToMove)
      mediafilesTree.value = newTree
    }

    const toggleGroupDelete = () => {
      isGroupDelete.value = !isGroupDelete.value
      mediasToRemove.value = []
    }

    const onMediaGroupRemove = () => {
      mediasToRemove.value.forEach(media => {
        onMediaRemove(media)
      })
      mediasToRemove.value = []
      closeConfirmGroupRemove()
    }

    const onMediaRemove = (data) => {
      removingMediaId.value = data.id;
      pxstream.tools.avoidRenderingStuck(() => {

        const mediafilesToRm = []
        const mediasToRm = [data.id]

        const mappedMedias = medias.value.reduce((acc, val, index) => {
          acc[val.id] = {
            ...val,
            index
          }
          return acc
        }, {})
        const mappedMediafiles = mediafiles.value.reduce((acc, val, index) => {
          acc[val.id] = {
            ...val,
            index
          }
          return acc
        }, {})
        const mappedMediafilesSettings = mediafilesSettings.value.reduce((acc, val, index) => {
          acc[val.mediafile.id] = {
            ...val,
            index
          }
          return acc
        }, {})

        const newMediafilesTree = mediafilesTree.value.filter(treeItem => {
          const isIt = treeItem.id === data.id

          const searchInChildren = (val) => {
            if (val.collection === KFluxMedia) {
              mediasToRm.push(val.id)
              val.children.forEach(searchInChildren)
            } else if (val.collection === KFluxMediafile) {
              mediafilesToRm.push(val.id)
            }
          }

          if (isIt) {
            treeItem.children.forEach(searchInChildren)
          }
          return !isIt
        })

        const deleteLinkElements = []

        mediafilesToRm.forEach((mfToRm) => {
          delete mappedMediafiles[mfToRm]

          const mfSettings = mappedMediafilesSettings[mfToRm]
          if (mfSettings) {
            delete mappedMediafilesSettings[mfToRm]
            deleteLinkElements.push({
              flow: KFluxMediafileSettings,
              id: mfSettings.id
            })
          }
        })

        mediasToRm.forEach((mToRm) => {
          delete mappedMedias[mToRm]
        })

        const newMedias = Object.values(mappedMedias).sort((a, b) => {
          return a.index-b.index
        }).map(val => {
          delete val.index
          return val
        })
        const newMediafiles = Object.values(mappedMediafiles).sort((a, b) => {
          return a.index-b.index
        }).map(val => {
          delete val.index
          return val
        })
        const newMediafilesSettings = Object.values(mappedMediafilesSettings).sort((a, b) => {
          return a.index-b.index
        }).map(val => {
          delete val.index
          return val
        })

        editor.value?.saveFlowDeleteLinkElements(deleteLinkElements)
        editor.value?.pauseUpdateChecking()
        medias.value = newMedias
        mediafiles.value = newMediafiles
        mediafilesSettings.value = newMediafilesSettings
        mediafilesTree.value = newMediafilesTree
        editor.value?.resumeUpdateChecking()

        removingMediaId.value = null
      })
    }


    return {
      mediasAreOnlyAudio,
      mediasList,
      removingMediaId,
      isGroupDelete,
      mediasToRemove,
      showConfirmGroupRemove,
      toggleGroupDelete,
      getThreeFirstElements,
      confirmGroupRemove,
      closeConfirmGroupRemove,
      onRowReorder,
      onMediaRemove,
      onMediaGroupRemove,
      isPreviousCycle
    }
  },
  props: {
    editorKey: String
  },
  components: {
    DataTable,
    Column,
    Checkbox,
    BtnConfirm,
    ViewFlagRow
  }
}
</script>

<style scoped>
.justify-content-space-around {
  justify-content: space-around;
}
</style>

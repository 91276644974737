<template>
  <div class="mb-4">
    <tab-menu :model="tabs" v-model:activeIndex="activeTab">
      <template #item="{item}">
        <Tab v-if="item.component.children.header" :index="item.index" @active="activeTab = $event">
          <component :is="item.component.children.header" v-bind="item.component.props" />
        </Tab>
        <component v-else :is="item.component" :index="item.index" @active="activeTab = $event"></component>
      </template>
    </tab-menu>
    <div class="py-4 px-3">
      <keep-alive>
        <component :is="contentComponent" v-bind="contentComponentProps" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Tab from './Tab.vue'
import TabMenu from 'primevue/tabmenu'
import { ref, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStorage } from '@vueuse/core'
// PortalManager Medias
import EditingRights from '@/pages/portalmanager/media/components/EditingRights.vue'
import EditingMetadata from '@/pages/portalmanager/media/components/EditingMetadata.vue'
import EditingHistory from '@/pages/portalmanager/media/components/EditingHistory.vue'
import EditingMediaUnknown from '@/pages/portalmanager/media/components/EditingMediaUnknown.vue'
import EditingMediaFiles from '@/pages/portalmanager/media/components/EditingMediafiles.vue'
import EditingTracks from '@/pages/portalmanager/media/components/collections/EditingTracks.vue'
import EditingEpisodes from '@/pages/portalmanager/media/components/collections/EditingEpisodes.vue'
import EditingMediaSeason from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaSeason.vue'
import EditingMediaAudioSimple from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaAudioSimple.vue'
import EditingMediaAlbum from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaAlbum.vue'
import EditingMediaPodcast from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaPodcast.vue'
import EditingMediaRadio from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaRadio.vue'
import EditingMediaVideo from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaVideo.vue'
import EditingMediaTrailer from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaTrailer.vue'
import EditingMediaTvSimple from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaTvSimple.vue'
import EditingMediaTvSeries from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaTvSeries.vue'
import EditingMediaBroadcastAndAdvert from '@/pages/portalmanager/media/components/mediaTypes/EditingMediaBroadcastAndAdvert.vue'
// Menu
import MenuStaticTranslations from '@/pages/portalmanager/menus/components/staticTranslations/Main.vue'
import MenuGeneralInformation from '@/pages/portalmanager/menus/components/generalInformation/Main.vue'
import MenuMediaSections from '@/pages/portalmanager/menus/components/mediaSections/Main.vue'
import MenuMedias from '@/pages/portalmanager/menus/components/medias/Main.vue'
import MenuHomeConfig from '@/pages/portalmanager/menus/components/homeConfig/Main.vue'
// Projects
import ProjectGeneral from '@/pages/projects/components/general/Main.vue'
import ProjectDetails from '@/pages/projects/components/details/Main.vue'
import ProjectIFEProducts from '@/pages/projects/components/ifeProducts/Main.vue'
// import ProjectExport from '@/pages/projects/components/export/Main.vue'

export const useTabMemory = (id) => {
  const route = useRoute()
  const tabState = useStorage('tab-editor-memory', {})
  const reset = () => {
    tabState.value.lastRoute = ''
  }
  if (tabState.value.lastRoute !== route.path) {
    tabState.value.lastRoute = route.path
    tabState.value.memory = {}
  }
  if (id) {
    const tabManagerState = computed({
      get: () => tabState.value.memory[id],
      set: (value) => {
        tabState.value.memory[id] = value
      }
    })
    return {
      tabManagerState,
      tabState,
      reset
    }
  } else {
    return {
      tabState,
      reset
    }
  }
}

export const useTabManager = (options) => {
  options = Object.assign({}, {activeIndex: 0, editorKey: '', editorMain: ''}, options)

  const _activeTab = ref(parseInt(options.activeIndex))

  const tabManagerId = `${options.editorMain}:${options.editorKey}`
  const { tabManagerState, tabMemoryAPI } = useTabMemory(tabManagerId)

  if (tabManagerState.value !== undefined) {
    _activeTab.value = tabManagerState.value
  }

  const activeTab = computed({
    get: () => _activeTab.value,
    set: (value) => {
      _activeTab.value = value
      tabManagerState.value = value
    }
  })

  return {
    activeTab,
    tabManagerState,
    ...tabMemoryAPI
  }
}

export default {
  name: 'TabEditor',
  components: {
    TabMenu,
    Tab,
    EditingRights,
    EditingMetadata,
    EditingHistory,
    EditingTracks,
    EditingEpisodes,
    EditingMediaUnknown,
    EditingMediaAudioSimple,
    EditingMediaRadio,
    EditingMediaSeason,
    EditingMediaVideo,
    EditingMediaTrailer,
    EditingMediaTvSimple,
    EditingMediaTvSeries,
    EditingMediaPodcast,
    EditingMediaBroadcastAndAdvert,
    EditingMediaAlbum,
    EditingMediaFiles,
    MenuStaticTranslations,
    MenuGeneralInformation,
    MenuMediaSections,
    MenuMedias,
    MenuHomeConfig,
    ProjectGeneral,
    ProjectDetails,
    ProjectIFEProducts,
    // ProjectExport,
  },
  props: {
    activeIndex: {
      type: [String, Number],
      default: 0
    },
    editorKey: {
      type: String,
      default: ''
    },
    editorMain: {
      type: String,
      default: ''
    }
  },
  emits: ['update:activeIndex'],
  setup (props, { emit, slots }) {
    
    const {
      activeTab,
    } = useTabManager(props)

    const isTabPanel = (child) => (child.type.name === 'TabEditor.Tab')

    const tabs = computed(() => {
      const tabs = []
      slots.default().forEach(child => {
        if (isTabPanel(child)) {
          tabs.push({index: tabs.length, component: child})
        } else if (child.children && child.children instanceof Array) {
          child.children.forEach(nestedChild => {
            if (isTabPanel(nestedChild)) {
              tabs.push({index: tabs.length, component: nestedChild})
            }
          });
        }
      })
      return tabs;
    })

    const contentComponent = computed(() => {
      if (!tabs.value[activeTab.value]) return null;
      if (tabs.value[activeTab.value].component.props?.content) {
        return tabs.value[activeTab.value].component.props.content
      }
      return tabs.value[activeTab.value].component.children.content
    })
    const contentComponentProps = computed(() => {
      const component = tabs.value[activeTab.value]?.component
      if (!component) return {}
      if (component.props?.content) {
        if (component.props?.contentProps) {
          if (Array.isArray(component.props.contentProps)) {
            return component.props?.contentProps.reduce((output, item) => {
              Object.keys(item).forEach((key) => {
                output[key] = item[key]
              })
              return output;
            }, {})
          }
          return component.props.contentProps
        }
      }
      return { editorKey: props.editorKey }
    })

    watchEffect(() => {
      emit('update:activeIndex', activeTab.value)
    })

    return {
      tabs,
      contentComponent,
      contentComponentProps,
      activeTab
    }
  },
  Tab
}
</script>

<style>

</style>


class PortalManager{
  constructor (http, tools) {
    this.http = http
    this.tools = tools
  }

  //#region medias

  // @deprecated ?
  async createMedia ({name, contentType, defaultLanguage}) {
    const {data} = await this.http.core.post(`/flow/portalmanager/media`, {
      name, contentType, defaultLanguage
    })

    return data
  }

  // Seasons API
  createOneSeason (data, patch) {
    data.patch = patch
    return this.http.core.post(`/flow/portalmanager/media-tv-season`, data)
  }

  saveOneSeason (id, data) {
    return this.http.core.put(`/flow/portalmanager/media-tv-season/${id}`, data)
  }

  deleteOneSeason (id) {
    return this.http.core.delete(`/flow/portalmanager/media-tv-season/${id}`)
  }

  async createEmptySeasonTv ({ defaultLanguage }) {
    const {data} = await this.http.core.post(`/flow/portalmanager/media-tv-season/empty`, {
      defaultLanguage
    })
    return data
  }

  // Common Media API
  async createEmptyMedia ({name, contentType, defaultLanguage}) {
    const {data} = await this.http.core.post(`/flow/portalmanager/media/empty`, {
      name, contentType, defaultLanguage
    })

    return data
  }

  async getMediaOne (id) {
    const {data} = await this.http.core.get(`/flow/portalmanager/media/${id}`)
    return data
  }

  async getMediaList (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/portalmanager/media?${qry}`)
    return data
  }

  async getMediaHistory (id) {
    if (id === 'new') {
      return null
    }
    const {data} = await this.http.core.get(`/flow/portalmanager/media/${id}/history`)
    data.list = data.list || []
    data.list.forEach(one => {
      try { one.at = new Date(one.at) } catch (e) { e }
    })
    return data
  }

  async postMediaWithMediafileInfoList (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.post(`/flow/portalmanager/media/mediafiles`, {qs: qry})
    return data
  }

  async createEmptyMediafile ({contentType}) {
    const { data } = await this.http.core.post(`/flow/portalmanager/mediafiles/empty`, {contentType})
    return data
  }

  //#endregion
  //#region menus

  // MENU /////////////////////////////////////////////////////////////////////
  async getMenuList (params) {
    const qry = this.tools.buildListingQuery(params)
    const { data } = await this.http.core.get(`/flow/portalmanager/menus?${qry}`)
    return data
  }

  async createMenu ({ name, cycleName, cycleStartDate, cycleEntryEndDate, cycleEndDate }) {
    const { data } = await this.http.core.post(`/flow/portalmanager/menus`,
      { name, cycleName, cycleStartDate, cycleEntryEndDate, cycleEndDate })
    return data
  }

  async isCycleAlreadyExported (cycleId) {
    const { data } = await this.http.core.get(`/services/exporter/exports/by-cycle/${cycleId}/count`)
    return data
  }

  async closeAndCreateCycle (menuId, { cycleName, cycleStartDate, cycleEntryEndDate, cycleEndDate }) {
    const { data } = await this.http.core.put(`/flow/portalmanager/menus/cycle/${menuId}`,
      { cycleName, cycleStartDate, cycleEntryEndDate, cycleEndDate })
    return data
  }

  async getMenuOne (id, light = false) {
    const endpoint = `/flow/portalmanager/menus/${id}` + (light ? '?light=true' : '')
    const { data } = await this.http.core.get(endpoint)
    return data
  }

  async getCyclesInOneMenu (id) {
    const endpoint = `/flow/portalmanager/menus/${id}/cycles`
    const { data } = await this.http.core.get(endpoint)
    return data
  }

  async hasPreviousCycleInOneMenu (id) {
    const endpoint = `/flow/portalmanager/menus/${id}/cycles/has-previous`
    const { data } = await this.http.core.get(endpoint)
    return data
  }

  async getPreviousCycleInOneMenu (id) {
    const endpoint = `/flow/portalmanager/menus/${id}/cycles/previous`
    const { data } = await this.http.core.get(endpoint)
    return data
  }

  // MENU ITEM ////////////////////////////////////////////////////////////////
  // async createMenuItem ({ name, type }) {
  //   const { data } = await this.http.core.post(`/flow/portalmanager/menu-items`, { name, type })
  //   return data
  // }

  async getTemplates () {
    const { data } = await this.http.core.get(`/flow/portalmanager/templates`)
    return data
  }

  async createEmptyMenuItem ({ name, type }) {
    const { data } = await this.http.core.post(`/flow/portalmanager/menu-items/empty`, { name, type })
    return data
  }

  async getMenuItemTypes (params) {
    const qry = this.tools.buildListingQuery(params)
    const { data } = await this.http.core.get(`/flow/portalmanager/menu-item-types?${qry}`)
    return data
  }

  // MEDIAFILE SETTINGS ///////////////////////////////////////////////////////

  async createEmptyMenuMediafileSettings ({ media, mediafile }) {
    const { data } = await this.http.core.post(`/flow/portalmanager/menu-mediafile-settings/empty`, { media, mediafile })
    return data
  }

  //#endregion
}

export default PortalManager

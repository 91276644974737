<template>
  <tr v-if="flags.length >= 1" role="row">
    <td v-if="label" class="column-title" role="cell">
      <span>{{label}}:</span>
    </td>
    <td class="column-data flex" role="cell">
      <LanguageFlagDisplay class="mr-2 border-1 relative bg-cover" v-for="flag in displayedFlags" :key="flag" :language="languageById(flag.id)" :width="flagWidth" :height="flagHeight" tooltip>
        <Badge v-if="useCC && flag.cc" value="CC" severity="danger" class="border-1 border-white p-0 p-mr-2 absolute right" />
      </LanguageFlagDisplay>
      <Badge v-if="max && (hoveredFlags.length >= 1)" :value="'+'+(flags.length-max)" severity="danger" class="p-mr-2 align-self-center" @mouseover="toggleOverlay" @mouseleave="toggleOverlay"/>
      <OverlayPanel ref="flagOverlay">
        <div class="flex">
          <LanguageFlagDisplay class="mr-2 border-1 relative bg-cover" v-for="flag in hoveredFlags" :key="flag" :language="languageById(flag.id)" :width="flagWidth" :height="flagHeight">
            <Badge v-if="useCC && flag.cc" value="CC" severity="danger" class="border-1 border-white p-0 p-mr-2 absolute right" />
          </LanguageFlagDisplay>
        </div>
      </OverlayPanel>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
import LanguageFlagDisplay from '@/components/resource/LanguageFlagDisplay.vue'
import Badge from 'primevue/badge'
import OverlayPanel from 'primevue/overlaypanel'

export default {
  components: {
    LanguageFlagDisplay,
    Badge,
    OverlayPanel
  },
  data () {
    return {
      hovered: false
    }
  },
  props: {
    flags: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: null
    },
    ccField: {
      type: String,
      default: 'cc'
    },
    max: {
      type: Number,
      default: null
    },
    useCC: {
      type: Boolean,
      default: false
    },
    flagWidth: {
      type: String,
      default: null
    },
    flagHeight: {
      type: String,
      default: null
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('resource', ['languageById']),
    flagList () {
      const fields = this.field?.split('.') || []
      let flagList = []
      try {
        for (let y = 0; y < this.flags.length; y++) {
          let currentFlag = this.flags[y]
          let customFlag = {}
          customFlag.id = currentFlag
          if (fields) {
            for (let i = 0; i < fields.length; i++) {
              currentFlag = currentFlag[fields[i]]
              customFlag.id = currentFlag
            }
          }

          if (this.useCC && this.flags[y][this.ccField]) {
            customFlag.cc = this.flags[y][this.ccField]
          }
          flagList.push(customFlag)
        }
      } catch (e) {
        return null
      }

      return flagList
    },
    displayedFlags () {
      const allFlags = this.flagList
      if (allFlags && this.max) {
        return allFlags.slice(0, this.max)
      }
      return allFlags
    },
    hoveredFlags () {
      const allFlags = this.flagList
      if (allFlags && this.max) {
        return allFlags.slice(this.max)
      }
      return []
    }
  },
  methods: {
    toggleOverlay (event) {
      this.$refs.flagOverlay.toggle(event);
    }
  }
}

</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>

<template>
  <div class="fluid my-2">
    <div class="mb-4">
      <label class="font-bold">Languages</label>
    </div>
    <draggable class="list-group" tag="transition-group" v-model="languages" item-key="id" handle=".drag-handler" :disabled="isPreviousCycle"
      v-bind="dragOptions" @start="drag = true" @end="drag = false"
      :component-data="{
          tag: 'div',
          type: 'transition-group',
          name: !drag ? 'flip-list' : null
        }"
      >
      <template #item="{element: lang}">
        <div class="fluid grid">
          <DragHandler class="col-1" />
          <div class="col-5 flex justify-content-start align-items-center">
            <Flag noTooltip :id="lang.id" />
            <label class="ml-3">{{lang.name}}</label>
          </div>
          <div class="col-4 flex justify-content-start align-items-center">
            <label class="mr-3">Default</label>
            <InputSwitch @update:modelValue="onDefaultLanguageChange($event, lang)" :modelValue="isDefaultLanguage(lang.id)" :disabled="isPreviousCycle" />
          </div>
          <div class="col-2 flex justify-content-start">
              <Button
                v-if="!isDefaultLanguage(lang.id)"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-outlined"
                :loading="false"
                @click="confirmRemove(lang)"
                :disabled="isPreviousCycle"
              />
          </div>
        </div>
      </template>
    </draggable>
    <div v-if="!isPreviousCycle" class="flex justify-content-center mt-3">
      <Button :label="$t('actions.add')" @click="showAddLangDialog = true" icon="pi pi-plus" type="button" class="p-button-outlined uppercase" />
    </div>
    <AddLangDialog :languages="languages" @languageAdded="addLang" v-model="showAddLangDialog" />
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import InputSwitch from "primevue/inputswitch"
import draggable from 'vuedraggable'
import DragHandler from '@/components/base/DragHandler.vue'
import Flag from '@/components/resource/Flag.vue'
import { useFlowEditor } from '@/compositions'
import AddLangDialog from './DialogAddLang.vue'

const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost"
}

export default {
  components: { InputSwitch, Flag, DragHandler, draggable, AddLangDialog },
  props: { editorKey: String },
  setup (props) {
    const isPreviousCycle = inject('isPreviousCycle')

    return {
      dragOptions,
      showAddLangDialog: ref(false),
      drag: ref(false),
      ...useFlowEditor(props.editorKey),
      isPreviousCycle
    }
  },
  computed: {
    languages: {
      get () { return (this.fieldGet('cycle.languages') || []) },
      set (value) { this.fieldSet({field: 'cycle.languages', value})}
    },
    defaultLanguage: {
      get () { return this.fieldGet('cycle.defaultLanguage') },
      set (value) { this.fieldSet({field: 'cycle.defaultLanguage', value})}
    },
  },
  methods: {
    addLang (lang) {
      this.fieldPush({ field: 'cycle.languages', value: lang })
    },
    onDefaultLanguageChange (isDefault, lang) {
      if (isDefault) {
        this.defaultLanguage = lang
      }
    },
    isDefaultLanguage (id) {
      return this.defaultLanguage.id === id
    },
    confirmRemove (lang) {
      this.$confirm.require({
        message: `Are you sure you want to delete this language?`,
        header: `Delete ${lang.name}?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.fieldSplice({ field: 'cycle.languages', filter: one => one.id !== lang.id })
        },
        reject: () => {}
      });
    }
  }
}
</script>

<style>

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: var(--primary-text-200);
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

</style>
<template>
  <div>
    <div class="flex"  style="cursor:pointer">
      <input-text v-if="disabled" :class="{'w-full': true, 'p-invalid': invalid}" v-model="display" :disabled="true"/>
      <div v-else :class="{'flex': true, 'p-invalid': invalid}">
        <InputNumber 
          v-model="hour"
          ref="hour"
          :class="{'p-invalid': invalid}"
          inputStyle="width:40px; text-align:center"
          :min="0" 
          :max="99"
          :prefix="prefixHour"
          @focus="isEditingHour = true"
          @focusout="isEditingHour = false"
          @keyup.enter="nextInput(2)"
        />
        <p class="mx-1 my-0 line-height-4">:</p>
        <InputNumber 
          v-model="minute"
          ref="minute"
          :class="{'p-invalid': invalid}"
          mode="decimal"
          inputStyle="width:40px; text-align:center"
          :min="0" 
          :max="59"
          :prefix="prefixMinute"
          @focus="isEditingMinute = true"
          @focusout="isEditingMinute = false"
          @keyup.enter="nextInput(3)"
        />
        <p class="mx-1 my-0 line-height-4">:</p>
        <InputNumber 
          v-model="second"
          ref="second"
          :class="{'p-invalid': invalid}"
          mode="decimal"
          inputStyle="width:40px; text-align:center"
          :min="0" 
          :max="59"
          :prefix="prefixSecond"
          @focus="isEditingSecond = true"
          @focusout="isEditingSecond = false"
        />
      </div>
    </div>
    <small v-if="invalid" class="p-error">{{invalidMsg || invalid}}</small>

  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'

export default {
  components: { InputNumber, InputText },
  props: {
    modelValue: Number,
    disabled: Boolean,
    invalid: [String, Boolean],
    invalidMsg: String
  },
  emits: ['update:modelValue'],
  data: () => ({
    isEditingHour: false,
    isEditingMinute: false,
    isEditingSecond: false,
  }),
  computed: {
    display () {
      return `${('0'+this.hour).slice(-2)} : ${('0'+this.minute).slice(-2)} : ${('0'+this.second).slice(-2)}`
    },
    prefixHour () {
      return this.hour >= 10 || this.isEditingHour ? '' : '0'
    },
    prefixMinute () {
      return this.minute >= 10 || this.isEditingMinute ? '' : '0'
    },
    prefixSecond () {
      return this.second >= 10 || this.isEditingSecond ? '' : '0'
    },
    hour: {
      get () { return Math.floor(this.modelValue / 3600) },
      set (hour) {
        this.buildRuntime({hour})
      }
    },
    minute: {
      get () { return Math.floor(Math.floor(this.modelValue % 3600) / 60) },
      set (minute) {
        this.buildRuntime({minute})
      }
    },
    second: {
      get () { return Math.floor(this.modelValue % 3600) % 60},
      set (second) {
        this.buildRuntime({second})
      }
    }
  },
  methods: {
    buildRuntime (args) {
      const hour = typeof args.hour !== 'undefined' ? args.hour : this.hour
      const minute = typeof args.minute !== 'undefined' ? args.minute : this.minute
      const second = typeof args.second !== 'undefined' ? args.second : this.second
      this.$emit('update:modelValue', hour * 3600 + minute * 60 + second)
    },
    nextInput(number) {
      switch (number) {
        case 2:
          this.$refs.minute.$el.children[0].focus();
          break;
        case 3:
          this.$refs.second.$el.children[0].focus();
          break;
        default:
          break;
      }
    }
  }
}
</script>

<template>
  <div>
    <Dialog
    v-model:visible="showIt"
    style="width:400px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">New Artist</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="create" >
        <div class="fluid">
          <div class="field">
            <label for="defaultLanguage" :class="{'p-error':defaultLanguageError}">{{ $t('forms.defaultLanguage') }}</label>
            <Dropdown
              v-model="defaultLanguage"
              :options="languages"
              :class="{'p-invalid': defaultLanguageError}"
              :disabled="isLoading"
              optionLabel="name"
              placeholder="Select default language"
            />
            <small v-if="defaultLanguageError" class="p-error">{{defaultLanguageError}}</small>
          </div>

          <div class="field">
            <label for="lastname" :class="{'p-error':lastnameError}">{{ $t('forms.lastname') }}</label>
            <InputText id="lastname" type="text"
              v-model="name"
              :class="{'p-invalid': lastnameError}"
              :disabled="isLoading"
            />
            <small v-if="lastnameError" class="p-error">{{lastnameError}}</small>
          </div>

          <div class="field">
            <label for="firstname">{{ $t('forms.firstname') }}</label>
            <InputText id="firstname" type="text"
              v-model="firstname"
              :disabled="isLoading"
            />
          </div>

        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text px-button-cancel" @click="showIt = false"/>
          <Button :label="$t('actions.submit')" icon="pi pi-check" class="px-button-success" @click="create"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import InputText from "primevue/inputtext"
import Dropdown from "primevue/dropdown"
import Message from "primevue/message"

import { mapGetters } from 'vuex'
import { ref } from 'vue'

import { required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"


export default {
  setup () { 
    const name = ref('')
    const defaultLanguage = ref('')

    const rules = {
      name: {
        required,
      },
      defaultLanguage: {
        required,
      }
    }

    const v$ = useVuelidate(rules, { name, defaultLanguage })

    return {
      name,
      defaultLanguage,
      v$
    }
  
  },
  props: {
    modelValue: {
      type: Boolean
    },
    noRouter: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InputText, Message, Dropdown,
  },

  data: () => ({
    firstname: '',

    showIt: false,
    isLoading: false,
    errorCaught: ''
  }),
  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.firstname = ''
      this.name = ''
      this.defaultLanguage = this.defaultInitLanguage
    },

    create () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.resource.createArtist({
        firstname: this.firstname,
        name: this.name,
        defaultLanguage: this.defaultLanguage.id
      })
      .then(res => {
        !this.noRouter ? this.$router.push(`/resources/artists/${res.id}`) : this.$emit('newArtist', res.id)
      })
      .catch(({message}) => {
        this.errorCaught = message
      })
      .finally(() => {
        this.isLoading = false
      })
    }
  },

  computed: {
    ...mapGetters('resource', ['languages', 'languageById', 'defaultInitLanguage']),
    lastnameError () {
      if (!this.v$.name.$dirty) return ''
      if (this.v$.name.required.$invalid) return 'Lastname is required.'
      return ''
    },
    defaultLanguageError () {
      if (!this.v$.defaultLanguage.$dirty) return ''
      if (this.v$.defaultLanguage.required.$invalid) return 'Default language is required.'
      return ''
    }
  },

  validations: {
    name: { required },
    defaultLanguage: { required }
  },

  watch: {
    showIt (show) {
      if (show) {
        this.defaultLanguage = this.defaultInitLanguage
      }
    },
    modelValue: function (val) {
      this.showIt = val
    }
  }
}
</script>
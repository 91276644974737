<template>
  <div class="field grid">
    <label class="col-12">Logo:</label>
    <div class="col relative">
      <Skeleton class="h-5rem" animation="none" />
      <div class="absolute top-0 w-12 h-full flex justify-content-center align-items-center">
        <Button
            icon="pi pi-plus"
            v-tooltip="'Not available'"
            class="p-button-rounded p-button-primary p-button-outlined"
          />
      </div>
    </div>
  </div>
  <div class="field grid">
    <label class="col-12">Logo 2:</label>
    <div class="col relative">
      <Skeleton class="h-5rem" animation="none" />
      <div class="absolute top-0 w-12 h-full flex justify-content-center align-items-center">
        <Button
          icon="pi pi-plus"
          v-tooltip="'Not available'"
          class="p-button-rounded p-button-primary p-button-outlined"
        />
      </div>
    </div>
  </div>
  <div class="field grid">
    <label class="col-12">Background:</label>
    <div class="col relative">
      <Skeleton class="h-8rem" animation="none" />
      <div class="absolute top-0 w-12 h-full flex justify-content-center align-items-center">
        <Button
            icon="pi pi-plus"
            v-tooltip="'Not available'"
            class="p-button-rounded p-button-primary p-button-outlined"
          />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton"

export default {
  props: { editorKey: String },
  components: {
    Skeleton
  }
}
</script>


class Project {
  constructor(http, tools) {
    this.http = http
    this.tools = tools
  }

  //#region projects

  // PROJECTS ////////////////////////////////////////////////////////////////
  async createProject (project) {
    const { data } = await this.http.core.post(`/flow/projects`,
      project)
    return data
  }

  async getProjectList (params) {
    const qry = this.tools.buildListingQuery(params)
    const { data } = await this.http.core.get(`/flow/projects?${qry}`)
    return data
  }

  async getProjectOne (id) {
    const { data } = await this.http.core.get(`/flow/projects/${id}`)
    return data
  }

  //#endregion
}

export default Project

<template>
  <input type="color" :value="modelValue" @input="setColor($event)" />
</template>

<script>
import { debounce } from 'vue-debounce'


export default {
  name: 'ColorPicker',
  emits: ['update:modelValue'],
  props: ['modelValue'],
  setup (props, { emit }) {
    const debounceColor = debounce(val => emit("update:modelValue", val), '400ms')
    return {
      debounceColor
    }
  },
  methods: {
    setColor ($event) {
      this.debounceColor($event.target.value)
    }
  }
}
</script>

<style scoped>
input[type="color"] {
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    width: 28px;
    height: 28px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
    border-radius: .25rem;
    overflow: hidden;
}

input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}
</style>
<template>
  <div v-if="modelValue">
    {{ fieldGet('mediaType') }}
    <Toolbar>
      <template #left>
        <h1 class="font-light"><i class="pi pi-clone pr-3 text-2xl" v-if="isOvercharged" />{{ name || 'New mediafile' }}</h1>
      </template>

      <template #right>
        <span class="p-buttonset">
          <Button :label="$t('actions.cancel')" class="p-button-outlined" icon="pi pi-times" @click="close"/>
          <Button label="Update" icon="pi pi-check" iconPos="right" @click="update" :disabled="!hasUpdate" />
        </span>
      </template>
    </Toolbar>

    <br/>

    <div class="grid">
      <div class="col-12 md:col-8">

        <div class="field grid">
          <label for="name" class="col-12 md:col-3 font-bold">{{ $t('forms.title') }}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="name" type="text" v-model="name" />
            </span>
          </div>
        </div>

      </div>
    </div>

    <component v-if="false" :is="mediafileComponent" :editorKey="editorKey"/>

    <div v-if="isMovieType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="codec" class="col-12 md:col-4">Codec</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsVideo" optionValue="id" optionLabel="name" placeholder="Select a codec" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Encrypted</label>
            <div class="col md:col-8">
              <InputSwitch v-model="isEncrypted " id="isEncrypted" />
            </div>
          </div>

          <div class="field grid">
            <label for="systemsCompliance" class="col-12 md:col-4">Systems Compliance</label>
            <div class="col md:col-8">
              <SystemComplianceEditor :editorKey="editorKey" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">Embedded filename</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="videoBitrate" class="col-12 md:col-4">Video Bitrate</label>
            <div class="col md:col-8">
              <Dropdown v-model="videoBitrate" :options="rscVideoBitrates" optionValue="id" optionLabel="name" placeholder="Select video bitrate" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

          <div class="field grid">
            <label for="resolution" class="col-12 md:col-4">Resolution</label>
            <div class="col md:col-8">
              <Dropdown v-model="resolution" :options="rscResolutions" optionValue="id" optionLabel="name" placeholder="Select resolution" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="ratio" class="col-12 md:col-4">Ratio</label>
            <div class="col md:col-8">
              <Dropdown v-model="ratio" :options="rscRatios" optionValue="id" optionLabel="name" placeholder="Select ratio" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="boxType" class="col-12 md:col-4">Box Type</label>
            <div class="col md:col-8">
              <Dropdown v-model="boxType" :options="rscBoxTypes" optionValue="id" optionLabel="name" placeholder="Select box type" class="w-full" />
            </div>
          </div>

        </div>
      </div>

      <div>
        <DataTable :value="audioTracks" @row-reorder="onRowReorder($event, 'audioTracks')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Audio Track {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeAudioTrack(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add audio track" class="p-button-outlined" icon="pi pi-plus-circle" @click="addAudioTrack"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="burnedSubs" @row-reorder="onRowReorder($event, 'burnedSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Burned In Sub {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeBurnedSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div v-if="burnedSubsLength < 2" class="flex justify-content-center">
          <Button label="Add burned in sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addBurnedSub"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="dynamicSubs" @row-reorder="onRowReorder($event, 'dynamicSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Dynamic Sub {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <div class="grid">
                <label for="isEncrypted" class="col-12 md:col-4">CC</label>
                <div class="col md:col-8">
                  <InputSwitch v-model="data.CC" @change="checkHasUpdate()" />
                </div>
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeDynamicSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add dynamic sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addDynamicSub"/>
        </div>
      </div>
    </div>

    <div v-if="isTVType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="codec" class="col-12 md:col-4">Codec</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsVideo" optionValue="id" optionLabel="name" placeholder="Select a codec" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Encrypted</label>
            <div class="col md:col-8">
              <InputSwitch v-model="isEncrypted " id="isEncrypted" />
            </div>
          </div>

          <div class="field grid">
            <label for="systemsCompliance" class="col-12 md:col-4">Systems Compliance</label>
            <div class="col md:col-8">
              <SystemComplianceEditor :editorKey="editorKey" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">Embedded filename</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="videoBitrate" class="col-12 md:col-4">Video Bitrate</label>
            <div class="col md:col-8">
              <Dropdown v-model="videoBitrate" :options="rscVideoBitrates" optionValue="id" optionLabel="name" placeholder="Select video bitrate" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

          <div class="field grid">
            <label for="resolution" class="col-12 md:col-4">Resolution</label>
            <div class="col md:col-8">
              <Dropdown v-model="resolution" :options="rscResolutions" optionValue="id" optionLabel="name" placeholder="Select resolution" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="ratio" class="col-12 md:col-4">Ratio</label>
            <div class="col md:col-8">
              <Dropdown v-model="ratio" :options="rscRatios" optionValue="id" optionLabel="name" placeholder="Select ratio" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="boxType" class="col-12 md:col-4">Box Type</label>
            <div class="col md:col-8">
              <Dropdown v-model="boxType" :options="rscBoxTypes" optionValue="id" optionLabel="name" placeholder="Select box type" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="videoTrickMode" class="col-12 md:col-4">Video Trick Mode</label>
            <div class="col md:col-8">
              <Dropdown v-model="videoTrickMode" :options="rscVideoTrickModes" optionValue="id" optionLabel="name" placeholder="Select video trick mode" class="w-full" />
            </div>
          </div>

        </div>
      </div>

      <div>
        <DataTable :value="audioTracks" @row-reorder="onRowReorder($event, 'audioTracks')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Audio Track {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeAudioTrack(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add audio track" class="p-button-outlined" icon="pi pi-plus-circle" @click="addAudioTrack"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="burnedSubs" @row-reorder="onRowReorder($event, 'burnedSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Burned In Sub {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeBurnedSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div v-if="burnedSubsLength < 2" class="flex justify-content-center">
          <Button label="Add burned in sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addBurnedSub"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="dynamicSubs" @row-reorder="onRowReorder($event, 'dynamicSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Dynamic Sub {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <div class="grid">
                <label for="isEncrypted" class="col-12 md:col-4">CC</label>
                <div class="col md:col-8">
                  <InputSwitch v-model="data.CC" @change="checkHasUpdate()" />
                </div>
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeDynamicSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add dynamic sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addDynamicSub"/>
        </div>
      </div>
    </div>

    <div v-if="isAudioType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Codec</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsAudio" optionValue="id" optionLabel="name" placeholder="Select a codec" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="encoding" class="col-12 md:col-4">Encoding</label>
            <div class="col md:col-8">
              <Dropdown v-model="encoding" :options="rscEncodings" optionValue="id" optionLabel="name" placeholder="Select encoding" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="systemsCompliance" class="col-12 md:col-4">Systems Compliance</label>
            <div class="col md:col-8">
              <SystemComplianceEditor :editorKey="editorKey" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">Embedded filename</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="bitrate" class="col-12 md:col-4">Bitrate</label>
            <div class="col md:col-8">
              <Dropdown v-model="bitrate" :options="rscBitrates" optionValue="id" optionLabel="name" placeholder="Select bitrate" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="lab" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

        </div>
      </div>

      <hr/>

      <div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="field grid">
              <label for="language" class="col-12 md:col-4">Audio Track</label>
              <LanguageSelector
                class="col md:col-8"
                v-model="language"
                :add-option="false"
                :getOnlyID="false"
                :placeholder="'Available for all languages'"
                :sort="languageSort"
                showClear/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isPramsBGMType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Codec</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsAudio" optionValue="id" optionLabel="name" placeholder="Select a codec" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">Embedded filename</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="bitrate" class="col-12 md:col-4">Bitrate</label>
            <div class="col md:col-8">
              <Dropdown v-model="bitrate" :options="rscBitrates" optionValue="id" optionLabel="name" placeholder="Select bitrate" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="lab" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

        </div>
      </div>

      <hr/>

      <div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="field grid">
              <label for="language" class="col-12 md:col-4">Audio Track</label>
              <LanguageSelector
                class="col md:col-8"
                v-model="language"
                :add-option="false"
                :getOnlyID="false"
                :placeholder="'Available for all languages'"
                :sort="languageSort"
                showClear/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isSafetyVPAAdvertType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="codec" class="col-12 md:col-4">Codec</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsVideo" optionValue="id" optionLabel="name" placeholder="Select a codec" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">Embedded filename</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="videoBitrate" class="col-12 md:col-4">Video Bitrate</label>
            <div class="col md:col-8">
              <Dropdown v-model="videoBitrate" :options="rscVideoBitrates" optionValue="id" optionLabel="name" placeholder="Select video bitrate" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

          <div class="field grid">
            <label for="ratio" class="col-12 md:col-4">Ratio</label>
            <div class="col md:col-8">
              <Dropdown v-model="ratio" :options="rscRatios" optionValue="id" optionLabel="name" placeholder="Select ratio" class="w-full" />
            </div>
          </div>

        </div>
      </div>

      <div>
        <DataTable :value="audioTracks" @row-reorder="onRowReorder($event, 'audioTracks')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Audio Track {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeAudioTrack(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add audio track" class="p-button-outlined" icon="pi pi-plus-circle" @click="addAudioTrack"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="burnedSubs" @row-reorder="onRowReorder($event, 'burnedSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Burned In Sub {{ index + 1}}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeBurnedSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div v-if="burnedSubsLength < 2" class="flex justify-content-center">
          <Button label="Add burned in sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addBurnedSub"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="dynamicSubs" @row-reorder="onRowReorder($event, 'dynamicSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              Dynamic Sub {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <div class="grid">
                <label for="isEncrypted" class="col-12 md:col-4">CC</label>
                <div class="col md:col-8">
                  <InputSwitch v-model="data.CC" @change="checkHasUpdate()" />
                </div>
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeDynamicSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add dynamic sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addDynamicSub"/>
        </div>
      </div>
    </div>

    <div v-if="isGameType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="systemsCompliance" class="col-12 md:col-4">Systems Compliance</label>
            <div class="col md:col-8">
              <SystemComplianceEditor :editorKey="editorKey" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">Embedded filename</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="lab" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Toolbar from 'primevue/toolbar'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'
import AutoComplete from 'primevue/autocomplete'
import MultiSelect from 'primevue/multiselect'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

import { Icon } from '@iconify/vue/dist/offline'

import BtnConfirm from '@/components/BtnConfirm.vue'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'

import MediaFileTypeUnknown from './mediafiles/MediaFileTypeUnknown.vue'
import MediaFileTypeMovie from './mediafiles/MediaFileTypeMovie.vue'

import SystemComplianceEditor from './mediafiles/fieldeditors/SystemComplianceEditor.vue'

import { useFlowPMMediafileBuilder } from '@/compositions'

export default {
  name: 'EditingMediafilesEditor',
  components: {
    Toolbar, InputText, InputSwitch, Dropdown, AutoComplete, MultiSelect, DataTable, Column, Icon,
    LanguageSelector,
    BtnConfirm,
    MediaFileTypeUnknown,
    MediaFileTypeMovie,
    SystemComplianceEditor
  },
  props: {
    modelValue: Object,
    mediafileInitFrom: String,
    isInitiating: Boolean,
    initList: {
      type: Array,
      default: () => [
        { field: 'content.codec' },
        { field: 'content.bitrate' },
        { field: 'content.videoBitrate' },
        { field: 'content.encoding' },
        { field: 'content.encrypted' },
        { field: 'content.resolution' },
        { field: 'content.ratio' },
        { field: 'content.boxType' },
        { field: 'content.videoTrickMode' },
        { field: 'lab' }
      ]
    }
  },
  data: () => ({
    rscCodecsAudio: RscCodecsAudio,
    rscCodecsVideo: RscCodecsVideo,
    rscBitrates: RscBitrates,
    rscVideoBitrates: RscVideoBitrates,
    rscEncodings: RscEncodings,
    rscResolutions: RscResolutions,
    rscRatios: RscRatios,
    rscBoxTypes: RscBoxTypes,
    rscVideoTrickModes: RscVideoTrickModes,
    rscLabs: []
  }),
  setup (props) {
    return {
      ...useFlowPMMediafileBuilder({
        propDoc: props.modelValue,
        onFieldChange: () => {},
        onUpdateChange: () => {},
        initFields: props.mediafileInitFrom && props.isInitiating ? {
          from: props.mediafileInitFrom,
          list: props.initList
        } : null
      })
    }
  },
  computed: {
    ...mapGetters('resource', ['languages', 'languageById']),
    name: {
      get () { return this.fieldGet('filename') },
      set (val) { this.fieldSet({field: 'filename', value: val}) }
    },
    isOvercharged: {
      get () { return this.fieldGet('isOvercharged') },
      set (val) { this.fieldSet({field: 'isOvercharged', value: val}) }
    },
    codec: {
      get () { return this.fieldGet('content.codec') },
      set (val) { this.fieldSet({field: 'content.codec', value: val}) }
    },
    bitrate: {
      get () { return this.fieldGet('content.bitrate') },
      set (val) { this.fieldSet({field: 'content.bitrate', value: val}) }
    },
    videoBitrate: {
      get () { return this.fieldGet('content.videoBitrate') },
      set (val) { this.fieldSet({field: 'content.videoBitrate', value: val}) }
    },
    encoding: {
      get () { return this.fieldGet('content.encoding') },
      set (val) { this.fieldSet({field: 'content.encoding', value: val}) }
    },
    isEncrypted: {
      get () { return this.fieldGet('content.encrypted') },
      set (val) { this.fieldSet({field: 'content.encrypted', value: val}) }
    },
    resolution: {
      get () { return this.fieldGet('content.resolution') },
      set (val) { this.fieldSet({field: 'content.resolution', value: val}) }
    },
    ratio: {
      get () { return this.fieldGet('content.ratio') },
      set (val) { this.fieldSet({field: 'content.ratio', value: val}) }
    },
    boxType: {
      get () { return this.fieldGet('content.boxType') },
      set (val) { this.fieldSet({field: 'content.boxType', value: val}) }
    },
    videoTrickMode: {
      get () { return this.fieldGet('content.videoTrickMode') },
      set (val) { this.fieldSet({field: 'content.videoTrickMode', value: val}) }
    },
    lab: {
      get () { return this.fieldGet('lab') },
      set (val) { this.fieldSet({field: 'lab', value: val}) }
    },
    language: {
      get () {
        const field = this.fieldGet('content.language')
        return field && field.id && field.id !== "0" ? field : null
      },
      set (val) { this.fieldSet({field: 'content.language', value: val}) }
    },
    embeddedFilename: {
      get () { return this.fieldGet('embeddedFilename') },
      set (val) { this.fieldSet({field: 'embeddedFilename', value: val}) }
    },
    audioTracks: {
      get () { return this.fieldGet('content.audioTracks') },
      set (val) { this.fieldSet({field: 'content.audioTracks', value: val}) }
    },
    burnedSubs: {
      get () { return this.fieldGet('content.burnedSubs') },
      set (val) { this.fieldSet({field: 'content.burnedSubs', value: val}) }
    },
    dynamicSubs: {
      get () { return this.fieldGet('content.dynamicSubs') },
      set (val) { this.fieldSet({field: 'content.dynamicSubs', value: val}) }
    },
    mediafileComponent () {
      switch (this.fieldGet('mediafileType')) {
        case 'movie': return 'MediaFileTypeMovie'
      }

      return 'MediaFileTypeUnknown'
    },
    isAudioType () {
      return this.fieldGet('mediafileType') === 'audio'
    },
    isMovieType () {
      return this.fieldGet('mediafileType') === 'movie'
    },
    isTVType () {
      return this.fieldGet('mediafileType') === 'tv'
    },
    isPramsBGMType () {
      return this.fieldGet('mediafileType') === 'prams-bgm'
    },
    isSafetyVPAAdvertType () {
      return this.fieldGet('mediafileType') === 'safety-vpa-advert'
    },
    isGameType () {
      return this.fieldGet('mediafileType') === 'game'
    },
    burnedSubsLength () {
      return this.burnedSubs ? this.burnedSubs.length : 0
    }
  },
  methods: {
    update () {
      this.$emit('child-submit', this.saveFlowBuild())
    },
    close () {
      this.$emit('close', false)
    },
    addAudioTrack () {
      this.fieldPush({field: 'content.audioTracks', value: { language: null }})
    },
    removeAudioTrack (index) {
      this.fieldSplice({ field: 'content.audioTracks', filter: (_, idx) => idx != index})
    },
    addBurnedSub () {
      this.fieldPush({field: 'content.burnedSubs', value: { language: null }})
    },
    removeBurnedSub (index) {
      this.fieldSplice({ field: 'content.burnedSubs', filter: (_, idx) => idx != index})
    },
    addDynamicSub () {
      this.fieldPush({field: 'content.dynamicSubs', value: { language: null, CC: false }})
    },
    removeDynamicSub (index) {
      this.fieldSplice({ field: 'content.dynamicSubs', filter: (_, idx) => idx != index})
    },
    onRowReorder (event, item) {
      this.fieldSet({field: `content.${item}`, value: event.value})
    },
    async searchLabs ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data} = await this.$pxstream.resource.getLabs(qryBuilder.build())
        this.rscLabs = data
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get labs', detail: err.toString(), life: 4000})
      }
    },
    languageSort (a, b) {
      if (!a.order && b.order) {
        return 1
      } else if (a.order && !b.order) {
        return -1
      } else if (a.order && b.order) {
        return a.order-b.order
      }
      return 0
    }
  }
}

// TODO from DB
const RscCodecsAudio = [
  {id: "MP3", name: "MP3", type: "audio"},
  {id: "MP2", name: "MP2", type: "audio"},
  {id: "ABS", name: "ABS", type: "audio"},
  {id: "WAV", name: "WAV", type: "audio"},
  {id: "AAC", name: "AAC", type: "audio"},
]

// TODO from DB
const RscCodecsVideo = [
  {id: "MPEG4", name: "MPEG4", type: "video"},
  {id: "MP4", name: "MP4", type: "video"},
  {id: "MPG", name: "MPG", type: "video"}
]

// TODO from DB
const RscBitrates = [
  {id: "K128", name: "128 Kbps"},
  {id: "K192", name: "192 Kbps"},
  {id: "K256", name: "256 Kbps"}
]

// TODO from DB
const RscVideoBitrates = [
  {id: "M1_5", name: "1.5 Mbps"}
]

// TODO from DB
const RscEncodings = [
  {id: "LeftMono", name: "Left-Mono"},
  {id: "RightMono", name: "Right-Mono"},
  {id: "Stereo", name: "Stereo"},
  {id: "Unused", name: "Unused"}
]

// TODO from DB
const RscResolutions = [
  {id: '_480p', name: '480p'},
  {id: '_720p', name: '720p'},
  {id: '_1080p', name: '1080p'},
  {id: '_4K', name: '4K'}
]

// TODO from DB
const RscRatios = [
  {id: '_16_9', name: '16x9'},
  {id: '_4_3', name: '4x3'}
]

// TODO from DB
const RscBoxTypes = [
  {id: 'boxed', name: 'Boxed'},
  {id: 'single', name: 'Single'}
]

// TODO from DB
const RscVideoTrickModes = [
  {id: 'OFF_ALL', name: 'Video Panel Off – All keys functional'},
  {id: 'OFF_STOP', name: 'Video Panel Off – Stop Key disabled'},
  {id: 'OFF_FFREW', name: 'Video Panel Off – FF.REW Keys disabled'},
  {id: 'ON_ALL', name: 'Video Panel On – All keys functional'},
  {id: 'ON_STOP', name: 'Video Panel On – Stop Key disabled'},
  {id: 'ON_FFREW', name: 'Video Panel On – FF.REW Keys disabled'}
]
</script>

<template>
  <div v-if="editorKey">

    <div class="grid">
      <div class="col-12 md:col-7">

        <div class="field grid">
          <label for="artists" :class="{'p-error': artistsError, 'col-12 md:col-3 font-bold': true}">{{ $t('medias.artists') }}</label>
          <div class="col md:col-8">
            <span class="p-fluid flex">
              <AutoCompleteSearch
                v-model="artists"
                itemValue="id"
                field="name"
                dropdown
                dropdownIcon="search"
                multiple
                :suggestions="listingArtists"
                :suggestionsTotal="listingArtistsTotal"
                @complete="searchArtists($event)"
                :class="{'w-full': true, 'p-invalid': artistsError}"
              />
              <Button icon="pi pi-plus" class="p-button ml-1" @click="openNewArtistForm()"/>
            </span>
            <small v-if="artistsError" class="p-error">{{artistsError}}</small>
          </div>
        </div>

        <div class="field grid">
          <label for="genres" class="col-12 md:col-3">Genres</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <AutoCompleteSearch
                v-model="genres"
                itemValue="id"
                field="name"
                dropdown
                dropdownIcon="search"
                multiple
                :suggestions="listingGenres"
                :suggestionsTotal="listingGenresTotal"
                @complete="searchGenres($event)"
                class="w-full"
              />
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="rating" class="col-12 md:col-3">{{ $t('medias.rating') }}</label>
          <div class="col md:col-8">
            <RatingEditor v-model="rating" />
          </div>
        </div>

        <div class="field grid">
          <label for="isViolence" class="col-12 md:col-6">Violence</label>
          <div class="col md:col-6">
            <InputSwitch v-model="hasViolence " id="isViolence" />
          </div>
        </div>

        <div class="field grid">
          <label for="isOffensiveLanguage" class="col-12 md:col-6">{{ $t('medias.offensiveLanguage') }}</label>
          <div class="col md:col-6">
            <InputSwitch v-model="hasOffensiveLanguage" id="isOffensiveLanguage"  />
          </div>
        </div>


      </div>

      <div class="col-12 md:col-4">

        <div class="field grid">
          <label for="year" class="col-12 md:col-6">{{ $t('forms.year') }}</label>
          <div class="col md:col-6">
            <YearEditor v-model="year" />
          </div>
        </div>

        <div class="field grid">
          <label for="countries" class="col-12 md:col-6">{{ $t('forms.countries') }}</label>
          <div class="col md:col-6">
            <span class="p-fluid">
              <AutoCompleteSearch
                v-model="countries"
                itemValue="id"
                field="name"
                dropdown
                dropdownIcon="search"
                multiple
                :suggestions="listingCountries"
                @complete="searchCountries($event)"
                :suggestionsTotal="listingCountriesTotal"
              />
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="channel" class="col-12 md:col-6">Channel Number</label>
          <div class="col md:col-6">
            <InputNumber v-model="channel" :min="0" allow-empty />
          </div>
        </div>

        <div class="field grid">
          <label for="runtime" :class="{'p-error': runtimeError, 'col-12 md:col-6 font-bold': true}">{{ $t('medias.runtime') }}</label>
          <div class="col md:col-6" >
            <RuntimeEditor v-model="runtime" :invalid="runtimeError" />
          </div>
        </div>

      </div>
    </div>
    <ArtistNew v-model="this.ui.showCreateArtist" @newArtist="addArtist($event)" no-router />
  </div>
</template>

<script>
import { computed } from 'vue'

import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'

import RatingEditor from '@/components/resource/RatingEditor.vue'
import YearEditor from '@/components/resource/YearEditor.vue'
import RuntimeEditor from '@/components/resource/RuntimeEditor.vue'

import ArtistNew from '@/pages/resources/artists/components/ArtistNew.vue'

import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'

import { useFlowCruder, useFlowEditor} from '@/compositions'

const KFlow = 'resource:artist'
const KOptionsName = 'EditingMediaRadio'

export default {
  name: KOptionsName,
  props: { editorKey: String },
  setup (props) {
    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save
    const {setError, ...use} = useFlowEditor(props.editorKey)

    const artists = computed({
      get () { return use.fieldGet('media.artists') },
      set (val) { use.fieldSet({field: 'media.artists', value: val}) }
    })

    const runtime = computed({
      get () { return use.fieldGet('media.runtime') },
      set (val) { use.fieldSet({field: 'media.runtime', value: val}) }
    })

    const rules = {
      artists: {
        required,
      },
      runtime: {
        required,
        minValue: minValue(1)
      }
    }

    const v$ = useVuelidate(rules, { artists, runtime }, { $scope: false })

    if (v$.value.$invalid) {
      setError(new Error('InvalidForm'), KOptionsName)
    }

    return {
      ...use,
      artists,
      runtime,
      v$,
      setError,
      ...useFlowCruder()
    }
  },
  components: {
    AutoCompleteSearch, RatingEditor, InputSwitch, RuntimeEditor, YearEditor, InputNumber, ArtistNew
  },
  data: () => ({
    listingArtists: [],
    listingArtistsTotal: 0,
    listingCountries: [],
    listingCountriesTotal: 0,
    listingGenres: [],
    listingGenresTotal: 0,
    ui: {
      showCreateArtist: false,
    }
  }),
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), KOptionsName) : this.setError(null, KOptionsName)
    }
  },
  computed: {
    rating: {
      get () { return this.fieldGet('media.rating') },
      set (val) { this.fieldSet({field: 'media.rating', value: val}) }
    },
    channel: {
      get () {
        const val = this.fieldGet('media.channelNumber')
        return val === 0 ? null : val
      },
      set (val) { this.fieldSet({field: 'media.channelNumber', value: val}) }
    },
    year: {
      get () { return this.fieldGet('media.year') },
      set (val) { this.fieldSet({field: 'media.year', value: val}) }
    },
    name: {
      get () { return this.fieldGet('name') },
      set (val) { this.fieldSet({field: 'name', value: val}) }
    },
    genres: {
      get () { return this.fieldGet('media.genres') },
      set (val) { this.fieldSet({field: 'media.genres', value: val}) }
    },
    countries: {
      get () { return this.fieldGet('media.countries') },
      set (val) { this.fieldSet({field: 'media.countries', value: val}) }
    },
    hasViolence: {
      get () { return this.fieldGet('media.hasViolence') },
      set (val) { this.fieldSet({field: 'media.hasViolence', value: val || false}) }
    },
    hasOffensiveLanguage: {
      get () { return this.fieldGet('media.hasOffensiveLanguage') },
      set (val) { this.fieldSet({field: 'media.hasOffensiveLanguage', value: val || false}) }
    },
    artistsError () {
      if (this.v$.artists.$invalid) return 'Artists are required'
      return ''
    },
    runtimeError () {
      if (this.v$.runtime.required.$invalid || this.v$.runtime.minValue.$invalid) return 'Runtime is required'
      return ''
    }
  },
  methods: {
    async searchArtists ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data, total} = await this.$pxstream.resource.getArtists(qryBuilder.build())
        this.listingArtists = data
        this.listingArtistsTotal = total
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get artists', detail: err.toString(), life: 4000})
      }
    },
    async searchGenres ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.addFilter('isBlocked', 'contains', ['false'])
        const {data, total} = await this.$pxstream.resource.getGenres(qryBuilder.build())
        this.listingGenres = data
        this.listingGenresTotal = total
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get genres', detail: err.toString(), life: 4000})
      }
    },
    async searchCountries ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        if (this.countries && this.countries.length !== 0) {
          qryBuilder.addFilter('id', 'nin', this.countries.map((country) => country.id))
        }
        const {data, total} = await this.$pxstream.resource.getCountries(qryBuilder.build())
        this.listingCountries = data
        this.listingCountriesTotal = total
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get countries', detail: err.toString(), life: 4000})
      }
    },
    async addArtist(event) {
      const { data } = await this.flowGet(KFlow, event)
      const name = `${data.firstname} ${data.name}`

      let newArtists = JSON.parse(JSON.stringify(this.artists)) || []
      newArtists.push({id: event, name})
      this.artists = newArtists
      this.$toast.add({severity: 'success', detail: 'Artist saved with success', summary: data.name, life: 4000})

      this.ui.showCreateArtist = false
    },
    openNewArtistForm () {
      this.ui.showCreateArtist = true
    }
  }
}
</script>
<template>
  <div>
    <div class="grid">
      <div class="col-12 md:col-6">

        <!-- Name -->
        <div class="field grid">
          <label for="name"
            class="col-12 md:col-3 font-bold">{{ $t('forms.name') }}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="name" type="text"
                :modelValue="name"
                disabled
              />
            </span>
          </div>
        </div>

        <!-- Airline Code -->
        <div class="field grid">
          <label for="airlineCode"
            class="col-12 md:col-3">Airline Code</label>
          <div class="col-4 sm:col-2 md:col-2 lg:col-2">
            <span class="p-fluid">
              <InputText id="airlineCode" type="text"
                :modelValue="airlineCode"
                v-debounce:300ms="(val) => (airlineCode = val)"
              />
            </span>
          </div>
        </div>

        <!-- Type -->
        <div class="field grid">
          <label for="type"
            class="col-12 md:col-3">Type</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <Dropdown id="type"
                v-model="type"
                :options="typeOptions"
                :showClear="!!type"
                :filter="false" />
            </span>
          </div>
        </div>

        <!-- Description -->
        <div class="field grid">
          <label for="description"
            class="col-12 md:col-3">Description</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <Textarea id="description" v-model="description" :filter="false" />
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { getDirective as vueDebounce } from 'vue-debounce'
import { useFlowEditor } from '@/compositions'

import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Textarea from 'primevue/textarea'

export default {
  name: 'ProjectDetails',
  components: {
    InputText, Dropdown, Textarea
  },
  directives: {
    debounce: vueDebounce(3)
  },
  props: { editorKey: String },
  setup (props) {
    const typeOptions = ['airline', 'airport', 'bus', 'csp', 'demo', 'ferrie', 'showroom']

    const {fieldGet, fieldSet} = useFlowEditor(props.editorKey)

    const name = computed({
      get: () => (fieldGet('name')),
    })

    const airlineCode = computed({
      get: () => (fieldGet('airlineCode')),
      set: (value) => (fieldSet({field: 'airlineCode', value }))
    })

    const type = computed({
      get: () => (fieldGet('type')),
      set: (value) => (
        fieldSet({field: 'type', value: value || '' })
      )
    })

    const description = computed({
      get: () => (fieldGet('description')),
      set: (value) => (fieldSet({field: 'description', value }))
    })



    return {
      typeOptions,
      name,
      airlineCode,
      type,
      description,
    }
  }
}
</script>

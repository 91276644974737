import { ref } from 'vue'

import pxstream from '@/services/pxstream'
import Log from '@/services/logger'


export function useFlowCruder () {

  const isSaving = ref(false)
  const isGetting = ref(false)
  const isGettingNew   = ref(false)

  async function flowSave (req) {
    Log('flowSave', req)
    if (req.type === 'new') {
      return __create(req)
    } else {
      return __update(req)
    }
  }

  async function __update ({id, flow, patch, links}) {
    isSaving.value = true
    return new Promise((resolve, reject) => {
      pxstream
        .saveOne(flow, id, { patch, links })
        .then(() => {
          isSaving.value = false
          resolve({})
        })
        .catch(err => {
          reject(err)
          isSaving.value = false
        })
    })
  }

  async function __create ({data, flow, patch, links}) {
    isSaving.value = true
    return new Promise((resolve, reject) => {
      pxstream
      .createOne(flow, data, { patch, links })
      .then(({data}) => {
        Log(data)
        isSaving.value = false
        resolve({redirect: data.id})
      })
      .catch(err => {
        reject(err.response ? err.response.data : err)
        isSaving.value = false
      })
    })
  }

  async function flowGet (flow, id) {
    isGetting.value = true
    return new Promise((resolve, reject) => {
      if (id === 'new') {
        try {
          const item = sessionStorage.getItem(`${flow}:new`)
          const data = JSON.parse(item)
          isGetting.value = false
          isGettingNew.value = true
          data.data.id = 'new'
          resolve(data)
        } catch (e) {
          isGetting.value = false
          reject(e)
        }
      } else {
        pxstream
        .getOne(flow, id)
        .then( ({data}) => {
          isGetting.value = false
          isGettingNew.value = false
          resolve(data)
        })
        .catch( err => {
          isGetting.value = false
          reject(err)
        })
      }
    })
  }

  return {
    isGetting,
    isGettingNew,
    isSaving,

    flowGet,
    flowSave
  }
}
import { ref } from 'vue'

export function usePanelToggle () {

  const openedPanel = ref(-1)
  
  function panelSet (index) {
    openedPanel.value = index
  }

  function panelToggle (index) {
    openedPanel.value = openedPanel.value === index ? -1 : index
  }

  function panelIsCollapsed (index) {
    return !(index === openedPanel.value)
  }

  return {
    panelSet,
    panelToggle,
    panelIsCollapsed
  }
}
<template>
  <div v-if="editorKey" class="collections-edition panels">
    <draggable v-model="tracks" item-key="id" handle=".handle">
      <template #item="{element: track, index}">
        <Panel :collapsed="isCollapsed(index)">
          <template #icons>
            <Button class="p-panel-header-icon mr-2" @click="toggle(index)">
              <span :class="`pi pi-${isCollapsed(index) ? 'plus' : 'minus'}`"></span>
            </Button>
            <Button class="p-panel-header-icon mr-2" @click="deleteTrack(track, $event)">
              <span class="pi pi-trash text-danger"></span>
            </Button>
            <OverlayPanel class="delete-overlay" :ref="`op_${track.id}`" :dismissable="true">
              <span @click="onTrackRemove(track, $event)" class="cursor-pointer underline text-danger">{{ $t('actions.remove') }}</span>
            </OverlayPanel>
          </template>
          <template #header>
            <div class="flex align-items-center">
              <button class="p-panel-header-icon handle cursor-move">
                <span class="pi pi-bars"></span>
              </button>
              <span class="pl-3" :class="{'p-error': hasLinksError(`${flow}:${track.id}`)}">
                <i v-if="hasLinksError(`${flow}:${track.id}`)" class="mr-1 pi pi-exclamation-triangle"></i>
                <span>{{track.name}}</span>
              </span>
            </div>
          </template>
          <EditingCore :doc="track" :editorMain="editorKey" :flow="flow"/>
        </Panel>
      </template>
      <template #footer>
        <Button
          label="Add Track"
          class="p-button-outlined mt-3"
          icon="pi pi-plus"
          iconPos="left"
          @click="addTrack"
          />
      </template>
    </draggable>
    <TrackNew v-model="showNewTrack" :doc="doc" :editorMain="editorKey" @onTrackAdded="onTrackAdded" />
  </div>
</template>

<script>
import { ref } from 'vue'

import draggable from 'vuedraggable'
import Panel from 'primevue/panel'
import OverlayPanel from 'primevue/overlaypanel'

import TrackNew from '../TrackNew.vue'

import { useFlowEditor } from '@/compositions/useFlowEditor'

const KFlow = "portalmanager:media"

export default {
  name: 'EditingTracks',
  props: { editorKey: String },
  setup (props) {
    const openedPanel = ref(null)
    const showNewTrack = ref(false)

    return {
      flow: KFlow,
      openedPanel,
      showNewTrack,
      ...useFlowEditor(props.editorKey)
    }
  },
  components: {
    draggable,
    Panel,
    OverlayPanel,
    TrackNew,
  },
  computed: {
    tracks: {
      get () { return this.fieldGet('media.tracks') || [] },
      set (value) { return this.fieldSet({field: 'media.tracks', value })}
    }
  },
  methods: {
    addTrack () {
      this.showNewTrack = true
    },
    onTrackAdded (req) {
      this.openedPanel = this.tracks.length
      this.fieldPush({field: 'media.tracks', value: req.doc})
      this.saveFlowAddLink(req)
    },
    onTrackRemove (e, $event) {
      this.fieldSplice({field: 'media.tracks', filter: (track) => {
        return track.id !== e.id
      }})
      this.$refs[`op_${e.id}`].toggle($event);
    },
    deleteTrack(e, $event) {
      this.$refs[`op_${e.id}`].toggle($event);
    },
    toggle (index) {
      this.openedPanel = this.openedPanel === index ? null : index
    },
    isCollapsed (index) {
      return !(index === this.openedPanel)
    }
  },
}
</script>

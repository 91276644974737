<template>
  <div>
    <Dialog 
    v-model:visible="showIt" 
    style="width:400px"
    @hide="hide();resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Add Season</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="create">
        
        <div class="fluid">
          <div class="field">
            <label for="title" :class="{'p-error':titleError}">{{ $t('forms.title') }}</label>
            <InputText id="title" type="text"
              v-model="title" 
              :class="{'p-invalid': titleError}"
              :disabled="isLoading"
            />
            <small v-if="titleError" class="p-error">{{titleError}}</small>
          </div>
        </div>

        <div class="fluid">
          <div class="field">
            <label for="seasonNumber" :class="{'p-error':seasonNumberError}">Season Number</label>
            <InputText id="seasonNumber" type="text"
              v-model="seasonNumber" 
              :class="{'p-invalid': seasonNumberError}"
              :disabled="isLoading"
            />
            <small v-if="seasonNumberError" class="p-error">{{seasonNumberError}}</small>
          </div>
        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="px-button-cancel p-button-text" @click="showIt = false"/>
          <Button :label="'Create'" icon="pi pi-check" @click="create"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import InputText from "primevue/inputtext"
import Message from "primevue/message"
import { required, numeric } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"

export default {
  name: 'SeasonNew',
  setup: () => ({ v$: useVuelidate() }),
  props: ['modelValue', 'doc'],
  emits: ['update:modelValue', 'onSeasonAdded'],
  components: { 
    InputText, Message
  },
  data () {
    return {
      title: '',
      seasonNumber: '',
      defaultLanguage: '',
      showIt: false,
      isLoading: false,
      errorCaught: ''
    }
  },

  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.title = ''
      this.seasonNumber = ''
      this.defaultLanguage = this.doc.defaultLanguage
    },

    create () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.portalmanager.createEmptySeasonTv({
        defaultLanguage: this.doc.defaultLanguage.id
      })
      .then(({data}) => {
        data.id = 'new' 
        this.$emit('onSeasonAdded', data, { title: this.title, seasonNumber: this.seasonNumber })
        this.hide()
      })
      .catch(({message}) => {
        this.errorCaught = message
      })
      .finally(() => {
        this.isLoading = false
      })

    },
    hide () {
      this.$emit('update:modelValue', false)
    }
  },

  computed: {
    titleError () {
      if (!this.v$.title.$dirty) return ''
      if (this.v$.title.required.$invalid) return 'Title is required.'
      return ''
    },
    seasonNumberError () {
      if (!this.v$.seasonNumber.$dirty) return ''
      if (this.v$.seasonNumber.required.$invalid) return 'Season number is required.'
      if (this.v$.seasonNumber.numeric.$invalid) return 'Season number must be a number'
      return ''
    }
  },

  validations: {
    title: { 
      required,
    },
    seasonNumber: {
      required,
      numeric
    }
  },

  watch: {
    showIt (show) {
      if (show) {
        this.resetFields()
      }
    },
    modelValue: function (val) {
      this.showIt = val
    }
  }
}
</script>


// TODO from DB
const RscCodecsAudio = [
  {id: "MP3", name: "MP3", type: "audio"},
  {id: "MP2", name: "MP2", type: "audio"},
  {id: "ABS", name: "ABS", type: "audio"},
  {id: "WAV", name: "WAV", type: "audio"},
  {id: "AAC", name: "AAC", type: "audio"},
]

// TODO from DB
const RscCodecsVideo = [
  {id: "MPEG4", name: "MPEG4", type: "video"},
  {id: "MP4", name: "MP4", type: "video"},
  {id: "MPG", name: "MPG", type: "video"}
]

// TODO from DB
const RscAudioBitrates = [
  {id: "K128", name: "128 Kbps"},
  {id: "K192", name: "192 Kbps"},
  {id: "K256", name: "256 Kbps"}
]

// TODO from DB
const RscVideoBitrates = [
  {id: "M1_5", name: "1.5 Mbps"}
]

// TODO from DB
const RscAudioEncodings = [
  {id: "LeftMono", name: "Left-Mono"},
  {id: "RightMono", name: "Right-Mono"},
  {id: "Stereo", name: "Stereo"},
  {id: "Unused", name: "Unused"}
]

// TODO from DB
const RscVideoResolutions = [
  {id: '_720p', name: '720p'},
  {id: '_1080p', name: '1080p'},
  {id: '_4K', name: '4K'}
]

// TODO from DB
const RscVideoRatios = [
  {id: '_16_9', name: '16x9'},
  {id: '_4_3', name: '4x3'}
]

// TODO from DB
const RscVideoBoxTypes = [
  {id: 'boxed', name: 'Boxed'},
  {id: 'single', name: 'Single'}
]

// TODO from DB
const RscVideoTrickModes = [
  {id: 'OFF_ALL', name: 'Video Panel Off – All keys functional'},
  {id: 'OFF_STOP', name: 'Video Panel Off – Stop Key disabled'},
  {id: 'OFF_FFREW', name: 'Video Panel Off – FF.REW Keys disabled'},
  {id: 'ON_ALL', name: 'Video Panel On – All keys functional'},
  {id: 'ON_STOP', name: 'Video Panel On – Stop Key disabled'},
  {id: 'ON_FFREW', name: 'Video Panel On – FF.REW Keys disabled'}
]

class Resource {
  constructor (http, tools) {
    this.http = http
    this.tools = tools
  }

  // RESOURCES
  async getAudioCodecs () {
    return new Promise(resolve => resolve({data: RscCodecsAudio}))
  }

  async getVideoCodecs () {
    return new Promise(resolve => resolve({data: RscCodecsVideo}))
  }

  async getVideoBitrates () {
    return new Promise(resolve => resolve({data: RscVideoBitrates}))
  }

  async getVideoResolutions () {
    return new Promise(resolve => resolve({data: RscVideoResolutions}))
  }

  async getVideoRatios () {
    return new Promise(resolve => resolve({data: RscVideoRatios}))
  }

  async getVideoBoxTypes () {
    return new Promise(resolve => resolve({data: RscVideoBoxTypes}))
  }

  async getVideoTrickModes () {
    return new Promise(resolve => resolve({data: RscVideoTrickModes}))
  }

  async getAudioBitrates () {
    return new Promise(resolve => resolve({data: RscAudioBitrates}))
  }

  async getAudioEncodings () {
    return new Promise(resolve => resolve({data: RscAudioEncodings}))
  }

  async getTemplates (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/portalmanager/templates?${qry}`)
    return data
  }
  
  // Language
  async getOneLanguage (id) {
    const {data} = await this.http.core.get(`/flow/resource/languages/${id}`)
    return data
  }

  // Artists
  async getArtists (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/resource/artists?${qry}`)
    return data
  }

  async createArtist ({ firstname, name, defaultLanguage }) {
    const {data} = await this.http.core.post(`/flow/resource/artists`, {
      firstname, name, defaultLanguage
    })
    return data
  }

  // Genres
  async getGenres (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/resource/genres?${qry}`)
    return data
  }

  async createGenre ({name}) {
    const { data } = await this.http.core.post(`/flow/resource/genres`, {
      name
    })
    return data
  }

  //  Countries
  async getCountries (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/resource/countries?${qry}`)
    return data
  }

  // Actors
  async getActors (params) {
    const qry = this.tools.buildListingQuery(params)
    const { data } = await this.http.core.get(`/flow/resource/actors?${qry}`)
    return data
  }

  async createActor ({ firstname, name, defaultLanguage }) {
    const { data } = await this.http.core.post(`/flow/resource/actors`, {
      firstname, name, defaultLanguage
    })
    return data
  }

  // Licensors
  async getLicensors (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/resource/licensors?${qry}`)
    return data
  }

  async getContentOwners (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/resource/content-owners?${qry}`)
    return data
  }

  async getProviders (params) {
    const qry = this.tools.buildListingQuery(params)
    const {data} = await this.http.core.get(`/flow/resource/providers?${qry}`)
    return data
  }

  // Labs
  async getLabs (params) {
    const qry = this.tools.buildListingQuery(params)
    const { data } = await this.http.core.get(`/flow/resource/labs?${qry}`)
    return data
  }

  // Ifes
  async getIfes (params) {
    const qry = this.tools.buildListingQuery(params)
    const { data } = await this.http.core.get(`/flow/resource/ifes?${qry}`)
    return data
  }

  async getIfe (id) {
    const { data } = await this.http.core.get(`/flow/resource/ifes/${id}`)
    return data
  }

  // IfeProducts
  async getIfeProducts (params) {
    const qry = this.tools.buildListingQuery(params)
    const { data } = await this.http.core.get(`/flow/resource/ife-products?${qry}`)
    return data
  }

  // Menus
  async getMediaFormats (params) {
    const qry = this.tools.buildListingQuery(params)
    const { data } = await this.http.core.get(`/flow/resource/media-formats?${qry}`)
    return data
  }
}

export default Resource
<template>
  <tr v-if="currentVideoTrickMode" role="row">
    <td class="column-title" role="cell">
      <span>{{ label }}</span>
    </td>
    <td class="column-data flex" role="cell">
      {{ currentVideoTrickMode }}
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    videoTrickMode: String,
    label: String,
  },
  setup(props) {
    const RscVideoTrickModes = [
      {id: 'OFF_ALL', name: 'Video Panel Off – All keys functional'},
      {id: 'OFF_STOP', name: 'Video Panel Off – Stop Key disabled'},
      {id: 'OFF_FFREW', name: 'Video Panel Off – FF.REW Keys disabled'},
      {id: 'ON_ALL', name: 'Video Panel On – All keys functional'},
      {id: 'ON_STOP', name: 'Video Panel On – Stop Key disabled'},
      {id: 'ON_FFREW', name: 'Video Panel On – FF.REW Keys disabled'}
    ]

    const currentVideoTrickMode = computed(() => {
      return RscVideoTrickModes.find(rscVideoTrickMode => rscVideoTrickMode.id === props.videoTrickMode)?.name || ''
    })

    return {
      currentVideoTrickMode
    }
  },
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>

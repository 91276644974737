import pxstream from '@/services/pxstream'
import Log from '@/services/logger'

const app = {
  namespaced: true,
  state: () => ({
    userOri: {},
    userWip: {},
    hasUpdate: false
  }),
  mutations: {
    setUser (state, args) {
      state.userOri = args.data
      state.userWip = JSON.parse(JSON.stringify(args.data))
    },
    updateField (state, {field, value}) {
      Log(field, value)

      switch (field) {
        case 'username':
          state.userWip.username = value
          break
        case 'email':
          state.userWip.email = value
          break
        case 'isBlocked':
          state.userWip.isBlocked = value
          break
        default:
          Log.Warn('[store:admin-user] UnknownField:'+field)
      }
    },
    checkHasUpdate (state) {
      if (JSON.stringify(state.userOri).localeCompare(JSON.stringify(state.userWip)) !== 0) {
        state.hasUpdate = true
      } else {
        state.hasUpdate = false
      }
      const patch = pxstream.tools.buildPatch(state.userOri, state.userWip)
      Log(patch)
    }
  },
  actions: {
    update ({commit}, args) {
      commit('updateField', args)
      commit('checkHasUpdate')
    },
    async get ({commit}, id) {
      return new Promise((resolve, reject) => {
        pxstream
        .getOneUser(id)
        .then( ({data}) => {
          commit('setUser', data)
          commit('checkHasUpdate')
          resolve(data)
        })
        .catch( err => {
          reject(err)
        })
      })
    },
    async save ({state, commit, dispatch}) {
      return new Promise((resolve, reject) => {
        const patch = pxstream.tools.buildPatch(state.userOri, state.userWip)
        pxstream
        .saveOneUser(state.userOri.id, patch)
        .then(() => {
          dispatch('get', state.userOri.id)
          .then(() => {
            commit('checkHasUpdate')
            resolve()
          })
          .catch(err => {
            reject(err)
          })
        })
        .catch(err => {
          reject(err)
        })
      })
    }
  },
  getters: {
    user: state => state.userWip,
    hasUpdate: state => state.hasUpdate
  }
}

export default app

<template>
  <div v-if="editorKey">
    <div class="flex flex-column flex-column-reverse md:flex-row">
      <div class="w-full fluid">
        <div v-for="metadataKey in metadataKeys" v-bind:key="metadataKey">
          <div v-if="languageById(metadataKey)" class="flex align-items-center border-bottom-1 border-purple-500 pb-3 mb-3 max-w-90">
            <LanguageFlagDisplay :language="languageById(metadataKey)" />
            <span class="ml-3">{{languageById(metadataKey).name}}</span>
          </div>
          <div class="divider"/>
          <div class="field grid">
            <label for="title" class="col-12 mb-2 md:col-2 md:mb-0 font-bold">{{ $t('forms.title') }}</label>
            <div class="col md:col-8">
              <InputText id="title" :modelValue="metadata[metadataKey].title" v-debounce:300ms="(val) => {changeTitle(val, metadataKey)}" :placeholder="metadata[metadataKey].titlePlaceholder" type="text" class="w-full"/>
            </div>
          </div>
          <div class="field grid">
            <label for="shortSynopsis" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.shortSynopsis') }}</label>
            <div class="col md:col-8">
              <Textarea id="shortSynopsis" :modelValue="metadata[metadataKey].shortSynopsis" v-debounce:300ms="(val) => {changeShortSynopsis(val, metadataKey)}" :placeholder="metadata[metadataKey].shortSynopsisPlaceholder" class="w-full"/>
            </div>
          </div>
          <div class="field grid">
            <label for="synopsis" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.synopsis') }}</label>
            <div class="col md:col-8" >
              <Textarea id="synopsis" :modelValue="metadata[metadataKey].synopsis" v-debounce:300ms="(val) => {changeSynopsis(val, metadataKey)}" :placeholder="metadata[metadataKey].synopsisPlaceholder" rows="8" class="w-full"/>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4 md:m-0">
        <div class="field grid center">
          <LanguageSelector v-if="excludedAvailableLanguages.length > 0" v-model="language" :onlyLanguages="excludedAvailableLanguages" :sort="sort" />
        </div>
        <div class="field grid center">
          <Button
            v-if="excludedAvailableLanguages.length > 0"
            label="Add metadata"
            icon="pi pi-plus"
            iconPos="right"
            :disabled="!language"
            @click="initMetadataLanguage"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { getDirective as vueDebounce } from 'vue-debounce'

import { useFlowEditor } from '@/compositions'

import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'

import LanguageFlagDisplay from '@/components/resource/LanguageFlagDisplay.vue'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'

export default {
  name: 'MenuParametersMetadata',
  props: {
    editorKey: String
  },
  setup (props) {
    let hasToApplyChanges = false

    const menuEditorKey = inject('menuEditorKey')

    const language = ref('')

    const metadata = reactive({})

    const store = useStore()

    const menuEditor = computed(() => useFlowEditor(menuEditorKey))

    const parametersEditor = computed(() => useFlowEditor(props.editorKey))

    const mediaMetadata = computed(() => parametersEditor.value?.fieldGet('media.metadata') || {})

    const mediafileSettingsMetadata = computed({
      get () { return parametersEditor.value?.fieldGet('metadata') || {} },
      set (value) { parametersEditor.value?.fieldSet({ field: 'metadata', value }) }
    })

    const metadataKeys = computed(() => {
      return Object.keys(metadata)
    })

    const availableLanguages = computed(() => menuEditor.value?.doc?.cycle?.languages?.map(item => item.id))

    const excludedAvailableLanguages = computed(() => availableLanguages.value?.filter(lang => !metadataKeys.value?.find(excludedLang => excludedLang === lang)) || [])

    const languages = computed(() => store.getters['resource/languages'])

    const languageById = computed(() => store.getters['resource/languageById'])

    const loadMetadata = () => {
      Object.keys(metadata).forEach(idLang => {
        delete metadata[idLang]
      })

      Object.keys(mediaMetadata.value).forEach(idLang => {
        metadata[idLang] = {
          shortSynopsis: '',
          shortSynopsisPlaceholder: mediaMetadata.value[idLang].shortSynopsis,
          synopsis: '',
          synopsisPlaceholder: mediaMetadata.value[idLang].synopsis,
          title: '',
          titlePlaceholder: mediaMetadata.value[idLang].title
        }
      })

      Object.keys(mediafileSettingsMetadata.value).forEach(idLang => {
        if (!metadata[idLang]) {
          metadata[idLang] = {
            shortSynopsis: '',
            shortSynopsisPlaceholder: '',
            synopsis: '',
            synopsisPlaceholder: '',
            title: '',
            titlePlaceholder: ''
          }
        }
        metadata[idLang].shortSynopsis = mediafileSettingsMetadata.value[idLang].shortSynopsis
        metadata[idLang].synopsis = mediafileSettingsMetadata.value[idLang].synopsis
        metadata[idLang].title = mediafileSettingsMetadata.value[idLang].title
        metadata[idLang].new = !!mediafileSettingsMetadata.value[idLang].new
      })

      hasToApplyChanges = true
    }

    const change = (value) => {
      const newSettingsMetadata = {}

      Object.keys(value).forEach(idLang => {
        const lang = value[idLang]
        if (lang.title !== '' || lang.shortSynopsis !== '' || lang.synopsis !== '') {
          newSettingsMetadata[idLang] = {
            shortSynopsis: lang.shortSynopsis,
            synopsis: lang.synopsis,
            title: lang.title
          }
        }
      })

      mediafileSettingsMetadata.value = newSettingsMetadata
    }

    const initMetadataLanguage = () => {
      metadata[language.value] = {
        shortSynopsis: '',
        synopsis: '',
        title: ''
      }

      language.value = null
    }

    const changeTitle = (event, language) => {
      metadata[language].title = event
    }

    const changeShortSynopsis = (event, language) => {
      metadata[language].shortSynopsis = event
    }

    const changeSynopsis = (event, language) => {
      metadata[language].synopsis = event
    }
    const sort = (a, b) => {
      return excludedAvailableLanguages.value.findIndex(val => val === a.id) - excludedAvailableLanguages.value.findIndex(val => val === b.id)
    }
    watch(metadata, () => {
      if (hasToApplyChanges) {
        change(metadata)
      }
    })

    watch(() => props.editorKey, () => {
      hasToApplyChanges = false
      loadMetadata()
    })

    loadMetadata()

    return {
      language,
      metadata,
      metadataKeys,
      languages,
      languageById,
      initMetadataLanguage,
      changeTitle,
      changeShortSynopsis,
      changeSynopsis,
      availableLanguages,
      sort,
      excludedAvailableLanguages
    }
  },
  directives: {
    debounce: vueDebounce(3)
  },
  components: {
    InputText,
    Textarea,
    LanguageFlagDisplay,
    LanguageSelector
  }
}
</script>
<style scoped>
  .max-w-90{
    max-width: 90%;
  }

  #title::placeholder{
    opacity: .7;
  }
</style>

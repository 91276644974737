<template>
  <div v-if="editorKey">

    <TabEditor :activeIndex="2" :editorKey="editorKey" :editorMain="editorKey">
      <Tab content="EditingMediaSeason">
        <template template #header>
          <span :class="{'p-error': hasErrorFrom('EditingMediaSeason')}">
            <i v-if="hasErrorFrom('EditingMediaSeason')" class="mr-1 pi pi-exclamation-triangle"></i>
            {{ $t('tabs.generalInformation') }}
          </span>
        </template>
      </Tab>
      <Tab content="EditingMetadata">
        <template #header>
          <span :class="{'p-error': hasErrorFrom('EditingMetadata')}">
            <i v-if="hasErrorFrom('EditingMetadata')" class="mr-1 pi pi-exclamation-triangle"></i>
            Metadata
          </span>
        </template>
      </Tab>
      <Tab content="EditingEpisodes" :contentProps="{editorKey, editorRoot: editorMain}">
        <template #header>
          <span :class="{'p-error': hasLinksError()}">
            <i v-if="hasLinksError()" class="mr-1 pi pi-exclamation-triangle"></i>
            Episodes
          </span>
        </template>
      </Tab>
    </TabEditor>
  </div>
</template>

<script>
import { useFlowPMTVSeasonBuilder } from '@/compositions/useFlowPMTVSeasonEditor'
import TabEditor from '@/components/base/TabEditor.vue'

export default {
  name: 'EditingSeason',
  props: { doc: Object, editorMain: String },
  components: {
    TabEditor: TabEditor,
    Tab: TabEditor.Tab,
  },
  setup (props, { emit }) {
    return {
      ...useFlowPMTVSeasonBuilder(props.doc, props.editorMain, data => {
        emit('field-change', data)
      })
    }
  }
}
</script>

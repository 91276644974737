<template>
  <div v-if="editorKey" class="field grid">
    <div class="col-12 md:col-6">
      <div class="field grid">
        <label for="embeddedThumbnail1" class="col-12 md:col-3">Embedded Thumbnail 1:</label>
        <div class="p-fluid">
          <InputText id="embeddedThumbnail1" :modelValue="embeddedThumbnail1" v-debounce:300ms="(val) => (embeddedThumbnail1 = val)" />
        </div>
      </div>
      <div class="field grid">
        <label for="embeddedThumbnail2" class="col-12 md:col-3">Embedded Thumbnail 2:</label>
        <div class="p-fluid">
          <InputText id="embeddedThumbnail2" :modelValue="embeddedThumbnail2" v-debounce:300ms="(val) => (embeddedThumbnail2 = val)" />
        </div>
      </div>
      <div class="field grid">
        <label for="embeddedThumbnail3" class="col-12 md:col-3">Embedded Thumbnail 3:</label>
        <div class="p-fluid">
          <InputText id="embeddedThumbnail3" :modelValue="embeddedThumbnail3" v-debounce:300ms="(val) => (embeddedThumbnail3 = val)" />
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field grid">
        <label for="embeddedImageLarge1" class="col-12 md:col-3">Embedded Image Large 1:</label>
        <div class="p-fluid">
          <InputText id="embeddedImageLarge1" :modelValue="embeddedImageLarge1" v-debounce:300ms="(val) => (embeddedImageLarge1 = val)" />
        </div>
      </div>
      <div class="field grid">
        <label for="embeddedImageLarge2" class="col-12 md:col-3">Embedded Image Large 2:</label>
        <div class="p-fluid">
          <InputText id="embeddedImageLarge2" :modelValue="embeddedImageLarge2" v-debounce:300ms="(val) => (embeddedImageLarge2 = val)" />
        </div>
      </div>
      <div class="field grid">
        <label for="embeddedImageSmall1" class="col-12 md:col-3">Embedded Image Small 1:</label>
        <div class="p-fluid">
          <InputText id="embeddedImageSmall1" :modelValue="embeddedImageSmall1" v-debounce:300ms="(val) => (embeddedImageSmall1 = val)" />
        </div>
      </div>
      <div class="field grid">
        <label for="embeddedImageSmall2" class="col-12 md:col-3">Embedded Image Small 2:</label>
        <div class="p-fluid">
          <InputText id="embeddedImageSmall2" :modelValue="embeddedImageSmall2" v-debounce:300ms="(val) => (embeddedImageSmall2 = val)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useFlowEditor } from '@/compositions'

import { getDirective as vueDebounce } from 'vue-debounce'

import InputText from "primevue/inputtext"

export default {
  name: 'MenuParametersPictures',
  props: { editorKey: String },
  setup (props) {
    const parametersEditor = computed(() => useFlowEditor(props.editorKey))

    const embeddedThumbnail1 = computed({
      get () { return parametersEditor.value?.fieldGet('embeddedThumbnail1') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'embeddedThumbnail1', value }) }
    })

    const embeddedThumbnail2 = computed({
      get () { return parametersEditor.value?.fieldGet('embeddedThumbnail2') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'embeddedThumbnail2', value }) }
    })

    const embeddedThumbnail3 = computed({
      get () { return parametersEditor.value?.fieldGet('embeddedThumbnail3') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'embeddedThumbnail3', value }) }
    })

    const embeddedImageLarge1 = computed({
      get () { return parametersEditor.value?.fieldGet('embeddedImageLarge1') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'embeddedImageLarge1', value }) }
    })

    const embeddedImageLarge2 = computed({
      get () { return parametersEditor.value?.fieldGet('embeddedImageLarge2') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'embeddedImageLarge2', value }) }
    })

    const embeddedImageSmall1 = computed({
      get () { return parametersEditor.value?.fieldGet('embeddedImageSmall1') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'embeddedImageSmall1', value }) }
    })

    const embeddedImageSmall2 = computed({
      get () { return parametersEditor.value?.fieldGet('embeddedImageSmall2') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'embeddedImageSmall2', value }) }
    })

    return {
      embeddedThumbnail1,
      embeddedThumbnail2,
      embeddedThumbnail3,
      embeddedImageLarge1,
      embeddedImageLarge2,
      embeddedImageSmall1,
      embeddedImageSmall2
    }
  },
  directives: {
    debounce: vueDebounce(3)
  },
  components: {
    InputText
  }
}
</script>

<template>
  <AutoComplete
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :itemValue="itemValue"
    :field="field"
    :multiple="multiple"
    :dropdown="dropdown"
    :suggestions="suggestions"
    @complete="$emit('complete', $event)"
    :class="{'w-full': true, 'autocomplete-search-icon': dropdownIcon === 'search'}"
    >
    <template #footer>
      <span v-if="missingResults > 0" class="p-autocomplete-footer">+{{missingResults}} result{{ missingResults > 1 ? 's' : '' }}</span>
    </template>
    </AutoComplete>
</template>

<script>
import AutoComplete from 'primevue/autocomplete'
export default {
  components: {
    AutoComplete
  },
  props: {
    modelValue: [Array, Object, String, null],
    field: String,
    itemValue: String,
    multiple: Boolean,
    suggestions: [Array, null],
    suggestionsTotal: {
      type: Number,
      default: 0
    },
    dropdown: [Boolean, null],
    dropdownIcon: [String, null]
  },
  emits: ['update:modelValue', 'complete'],
  computed: {
    missingResults () {
      return this.suggestionsTotal - this.suggestions?.length
    }
  }
}
</script>

<style>
.p-autocomplete.autocomplete-search-icon button.p-autocomplete-dropdown > span.pi-chevron-down::before {
  content: "\e908";
}

span.p-autocomplete-footer {
  margin: 0;
  margin-top: calc(-1 * var(--inline-spacing));
  padding: var(--inline-spacing) var(--content-padding);
  color: var(--text-color);
  border: 0 none;
  position: relative;
  white-space: nowrap;
  background: var(--surface-d);
  width: 100%;
  display: inline-block;
  font-weight: bold;
}
</style>
import axios from 'axios'
import router from '@/router.js'
import { auth } from '@/plugins/auth'

const isAuthRequest = (url) => {
  const requestUrl = url || ''
  return requestUrl.includes('/session/me')
    || requestUrl.includes('/session/logout')
    || requestUrl.includes('/session/login')
}

axios.interceptors.response.use(
  function (response) {
    return response;
  }, 
  async function (error) {
    const currentRoute = router.currentRoute.value
    if (error.response && error.response.status === 401
      && !isAuthRequest(error.response.config.url)) {
      await auth.logout()
      router.replace({
        name: 'AccountLogin', query: {
        redirect: currentRoute.fullPath
      } })
    }
    return Promise.reject(error);
  }
)

function getApiUrl (url) {
  return '/core' + url
}

const plugin = {
  axios: axios,
  getApiUrl: getApiUrl,
  core: {
    get (url) {
      // console.log(`[GET] ${url} - ${JSON.stringify(buildCfg(cfg))}`)
      const u = getApiUrl(url)
      return axios.get(u)
    },
    delete (url) {
      // console.log(`[DELETE] ${url}`)
      const u = getApiUrl(url)
      return axios.delete(u)
    },
    put (url, data) {
      // console.log(`[PUT] ${url}`)
      const u = getApiUrl(url)
      return axios.put(u, data)
    },
    post (url, data) {
      // console.log(`[POST] ${url}`)
      const u = getApiUrl(url)
      return axios.post(u, data)
    }
  }
}

export default plugin

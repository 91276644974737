<template>
  <div v-if="editorKey">
    <div class="field grid">
      <label for="playPeriodFrom" class="col-12 md:col-3">Play period from:</label>
      <div class="p-fluid">
        <Calendar id="playPeriodFrom" class="customCalendar" appendTo="self" v-model="playPeriodFrom" dateFormat="yy-mm-dd" />
      </div>
    </div>
    <div class="field grid">
      <label for="playPeriodTo" class="col-12 md:col-3">Play period to:</label>
      <div class="p-fluid">
        <Calendar id="playPeriodTo" class="customCalendar" appendTo="self" v-model="playPeriodTo" dateFormat="yy-mm-dd" />
      </div>
    </div>
    <div v-if="(isTvSeries || isTvSimple)" class="field grid">
      <label for="featureID" class="col-12 md:col-3">Feature ID:</label>
      <div class="p-fluid">
        <InputText id="featureID" type="text" v-model="featureID" />
      </div>
    </div>
    <div v-if="isTvSeries" class="field grid">
      <label for="tvSeriesFeatureID" class="col-12 md:col-3">Feature ID Series:</label>
      <div class="p-fluid">
        <InputText id="tvSeriesFeatureID" type="text" v-model="tvSeriesfeatureID" />
      </div>
    </div>
    <div class="field grid">
      <label for="hasPriorityEnabled" class="col-12 md:col-3">Priority:</label>
      <div class="col md:col-8">
        <div class="p-fluid">
          <InputSwitch id="hasPriorityEnabled"
            v-model="hasPriorityEnabled" />
        </div>
      </div>
    </div>
    <div class="field grid align-items-baseline">
      <label for="hasTrailerEnabled" class="col-12 md:col-3">Trailer:</label>
      <div class="col md:col-2">
        <div class="p-fluid">
          <InputSwitch id="hasTrailerEnabled"
            v-model="hasTrailerEnabled" />
        </div>
      </div>
      <div v-if="trailerOptions.length > 0 && hasTrailerEnabled" class="field grid col md:col-7">
        <div :class="`col-12 flex align-items-center flex-wrap ${trailerOptions.length > 1 ? 'mb-1' : ''}`" v-bind:key="val.id" v-for="val in trailerOptions">
          <RadioButton class="mr-1" name="trailer" :value="val" v-model="trailer"/>
          <span class="pr-3">{{val.filename}}</span>
          <span class="flex align-items-center text-3xl font-light">
            <ViewFlagRow v-if="val.content.audioTracks.length > 0" :flags="val.content.audioTracks" flagWidth="35px" flagHeight="25px" field="language.id"/>
            <span v-else class="pl-4 pr-2"> - </span> /
            <ViewFlagRow v-if="val.content.burnedSubs.length > 0" :flags="val.content.burnedSubs" flagWidth="35px" flagHeight="25px" field="language.id"/>
            <span v-else class="px-2"> - </span> /
            <ViewFlagRow v-if="val.content.dynamicSubs.length > 0" flagWidth="35px" flagHeight="25px" :flags="val.content.dynamicSubs" field="language.id" useCC ccField="CC"/>
            <span v-else class="pl-2"> - </span>
          </span>
        </div>
      </div>
    </div>
    <div class="field grid">
      <label for="comments" class="col-12 md:col-3">Comments:</label>
      <div class="p-fluid col-12 md:col-6">
        <Textarea id="comments" :modelValue="comments" v-debounce:300ms="(val) => (comments = val)" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from 'vue'
import { useFlowEditor } from '@/compositions'

import { getDirective as vueDebounce } from 'vue-debounce'

import Calendar from "primevue/calendar"
import InputSwitch from "primevue/inputswitch"
import RadioButton from "primevue/radiobutton"
import InputText from 'primevue/inputtext'
import Textarea from "primevue/textarea"
import ViewFlagRow from '@/pages/portalmanager/menus/components/medias/components/tableRows/ViewFlagRow.vue'

export default {
  name: 'MenuParametersGeneralInformation',
  props: { editorKey: String },
  setup (props) {
    const pxstream = inject('pxstream')
    const fatherType = inject('fatherType')

    const parametersEditor = computed(() => useFlowEditor(props.editorKey))

    const isTvSeries = computed(() => fatherType.value === 'TV Series')
    const isTvSimple = computed(() => parametersEditor.value?.fieldGet('media.mediaType') === 'tv-simple')

    const playPeriodFrom = computed({
      get () {
        const date = parametersEditor.value?.fieldGet('playPeriodFrom')
        return date ? new Date(date) : ''
      },
      set (value) { parametersEditor.value?.fieldSet({ field: 'playPeriodFrom', value: pxstream.tools.toSimpleDate(value) }) }
    })

    const playPeriodTo = computed({
      get () {
        const date = parametersEditor.value?.fieldGet('playPeriodTo')
        return date ? new Date(date) : ''
      },
      set (value) { parametersEditor.value?.fieldSet({ field: 'playPeriodTo', value: pxstream.tools.toSimpleDate(value) }) }
    })

    const featureID = computed({
      get () { return parametersEditor.value?.fieldGet('featureId') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'featureId', value }) }
    })

    const tvSeriesfeatureID = computed({
      get () { return parametersEditor.value?.fieldGet('tvSeriesFeatureId') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'tvSeriesFeatureId', value }) }
    })
  
    const hasPriorityEnabled = computed({
      get () { return parametersEditor.value?.fieldGet('hasPriorityEnabled') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'hasPriorityEnabled', value }) }
    })

    const trailerOptions = computed(() => parametersEditor.value?.fieldGet('media.media.trailer.media.mediafiles') || [])

    const trailer = computed({
      get () { return parametersEditor.value?.fieldGet('trailer') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'trailer', value }) }
    })
    const hasTrailerEnabled = computed({
      get () { return parametersEditor.value?.fieldGet('hasTrailerEnabled') },
      set (value) {
        if(value && trailerOptions.value.length > 0) {
          trailer.value = trailerOptions.value[0]
        }
        parametersEditor.value?.fieldSet({ field: 'hasTrailerEnabled', value })
      }
    })


    const comments = computed({
      get () { return parametersEditor.value?.fieldGet('comments') },
      set (value) { parametersEditor.value?.fieldSet({ field: 'comments', value }) }
    })


    return {
      isTvSimple,
      isTvSeries,
      playPeriodFrom,
      playPeriodTo,
      featureID,
      tvSeriesfeatureID,
      hasPriorityEnabled,
      hasTrailerEnabled,
      trailer,
      comments,
      trailerOptions
    }
  },
  directives: {
    debounce: vueDebounce(3)
  },
  components: {
    Calendar,
    InputSwitch,
    RadioButton,
    Textarea,
    InputText,
    ViewFlagRow
  }
}
</script>

import { watchEffect } from 'vue'
import { createI18n } from 'vue-i18n/index'
import { useLocalStorage } from '@vueuse/core'
import { messages, fallbackLocale } from '@/locales'

export const supportedLanguages = ['en', 'fr']

const getDeviceLanguage = () => {
  const deviceLang = navigator.language.substring(0,2)
  if (supportedLanguages.includes(deviceLang)) {
    return deviceLang
  }
  return fallbackLocale
}

export const locale = useLocalStorage('i18n.lang', getDeviceLanguage())
const languageParam = new URLSearchParams(window.location.search).get('lang')

const setI18nLanguage = function (i18n, locale) {
  i18n.global.locale.value = locale
  document.querySelector('html').setAttribute('lang', locale)
}

function setupI18n(options = { locale: fallbackLocale }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

const i18n = setupI18n({
  locale: languageParam ? languageParam.substring(0, 2) : locale.value,
  fallbackLocale,
  legacy: false,
  globalInjection: true,
  messages,
})

watchEffect(() => {
  locale.value = i18n.global.locale.value
})

const useI18n = () => {
  return {
    i18n,
    locale,
    setLocale: (locale) => setI18nLanguage(i18n, locale),
  }
}

export default useI18n

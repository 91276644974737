<template>
  <div class="grid" v-if="editorKey">
    <div class="col-12 flex justify-content-end">
      <LanguageSelector v-model="currentLang" :onlyLanguages="languages" :getOnlyID="false" :sort="sort" />
    </div>
    <div class="col-12">
      <div class="field grid">
        <label for="enable" class="col-12 md:col-3 font-bold">Enable:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputSwitch id="enable"
            v-model="enableToggle" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="displayTitle" class="col-12 md:col-3 font-bold">Display Title:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputText id="displayTitle" :modelValue="displayTitle"
            v-debounce:300ms="(val)=>(displayTitle = val)" :placeholder="displayTitlePlaceholder" />
            <small v-if="displayTitleError" class="p-error">{{displayTitleError}}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="type" class="col-12 md:col-3 font-bold">Type:</label>
        <div class="col md:col-8">
          <span class="p-fluid capitalize">{{type}}</span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid align-items-start">
        <label for="description" class="col-12 md:col-3">Description:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <Textarea id="description" rows="5" cols="30" :modelValue="displayDescription"
            v-debounce:300ms="(val)=>(displayDescription = val)" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="template" class="col-12 md:col-3">Template:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <AutoComplete id="template" v-model="template" dropdown :suggestions="templateOptions" @complete="searchTemplate($event)" field="name" itemValue="iso" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="typeIcon" class="col-12 md:col-3">Type Icon:</label>
        <div class="col md:col-8">
          <Dropdown id="typeIcon" v-model="iconType" :options="typeIconOptions" optionValue="iso" optionLabel="name" :showClear="true" placeholder="Select icon type" class="w-full" />
        </div>
      </div>
    </div>

    <div class="col-12" v-if="iconType === 'CSS' ">
      <div class="field grid">
        <label for="ion-icon" class="col-12 md:col-3">Ion-Icon:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputText id="cssIcon" :modelValue="cssIcon" v-debounce:300ms="(val)=>(cssIcon = val)" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="image" class="col-12 md:col-3">Image:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <Skeleton class="w-12 h-5rem" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid justify-content-center">
        <div class="grid mt-2">
          <div class="flex justify-content-center mt-4" v-tooltip.top="{ value: 'Please delete children sections or move it', disabled: !hasChildren }">
            <Button icon="pi pi-minus" label="Delete Item" class="p-button-outlined"
              :class="hasChildren ? 'pointer-events-none p-button-secondary' : 'p-button-danger'"
              @click="confirmRemove()" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="grid">
    <div class="col-12 flex justify-content-center"><span>No Selected Section</span></div>
  </div>
</template>

<script>
import { ref, watch, computed, inject } from 'vue'

import { useFlowEditor } from '@/compositions/useFlowEditor'
import Skeleton from 'primevue/skeleton'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import AutoComplete from 'primevue/autocomplete'
import Dropdown from 'primevue/dropdown'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'
import { useVuelidate } from '@vuelidate/core'
import { getDirective as vueDebounce } from 'vue-debounce'

const KFlow = "portalmanager:menu-item"

const defaultI18nValue = {displayTitle: '', description: ''}

const componentName = 'EditingSectionFolder'

export default {
  name: componentName,
  components: { Skeleton, InputSwitch, InputText, Textarea, AutoComplete, Dropdown, LanguageSelector },
  props: { editorKey: String, hasChildren: Boolean },
  directives: {
    debounce: vueDebounce(3)
  },
  setup (props) {
    const onMediaSectionRemove = inject('onMediaSectionRemove')

    const languages = inject('languages')
    const defaultLanguage = inject('defaultLanguage')

    const currentLang = ref(defaultLanguage.value)
    const typeIcon = ref('')
    const typeIconOptions  = ref(RscTypeIcons)
    const templateOptions = ref([])

    const showDeleteDialog = ref(false)

    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save, set/get/has Error
    const { fieldSet, fieldGet, setError, ...flowEditorAPI } = useFlowEditor(props.editorKey)

    const i18n = computed({
      get () { return fieldGet('i18n') }
    })

    const initI18n = () => {
      const i18n = fieldGet('i18n')
      if(!i18n) {
        if (defaultLanguage.value.id === currentLang.value.id) {
          fieldSet({field: 'i18n', value: {
            [currentLang.value.id]: {displayTitle: fieldGet('name'), description: ''}
          }})
        } else {
          fieldSet({field: 'i18n', value: {
            [currentLang.value.id]: JSON.parse(JSON.stringify(defaultI18nValue))
          }})
        }
      } else if (!i18n[currentLang.value.id]) {
        fieldSet({field: `i18n.${currentLang.value.id}`, value: JSON.parse(JSON.stringify(defaultI18nValue))})
      }
    }

    watch(currentLang, initI18n)

    initI18n()

    flowEditorAPI.v$ = useVuelidate({
      i18n: {
        customRequired: (value) => {
          return value[defaultLanguage.value.id]?.displayTitle
        }
      }
    }, { i18n })

    flowEditorAPI.v$.value.$invalid ? setError(new Error('InvalidForm'), componentName) : setError(null, componentName)

    return {
      onMediaSectionRemove,
      languages,
      defaultLanguage,
      currentLang,
      typeIcon,
      typeIconOptions,
      templateOptions,
      showDeleteDialog,
      fieldSet,
      fieldGet,
      setError,
      ...flowEditorAPI
    }
  },
  computed: {
    id: {
      get () { return this.fieldGet('id') }
    },
    name: {
      get () { return this.fieldGet('name') },
      set (value) { this.fieldSet({ field: 'name', value }) }
    },
    enableToggle: {
      get () { return this.fieldGet('isEnabled') },
      set (value) { this.fieldSet({ field: 'isEnabled', value }) }
    },
    displayTitlePlaceholder: { get () { return this.name } },
    displayTitle: {
      get () { return this.fieldGet(`i18n.${this.currentLang.id}.displayTitle`) },
      set (value) { 
        this.fieldSet({ field: `i18n.${this.currentLang.id}.displayTitle` , value })
        if (this.currentLang.id === this.defaultLanguage.id) {
          this.name = value
        }
      }
    },
    type: {
      get () { return this.fieldGet('dataType') },
      set (value) { this.fieldSet({ field: 'dataType', value }) }
    },
    displayDescription: {
      get () { return this.fieldGet(`i18n.${this.currentLang.id}.description`) },
      set (value) { this.fieldSet({ field: `i18n.${this.currentLang.id}.description`, value }) }
    },
    template: {
      get () { return this.fieldGet('data.template') },
      set (value) { this.fieldSet({ field: 'data.template', value }) }
    },
    iconType: {
      get () { return this.fieldGet('iconType') === "" ? null : this.fieldGet('iconType') },
      set (value) { this.fieldSet({ field: 'iconType', value }) }
    },
    cssIcon: {
      get () { return this.fieldGet('cssIcon') },
      set (value) { this.fieldSet({ field: 'cssIcon', value }) }
    },
    displayTitleError () {
      if (this.v$.i18n.customRequired.$invalid) return `${this.defaultLanguage.name} display title is required`
      return ''
    },
  },
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), componentName) : this.setError(null, componentName)
    }
  },
  methods: {
    async searchTemplate ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data} = await this.$pxstream.resource.getTemplates(qryBuilder.build())
        this.templateOptions = data
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get labs', detail: err.toString(), life: 4000})
      }
    },
    confirmRemove () {
      this.$confirm.require({
        message: `Are you sure you want to delete this media section?`,
        header: `Delete ${this.name}?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.onMediaSectionRemove(this.id, KFlow)
        },
        reject: () => {}
      })
    },
    sort (a, b) {
      return this.languages.findIndex(val => val === a.id) - this.languages.findIndex(val => val === b.id)
    }
  }
}

const RscTypeIcons = [
  { iso: 'ImageFile', name: 'Image File' },
  { iso: 'CSS', name: 'CSS'},
  { iso: 'SVG', name: 'SVG'},
  { iso: 'None', name: 'none'},
]
</script>

<template>
  <tr v-if="currentEncoding" role="row">
    <td class="column-title" role="cell">
      <span>{{ label }}</span>
    </td>
    <td class="column-data flex" role="cell">
      {{ currentEncoding }}
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    encoding: String,
    label: String,
  },
  setup(props) {
    const RscEncodings = [
      {id: "LeftMono", name: "Left-Mono"},
      {id: "RightMono", name: "Right-Mono"},
      {id: "Stereo", name: "Stereo"},
      {id: "Unused", name: "Unused"}
    ]

    const currentEncoding = computed(() => {
      return RscEncodings.find(rscEncoding => rscEncoding.id === props.encoding)?.name || ''
    })

    return {
      currentEncoding
    }
  },
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>

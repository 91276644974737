import { useFlowBuilder } from './useFlowEditor'

const KFlow = 'portalmanager:mediafile'

export function useFlowPMMediafileBuilder({propDoc, editorMain, onFieldChange, onUpdateChange, initFields}) {
  return useFlowBuilder(KFlow, propDoc, {
    editorMain,
    onCreate: function onCreate (doc) {
      return {
        contentType: doc.contentType.id
      }
    },
    onFieldChange: (field, value) => {
      onFieldChange({field, value})
    },
    onUpdateChange: ({hasUpdate, at}) => {
      onUpdateChange({hasUpdate, at})
    },
    actionFields: [
      { field: 'content.audioTracks', action: 'languages-to-ids' },
      { field: 'content.burnedSubs', action: 'languages-to-ids' },
      { field: 'content.dynamicSubs', action: 'to-raw' }
    ],
    initFields
  })
}

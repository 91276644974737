
class Service {
  constructor(http, tools) {
    this.http = http
    this.tools = tools
  }

  async export (params) {
    const { data } = await this.http.core.post(`/services/exporter/request/register/exports`, params)
    return data
  }

  async getProjectReports (id) {
    const { data } = await this.http.core.get(`/services/exporter/exports/by-project/${id}/flat`)
    return data
  }

  async getProjectVersions (ProjectID, cycleID, fileType) {
    const { data } = await this.http.core.get(`/services/exporter/exports/by-project/${ProjectID}/versions?cycle=${cycleID}&filetype=${fileType}&state=done`)
    return data
  }

  async getExportSteps (uuid) {
    const { data } = await this.http.core.get(`/services/exporter/exports/${uuid}`)
    return data
  }
  //#endregion
}

export default Service

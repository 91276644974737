<template>
  <div>
    <Dialog
      v-model:visible="showIt"
      style="width:80%"
      @hide="resetFields()"
      modal
      dismissableMask
      :closable="true"
      :draggable="false">
      <template #header>
        <div class="flex flex-auto justify-content-between text-700">
          <h3 class="text-center align-self-center m-0 text-xl font-bold">Add Medias to {{menuName}} > {{menuItemName}}</h3>
          <Button
            v-if="isShoppingCartButtonVisible"
            icon="pi pi-shopping-cart"
            class="flex align-self-center align-items-baseline p-button-primary p-button-outlined border-0 p-button-xl cart-button font-lg"
            :badge="toAddMedia.length.toString()"
            badgeClass="p-badge-danger"
            :disabled="isAddingSelectedMedia || isAddSelectedMediaButtonVisible"
            @click="goToCheckout()"
          />
        </div>
      </template>

      <AddMediaListing :editorKey="editorKey" v-model:selectedMedia="selectedMedia" :mediaAdded="toAddMedia" :removeFromMediaListTrigger="removeFromMediaListTrigger" v-show="isListingMode" />
      <AddMediaCheckout :editorKey="editorKey" v-model:selectedMediafiles="selectedMediafiles" :toAddMedia="toAddMedia" v-show="!isListingMode" />

      <template v-if="isFooterVisible" #footer>
        <div class="flex justify-content-center">
          <Button
            v-if="isAddSelectedMediaButtonVisible"
            :label="'Add to selected media '"
            :badge="selectedMedia.length.toString()"
            badgeClass="p-badge-danger"
            class="flex p-button p-button-outlined font-bold"
            :loading="isAddingSelectedMedia"
            @click="addSelectedMedia()"
          />
          <Button
            v-if="isValidateSelectedMediaButtonVisible"
            label="Validate selected media"
            class="flex p-button text-white font-bold"
            :disabled="isAddingSelectedMedia || isAddSelectedMediaButtonVisible"
            @click="goToCheckout()"
          />
          <Button
            v-if="isBackButtonVisible"
            label="Back"
            class="flex p-button p-button-outlined font-bold"
            @click="goToListing()"
          />
          <Button
            v-if="isAddMediafilesVisible"
            :label="'Add Mediafiles - ' + selectedMediafiles.length"
            class="flex p-button p-component p-button text-white font-bold"
            :loading="isAddingSelectedMediafile"
            :disabled="selectedMediafiles.length === 0"
            @click="addSelectedMediafiles()"
          />
        </div>
      </template>
    </Dialog>

    <Message v-if="errorCaught" severity="error" :closable="false">
      {{ errorCaught }}
    </Message>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import async from 'async'
import { useFlowEditor, useFlowCruder, useFlowBuilder, useFlowPMMediafileBuilder } from '@/compositions'
import { useToast } from 'primevue/usetoast'
import Log from '@/services/logger'
import Message from "primevue/message"
import AddMediaCheckout from './AddMediaCheckout.vue'
import AddMediaListing from './AddMediaListing.vue'

const KFlowPortalmanagerMedia = "portalmanager:media"
const KFlowPortalmanagerMediafile = "portalmanager:mediafile"

export default {
  name: 'AddMediaFromLibraryModal',
  setup: (props, { emit }) => {
    const toast = useToast()
    const pxstream = inject('pxstream')

    const isListingMode = ref(true)
    const toAddMedia = ref([])
    const selectedMedia = ref([])
    const isAddingSelectedMedia = ref(false)
    const isAddingSelectedMediafile = ref(false)
    const removeFromMediaListTrigger = ref(0)
    const selectedMediafiles = ref([])
    const errorCaught = ref('')

    const { fieldGet: fieldGetMain } = useFlowEditor(props.editorMain)

    const { flowGet } = useFlowCruder()

    const menuItemEditor = computed(() => {
      return useFlowEditor(props.editorKey)
    })
    const menuName = computed(() => { return fieldGetMain('name') })
    const menuItemName = computed(() => { return menuItemEditor.value.fieldGet('name') })
    const showIt = computed({
      get () { return props.visible },
      set (value) { emit('update:visible', value) }
    })
    const isAddSelectedMediaButtonVisible = computed(() => { return selectedMedia.value.length > 0 })
    const isValidateSelectedMediaButtonVisible = computed(() => { return toAddMedia.value.length > 0 && isListingMode.value === true })
    const isShoppingCartButtonVisible = computed(() => { return toAddMedia.value.length > 0 })
    const isBackButtonVisible = computed(() => { return isListingMode.value === false })
    const isAddMediafilesVisible = computed(() => { return isListingMode.value === false })
    const isFooterVisible = computed(() => { return isAddSelectedMediaButtonVisible.value || isValidateSelectedMediaButtonVisible.value || isBackButtonVisible.value || isAddMediafilesVisible.value })

    const menuItemMedias = computed({
      get () { return menuItemEditor.value.fieldGet('data.medias') },
      set (value) { menuItemEditor.value.fieldSet({ field: 'data.medias', value }) }
    })

    const menuItemMediafiles = computed({
      get () { return menuItemEditor.value.fieldGet('data.mediafiles') },
      set (value) { menuItemEditor.value.fieldSet({ field: 'data.mediafiles', value }) }
    })

    const menuItemMediafilesTree = computed({
      get () { return menuItemEditor.value.fieldGet('data.mediafilesTree') },
      set (value) { menuItemEditor.value.fieldSet({ field: 'data.mediafilesTree', value }) }
    })

    const menuItemMediafilesSettings = computed({
      get () { return menuItemEditor.value.fieldGet('data.mediafilesSettings') },
      set (value) { menuItemEditor.value.fieldSet({ field: 'data.mediafilesSettings', value }) }
    })

    const addSelectedMedia = async () => {
      isAddingSelectedMedia.value = true
      // We only add low elements. If you selected a TV Serie you will have all TV Simples related to this Serie
      const toAdd = JSON.parse(JSON.stringify(selectedMedia.value.filter(value => value.children.length === 0)))

      const toAddPromises = toAdd.reduce((acc, value) => {
        if (!value.more) {
          acc.push(flowGet(KFlowPortalmanagerMedia, value.id).then(data => {
            value.more = data.data
          }))
        }
        return acc
      }, [])

      const toAddTVSimpleFatherMap = toAdd.reduce((acc, value, idx) => {
        if (value.contentType.id === '4') {
          const tvSeriesID = value.father?.father?.id
          if (tvSeriesID) {
            if (!acc[tvSeriesID]) {
              acc[tvSeriesID] = []
            }
            acc[tvSeriesID].push(idx)
          }
        }
        return acc
      }, {})

      const toAddTVSimpleFatherPromises = Object.keys(toAddTVSimpleFatherMap).map(tvSerieID => {
        return flowGet(KFlowPortalmanagerMedia, tvSerieID).then(data => {
          toAddTVSimpleFatherMap[tvSerieID].forEach(toAddIdx => {
            toAdd[toAddIdx].father.father.more = data.data
            const seasonToAdd = data.data.media.seasons.find(season => season.id === toAdd[toAddIdx].father.id)
            toAdd[toAddIdx].father.more = seasonToAdd
          })
        })
      })

      try {
        await Promise.all(toAddPromises.concat(toAddTVSimpleFatherPromises))
      } catch (e) {
        toast.add({severity: 'error', summary: 'Failed to get media additional data', detail: e.toString(), life: 4000})
        isAddingSelectedMedia.value = false
        return
      }

      removeFromMediaListTrigger.value++

      toAddMedia.value = toAddMedia.value.concat(toAdd)
      isAddingSelectedMedia.value = false
    }

    const goToListing = () => {
      isListingMode.value = true
    }

    const goToCheckout = () => {
      isListingMode.value = false
    }

    const resetFields = () => {
      isListingMode.value = true
      toAddMedia.value = []
      selectedMedia.value = []
      selectedMediafiles.value = []
      isAddingSelectedMedia.value = false
      isAddingSelectedMediafile.value = false
    }

    const addSelectedMediafiles = async () => {
      const { flowGet, flowSave } = useFlowCruder()

      const newMedias = JSON.parse(JSON.stringify(menuItemMedias.value))
      const newMediafiles = JSON.parse(JSON.stringify(menuItemMediafiles.value))
      const newMediafilesTree = JSON.parse(JSON.stringify(menuItemMediafilesTree.value))
      const newMediafilesSettings = JSON.parse(JSON.stringify(menuItemMediafilesSettings.value))

      const mediafileSettingsPostEmptyPromises = []

      isAddingSelectedMediafile.value = true

      errorCaught.value = ''

      selectedMediafiles.value.forEach((pSelectedMediafile) => {
        const mediafile = pSelectedMediafile
        switch (mediafile.media.mediaType) {
          case 'audio-album': {
            if (!newMedias.find(media => media.id === mediafile.media.id)) {
              newMedias.push(mediafile.media)
              const newTreeItem = {
                id: mediafile.media.id,
                collection: KFlowPortalmanagerMedia,
                sortable: false,
                children: []
              }
              mediafile.media.media.tracks.forEach(track => {
                newMedias.push(track)
                newTreeItem.children.push({
                  id: track.id,
                  collection: KFlowPortalmanagerMedia,
                  sortable: true,
                  children: []
                })
              })
              newMediafilesTree.unshift(newTreeItem)
            }

            mediafileSettingsPostEmptyPromises.push(new Promise((resolve, reject) => {
              const tasks = []

              if (mediafile.isNew) {
                tasks.push((pCb) => {
                  pxstream.portalmanager.createEmptyMediafile({contentType: mediafile.mediaChild.contentType.id})
                  .then(({data}) => {
                    const { fieldPush: mediaFieldPush, editorKey: mediaEditorKey } = useFlowBuilder(KFlowPortalmanagerMedia, mediafile.mediaChild, {
                      actionFields: [
                        { field: 'media.mediafiles', action: 'links-to-ids' },
                        { field: 'media.tracks', action: 'links-to-ids' },
                        { field: 'media.seasons', action: 'links-to-ids' }
                      ]
                    })
                    data.id = 'new'
                    const { fieldSet: mfFieldSet } = useFlowPMMediafileBuilder({
                      editorMain: mediaEditorKey,
                      propDoc: data,
                      onFieldChange: () => {},
                      onUpdateChange: () => {}
                    })
                    mfFieldSet({ field: 'filename', value: mediafile.filename })
                    mediaFieldPush({ field: 'media.mediafiles', value: data })
                    pCb(null, mediaEditorKey)
                  }).catch((e) => {
                    errorCaught.value = e.message
                    isAddingSelectedMediafile.value = false
                    pCb(e)
                  })
                })

                tasks.push((editorKey, pCb) => {
                  const { saveFlowBuild } = useFlowEditor(editorKey)
                  try {
                    flowSave(saveFlowBuild('*')).then(() => {
                      pCb(null)
                    })
                  } catch (e) {
                    Log(e)
                    return pCb(e)
                  }
                })

                tasks.push((pCb) => {
                  flowGet(KFlowPortalmanagerMedia, mediafile.mediaChild.id)
                  .then(data => {
                    if (data.data?.media?.mediafiles?.length > 0) {
                      pCb(null, data.data.media.mediafiles[0])
                    }
                  })
                  .catch(pCb)
                })
              } else {
                tasks.push((pCb) => {
                  pCb(null, mediafile.mediafile)
                })
              }
              tasks.push((pMediafile, pCb) => {
                const indexMedia = newMediafilesTree.findIndex(item => item.id === mediafile.media.id)
                const indexMediaTrack = newMediafilesTree[indexMedia].children.findIndex(item => item.id === mediafile.mediaChild.id)
                newMediafilesTree[indexMedia].children[indexMediaTrack].children.push({
                  id: pMediafile.id,
                  collection: KFlowPortalmanagerMediafile,
                  sortable: false,
                  children: []
                })
                newMediafiles.push(pMediafile)
                pCb(null, pMediafile)
              })

              async.waterfall(tasks, error => {
                if (error) {
                  reject(error)
                } else {
                  resolve()
                }
              })
            }))
            break
          }
          case 'tv-simple': {
            if (mediafile.mediaFather) {
              if (!newMedias.find(media => media.id === mediafile.mediaFather.id)) {
                newMedias.push(mediafile.mediaFather)
                newMediafilesTree.unshift({
                  id: mediafile.mediaFather.id,
                  collection: KFlowPortalmanagerMedia,
                  sortable: false,
                  children: []
                })
              }

              const indexMediaFather = newMediafilesTree.findIndex(item => item.id === mediafile.mediaFather.id)
              if (!newMedias.find(media => media.id === mediafile.media.id)) {
                newMedias.push(mediafile.media)
                newMediafilesTree[indexMediaFather].children.push({
                  id: mediafile.media.id,
                  collection: KFlowPortalmanagerMedia,
                  sortable: true,
                  children: []
                })
                newMediafilesTree[indexMediaFather].children.sort((a, b) => {
                  const indexSeasonA = mediafile.mediaFather.media.seasons.findIndex(season => {
                    return season.episodes.find(episode => episode.id === a.id)
                  })
                  const indexSeasonB = mediafile.mediaFather.media.seasons.findIndex(season => {
                    return season.episodes.find(episode => episode.id === b.id)
                  })
                  if (indexSeasonA !== indexSeasonB) {
                    return indexSeasonA-indexSeasonB
                  }
                  const season = mediafile.mediaFather.media.seasons[indexSeasonA]
                  const indexEpisodeA = season.episodes.findIndex(episode => episode.id === a.id)
                  const indexEpisodeB = season.episodes.findIndex(episode => episode.id === b.id)
                  return indexEpisodeA-indexEpisodeB
                })
              }

              mediafileSettingsPostEmptyPromises.push(new Promise((resolve, reject) => {
                const tasks = []

                if (mediafile.isNew) {
                  tasks.push((pCb) => {
                    pxstream.portalmanager.createEmptyMediafile({contentType: mediafile.media.contentType.id})
                    .then(({data}) => {
                      const { fieldPush: mediaFieldPush, editorKey: mediaEditorKey } = useFlowBuilder(KFlowPortalmanagerMedia, mediafile.media, {
                        actionFields: [
                          { field: 'media.mediafiles', action: 'links-to-ids' },
                          { field: 'media.tracks', action: 'links-to-ids' },
                          { field: 'media.seasons', action: 'links-to-ids' }
                        ]
                      })
                      data.id = 'new'
                      const { fieldSet: mfFieldSet } = useFlowPMMediafileBuilder({
                        editorMain: mediaEditorKey,
                        propDoc: data,
                        onFieldChange: () => {},
                        onUpdateChange: () => {}
                      })
                      mfFieldSet({ field: 'filename', value: mediafile.filename })
                      mediaFieldPush({ field: 'media.mediafiles', value: data })
                      pCb(null, mediaEditorKey)
                    }).catch((e) => {
                      errorCaught.value = e.message
                      isAddingSelectedMediafile.value = false
                      pCb(e)
                    })
                  })

                  tasks.push((editorKey, pCb) => {
                    const { saveFlowBuild } = useFlowEditor(editorKey)
                    try {
                      flowSave(saveFlowBuild('*')).then(() => {
                        pCb(null)
                      })
                    } catch (e) {
                      Log(e)
                      return pCb(e)
                    }
                  })

                  tasks.push((pCb) => {
                    flowGet(KFlowPortalmanagerMedia, mediafile.media.id)
                    .then(data => {
                      if (data.data?.media?.mediafiles?.length > 0) {
                        pCb(null, data.data.media.mediafiles[0])
                      }
                    })
                    .catch(pCb)
                  })
                } else {
                  tasks.push((pCb) => {
                    pCb(null, mediafile.mediafile)
                  })
                }

                tasks.push((pMediafile, pCb) => {
                  const indexMediaFather = newMediafilesTree.findIndex(item => item.id === mediafile.mediaFather.id)
                  const indexMedia = newMediafilesTree[indexMediaFather].children.findIndex(item => item.id === mediafile.media.id)
                  newMediafilesTree[indexMediaFather].children[indexMedia].children.push({
                    id: pMediafile.id,
                    collection: KFlowPortalmanagerMediafile,
                    sortable: false,
                    children: []
                  })
                  newMediafiles.push(pMediafile)
                  pCb(null, pMediafile)
                })

                async.waterfall(tasks, error => {
                  if (error) {
                    reject(error)
                  } else {
                    resolve()
                  }
                })
              }))
            } else {
              if (!newMedias.find(media => media.id === mediafile.media.id)) {
                newMedias.push(mediafile.media)
                newMediafilesTree.unshift({
                  id: mediafile.media.id,
                  collection: KFlowPortalmanagerMedia,
                  sortable: true,
                  children: []
                })
              }

              mediafileSettingsPostEmptyPromises.push(new Promise((resolve, reject) => {
                const tasks = []

                if (mediafile.isNew) {
                  tasks.push((pCb) => {
                    pxstream.portalmanager.createEmptyMediafile({contentType: mediafile.media.contentType.id})
                    .then(({data}) => {
                      const { fieldPush: mediaFieldPush, editorKey: mediaEditorKey } = useFlowBuilder(KFlowPortalmanagerMedia, mediafile.media, {
                        actionFields: [
                          { field: 'media.mediafiles', action: 'links-to-ids' },
                          { field: 'media.tracks', action: 'links-to-ids' },
                          { field: 'media.seasons', action: 'links-to-ids' }
                        ]
                      })
                      data.id = 'new'
                      const { fieldSet: mfFieldSet } = useFlowPMMediafileBuilder({
                        editorMain: mediaEditorKey,
                        propDoc: data,
                        onFieldChange: () => {},
                        onUpdateChange: () => {}
                      })
                      mfFieldSet({ field: 'filename', value: mediafile.filename })
                      mediaFieldPush({ field: 'media.mediafiles', value: data })
                      pCb(null, mediaEditorKey)
                    }).catch((e) => {
                      errorCaught.value = e.message
                      isAddingSelectedMediafile.value = false
                      pCb(e)
                    })
                  })

                  tasks.push((editorKey, pCb) => {
                    const { saveFlowBuild } = useFlowEditor(editorKey)
                    try {
                      flowSave(saveFlowBuild('*')).then(() => {
                        pCb(null)
                      })
                    } catch (e) {
                      Log(e)
                      return pCb(e)
                    }
                  })

                  tasks.push((pCb) => {
                    flowGet(KFlowPortalmanagerMedia, mediafile.media.id)
                    .then(data => {
                      if (data.data?.media?.mediafiles?.length > 0) {
                        pCb(null, data.data.media.mediafiles[0])
                      }
                    })
                    .catch(pCb)
                  })
                } else {
                  tasks.push((pCb) => {
                    pCb(null, mediafile.mediafile)
                  })
                }

                tasks.push((pMediafile, pCb) => {
                  const indexMedia = newMediafilesTree.findIndex(item => item.id === mediafile.media.id)
                  newMediafilesTree[indexMedia].children.push({
                    id: pMediafile.id,
                    collection: KFlowPortalmanagerMediafile,
                    sortable: false,
                    children: []
                  })
                  newMediafiles.push(pMediafile)
                  pCb(null, pMediafile)
                })

                async.waterfall(tasks, error => {
                  if (error) {
                    reject(error)
                  } else {
                    resolve()
                  }
                })
              }))
            }
            break
          }
          default: {
            if (!newMedias.find(media => media.id === mediafile.media.id)) {
              newMedias.push(mediafile.media)
              newMediafilesTree.unshift({
                id: mediafile.media.id,
                collection: KFlowPortalmanagerMedia,
                sortable: true,
                children: []
              })
            }

            mediafileSettingsPostEmptyPromises.push(new Promise((resolve, reject) => {
              const tasks = []

              if (mediafile.isNew) {
                tasks.push((pCb) => {
                  pxstream.portalmanager.createEmptyMediafile({contentType: mediafile.media.contentType.id})
                  .then(({data}) => {
                    const { fieldPush: mediaFieldPush, editorKey: mediaEditorKey } = useFlowBuilder(KFlowPortalmanagerMedia, mediafile.media, {
                      actionFields: [
                        { field: 'media.mediafiles', action: 'links-to-ids' },
                        { field: 'media.tracks', action: 'links-to-ids' },
                        { field: 'media.seasons', action: 'links-to-ids' }
                      ]
                    })
                    data.id = 'new'
                    const { fieldSet: mfFieldSet } = useFlowPMMediafileBuilder({
                      editorMain: mediaEditorKey,
                      propDoc: data,
                      onFieldChange: () => {},
                      onUpdateChange: () => {}
                    })
                    mfFieldSet({ field: 'filename', value: mediafile.filename })
                    mediaFieldPush({ field: 'media.mediafiles', value: data })
                    pCb(null, mediaEditorKey)
                  }).catch((e) => {
                    errorCaught.value = e.message
                    isAddingSelectedMediafile.value = false
                    pCb(e)
                  })
                })

                tasks.push((editorKey, pCb) => {
                  const { saveFlowBuild } = useFlowEditor(editorKey)
                  try {
                    flowSave(saveFlowBuild('*')).then(() => {
                      pCb(null)
                    })
                  } catch (e) {
                    Log(e)
                    return pCb(e)
                  }
                })

                tasks.push((pCb) => {
                  flowGet(KFlowPortalmanagerMedia, mediafile.media.id)
                  .then(data => {
                    if (data.data?.media?.mediafiles?.length > 0) {
                      pCb(null, data.data.media.mediafiles[0])
                    }
                  })
                  .catch(pCb)
                })
              } else {
                tasks.push((pCb) => {
                  pCb(null, mediafile.mediafile)
                })
              }

              tasks.push((pMediafile, pCb) => {
                const indexMedia = newMediafilesTree.findIndex(item => item.id === mediafile.media.id)
                newMediafilesTree[indexMedia].children.push({
                  id: pMediafile.id,
                  collection: KFlowPortalmanagerMediafile,
                  sortable: false,
                  children: []
                })
                newMediafiles.push(pMediafile)
                pCb(null, pMediafile)
              })

              async.waterfall(tasks, error => {
                if (error) {
                  reject(error)
                } else {
                  resolve()
                }
              })
            }))
          }
        }
      })

      try {
        await Promise.all(mediafileSettingsPostEmptyPromises)
      } catch (e) {
        isAddingSelectedMediafile.value = false
        return
      }

      menuItemEditor.value.pauseUpdateChecking()
      menuItemMedias.value = newMedias
      menuItemMediafiles.value = newMediafiles
      menuItemMediafilesTree.value = newMediafilesTree
      menuItemMediafilesSettings.value = newMediafilesSettings
      menuItemEditor.value.resumeUpdateChecking()
      resetFields()
      showIt.value = false
    }

    return {
      isListingMode,
      toAddMedia,
      selectedMedia,
      isAddingSelectedMedia,
      isAddingSelectedMediafile,
      removeFromMediaListTrigger,
      selectedMediafiles,
      errorCaught,
      menuName,
      menuItemName,
      showIt,
      isAddSelectedMediaButtonVisible,
      isValidateSelectedMediaButtonVisible,
      isShoppingCartButtonVisible,
      isBackButtonVisible,
      isAddMediafilesVisible,
      isFooterVisible,
      addSelectedMedia,
      goToListing,
      goToCheckout,
      resetFields,
      addSelectedMediafiles
    }
  },
  props: {
    visible: Boolean,
    editorMain: String,
    editorKey: String
  },
  components: {
    Message,
    AddMediaCheckout,
    AddMediaListing
  }
}
</script>

<style scoped>
  .cart-button {
    width: 7rem !important;
  }
</style>
<template>
  <div class="collections-edition panels">
    <draggable v-model="episodeEditor" item-key="id" handle=".handle">
      <template #item="{element, index}">
        <Panel :collapsed="panelIsCollapsed(index)">
          <template #icons>
            <Button class="p-panel-header-icon mr-2" @click="panelToggle(index)">
              <span :class="`pi pi-${panelIsCollapsed(index) ? 'plus' : 'minus'}`"></span>
            </Button>
            <Button class="p-panel-header-icon mr-2" @click="deleteEpisode(element.episode, $event)">
              <span class="pi pi-trash text-danger"></span>
            </Button>
            <OverlayPanel class="delete-overlay" :ref="`op_${element.episode.id}`" :dismissable="true">
              <span @click="onEpisodeRemove(element.episode, $event)" class="cursor-pointer underline text-danger">{{ $t('actions.remove') }}</span>
            </OverlayPanel>
          </template>

          <template #header>
            <div class="flex align-items-center">
              <button class="p-panel-header-icon handle cursor-move">
                <span class="pi pi-bars"></span>
              </button>
              <span class="pl-3" :class="{'p-error': hasLinksError(`${flow}:${element.episode.id}`)}">
                <i v-if="hasLinksError(`${flow}:${element.episode.id}`)" class="mr-1 pi pi-exclamation-triangle"></i>
                <span v-if="element.episodeNumber.value && element.episodeNumber.value > 0">Episode N°{{element.episodeNumber.value}} - </span><span>{{element.name.value}}</span>
              </span>
            </div>
          </template>
          <EditingCore
            :doc="element.episode"
            :editorMain="editorKey"
            :flow="flow"
          />
        </Panel>
      </template>
      <template #footer>
        <div class="flex mt-3">
          <Button
            label="Add Episode"
            class="p-button-outlined"
            icon="pi pi-plus"
            iconPos="left"
            @click="addEpisode"
            />
        </div>
      </template>
    </draggable>
    <EpisodeNew v-model="showNewEpisode" :doc="doc" :editorKey="editorKey" :editorRoot="editorRoot" @onEpisodeAdded="onEpisodeAdded" />
  </div>
</template>

<script>
import { ref, computed, onMounted, inject } from 'vue'
import draggable from 'vuedraggable'

import Panel from 'primevue/panel'
import OverlayPanel from 'primevue/overlaypanel'

import EpisodeNew from '../EpisodeNew.vue'

import { useFlowEditor } from '@/compositions/useFlowEditor'
import { usePanelToggle } from '@/compositions/usePanelToggle'
import { useFlowBuilder } from '@/compositions/useFlowEditor'

const KFlow = "portalmanager:media"

export default {
  name: 'EditingEpisodes',
  props: { editorKey: String, editorRoot: String },
  components: {
    draggable,
    Panel,
    OverlayPanel,
    EpisodeNew
  },
  setup (props) {
    const showNewEpisode = ref(false)
    const updateRuntime = inject('updateRuntime', () => {})

    const {fieldGet, fieldSet, ...useFlowEditorAPI} = useFlowEditor(props.editorKey)

    const episodes = computed({
      get: () => (fieldGet('episodes') || []),
      set: (value) => (fieldSet({field: 'episodes', value }))
    })
    const episodeEditor = computed({
      get: () => {
        return episodes.value.map(episode => {
          const {fieldGet, editorKey} = useFlowBuilder(KFlow, episode, {
            editorMain: props.editorKey,
            onFieldChange: () => {},
            actionFields: [
              { field: 'media.mediafiles', action: 'links-to-ids' },
            ]
          })
          return {
            editorKey,
            episode : episode,
            episodeNumber: computed(() => parseInt(fieldGet(`media.episodeNumber`)) || null),
            name: computed(() => fieldGet(`name`)),
          }
        })
      },
      set: (value) => {
        fieldSet({field: 'episodes', value: value.reduce((reOrderedEpisodes, editor) => {
          reOrderedEpisodes.push(episodes.value.find((e) => (editor.episode.id == e.id)))
          return reOrderedEpisodes
        }, []) })
      }
    })

    onMounted(() => {
      episodes.value.forEach((episode) => {
        updateRuntime(`portalmanager:media:${episode.id}`, episode.media.runtime)
      })
    })

    return {
      flow: KFlow,
      fieldGet,
      fieldSet,
      episodes,
      showNewEpisode,
      ...useFlowEditorAPI,
      ...usePanelToggle(),
      episodeEditor,
      updateRuntime
    }
  },
  methods: {
    addEpisode () {
      this.showNewEpisode = true
    },
    onEpisodeAdded (req) {
      this.updateRuntime(`portalmanager:media:${req.doc.id}`, req.doc.media.runtime)
      this.fieldPush({field: 'episodes', value: req.doc})
      this.saveFlowAddLink(req)
      this.panelSet(this.episodes.length - 1)
    },
    onEpisodeRemove (e, $event) {
      this.updateRuntime(`portalmanager:media:${e.id}`, 0)
      this.fieldSplice({field: 'episodes', filter: (episode) => {
        return episode.id !== e.id
      }})
      this.$refs[`op_${e.id}`].toggle($event);
    },
    deleteEpisode(e, $event) {
      this.$refs[`op_${e.id}`].toggle($event);
    },
    onFieldChange (doc, {field, value}) {
      if (field === 'name') {
        doc.name = value
      }
    }
  },
}
</script>

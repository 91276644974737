<template>
  <tr v-if="currentRatingUS" role="row">
    <td class="column-title" role="cell">
      <span>Rating US:</span>
    </td>
    <td class="column-data flex" role="cell">
      {{ currentRatingUS }}
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    rating: String,
  },
  setup(props) {
    const RscRatingsUS = [
      { id: 'R', name: 'R' },
      { id: 'NR', name: 'NR' },
      { id: 'NR17', name: 'NR-17' },
      { id: 'G', name: 'G' },
      { id: 'PG', name: 'PG' },
      { id: 'PG13', name: 'PG-13' }
    ]

    const currentRatingUS = computed(() => {
      return RscRatingsUS.find(rscRatingUS => rscRatingUS.id === props.rating)?.name || ''
    })

    return {
      currentRatingUS
    }
  },
}
</script>

<style lang="postcss" scoped>
.column-title {
  font-weight: bold;
  font-size: 1rem;
}

.column-data {
  padding-left: 1.5rem;
}
</style>

<template>
  <div v-if="editorKey">
    <div class="flex flex-column flex-column-reverse md:flex-row">
      <div class="w-full fluid">
        <div v-for="metadataKey in metadataKeys" v-bind:key="metadataKey">
          <div v-if="languageById(metadataKey)" class="flex align-items-center">
            <LanguageFlagDisplay :language="languageById(metadataKey)" />
            <span class="ml-3">{{languageById(metadataKey).name}}</span>
          </div>
          <div class="divider"/>
          <div class="field grid">
            <label for="title" class="col-12 mb-2 md:col-2 md:mb-0 font-bold">{{ $t('forms.title') }}</label>
            <div class="col md:col-8">
              <InputText id="title" v-model="metadata[metadataKey].title" @update:modelValue="changeTitle(metadataKey)" type="text" class="w-full"/>
            </div>
          </div>
          <div class="field grid">
            <label for="shortSynopsis" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.shortSynopsis') }}</label>
            <div class="col md:col-8">
              <Textarea id="shortSynopsis" v-model="metadata[metadataKey].shortSynopsis" @update:modelValue="changeShortSynopsis(metadataKey)" class="w-full"/>
            </div>
          </div>
          <div class="field grid">
            <label for="synopsis" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.synopsis') }}</label>
            <div class="col md:col-8" >
              <Textarea id="synopsis" v-model="metadata[metadataKey].synopsis" @update:modelValue="changeSynopsis(metadataKey)" rows="8" class="w-full"/>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4 md:m-0">
        <div class="field grid center">
          <LanguageSelector v-model="language" v-bind:excludedLanguages="metadataKeys" :sort="languageSort"/>
        </div>
        <div class="field grid center">
          <Button 
          label="Add metadata" 
          icon="pi pi-plus" 
          iconPos="right" 
          :disabled="!language"
          @click="initMetadataLanguage"
          />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'

import LanguageFlagDisplay from '@/components/resource/LanguageFlagDisplay.vue'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'

import { useFlowEditor } from '@/compositions/useFlowEditor'

export default {
  name: 'EditingMetadata',
  props: { editorKey: String },
  components: {
    InputText, Textarea, 
    LanguageFlagDisplay, LanguageSelector
  },
  setup (props) {
    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save
    return useFlowEditor(props.editorKey)
  },
  data: () => ({
    language: ''
  }),
  computed: {
    ...mapGetters('resource', ['languages', 'languageById']),
    metadata: {
      get () { return this.fieldGet('metadata') },
      set (val) { this.fieldSet({field: 'metadata', value: val}) }
    },
    metadataKeys: {
      get () { return Object.keys(this.metadata || {}) }
    }
  },
  methods: {
    initMetadataLanguage () {
      this.metadata[this.language] = {
        shortSynopsis: '',
        synopsis: '',
        title: ''
      }

      this.fieldObjSet({field: 'metadata', key: this.language, value: this.metadata[this.language]})

      this.language = null

      window.scrollTo(0, document.body.scrollHeight)
    },
    changeTitle (language) {
      this.fieldSet({field: `metadata.${language}.title`, value: this.metadata[language].title})
    },
    changeShortSynopsis (language) {
      this.fieldSet({field: `metadata.${language}.shortSynopsis`, value: this.metadata[language].shortSynopsis})
    },
    changeSynopsis (language) {
      this.fieldSet({field: `metadata.${language}.synopsis`, value: this.metadata[language].synopsis})
    },
    languageSort (a, b) {
      if (!a.order && b.order) {
        return 1
      } else if (a.order && !b.order) {
        return -1
      } else if (a.order && b.order) {
        return a.order-b.order
      }
      return 0
    }
  },
}
</script>

<style lang="scss" scoped>
  .divider {
    width: 95%;
    border-bottom: 1px solid black;
    margin: 15px 0;
  }
  .center {
    margin-right: auto;
    margin-left: auto;
  }
</style>
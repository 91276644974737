import { ref } from 'vue'
import { useWsckOn } from './useWsck'

import pxstream from '@/services/pxstream'
import Log from '@/services/logger'

export async function userExporterProjectReports(projectId) {
  return pxstream.service.getProjectReports(projectId)
}

export function useExporterProjectEvent(projectId, cb) {
  const lastEvent = ref({})
  const { close: closeWsck } = useWsckOn(`exporter.projects.${projectId}`, (e) => {
    if (cb) cb(e)
    lastEvent.value = e
  }, true)

  const close = () => {
    Log('Cleaning', projectId)
    closeWsck()
  }

  return {
    lastEvent,
    close
  }
}
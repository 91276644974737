
import genericEditor from '../generic/editor'

function updateField (field, value) {
  switch (field) {
    case 'media.year': return value ? parseInt(value) : 0
  }
  return value
}

export function onCreate (doc) {
  const isSeason = (d) => ('episodes' in d)
  return {
    name: doc.name,
    ...(!isSeason(doc) && {contentType: doc.contentType.id}),
    defaultLanguage: doc.defaultLanguage.id
  }
}

export const K_FLOW = 'portalmanager:media'

const generic = genericEditor(K_FLOW, updateField, onCreate)

const store = {
  namespaced: true,
  state: () => ({
    ...generic.state(),
  }),
  getters: {
    ...generic.getters,
  },
  mutations: {
    ...generic.mutations,
  },
  actions: {
    ...generic.actions,
  }
}

export default store

<template>
  <div v-if="editorKey">
    <div class="grid">
      <div class="col-12 md:col-6">

        <!-- Name -->
        <div class="field grid">
          <label for="name" :class="{'p-error': nameError, 'col-12 md:col-3 font-bold': true}">{{ $t('forms.name') }}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="name" type="text"
                :modelValue="name"
                v-debounce:300ms="(val) => (name = val)"
                :class="{'p-invalid': nameError}"
                :disabled="isLoading || isPreviousCycle"
              />
              <small v-if="nameError" class="p-error">{{nameError}}</small>
            </span>
          </div>
        </div>

        <div class="flex justify-content-center">
          <Divider class="w-10" />
        </div>

        <CycleManager :editorKey="editorKey" />

        <div v-if="!isPreviousCycle && hasPreviousCycle" class="flex justify-content-center mt-4">
          <Button :label="$t('menus.updatePreviousCycle')" @click="closeAndUpdateClick" type="button" :class="{'p-button-outlined uppercase': true, 'p-disabled': hasUpdate}" />
        </div>

        <div class="flex justify-content-center">
          <Divider class="w-10" />
        </div>

        <Languages :editorKey="editorKey" />

        <div class="flex justify-content-center">
          <Divider class="w-10" />
        </div>
      </div>
      <div class="col-12 md:col-6">

        <!-- Theme -->
        <Uploads :editorKey="editorKey" />
        <CycleColors :editorKey="editorKey" />

      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { useFlowEditor } from '@/compositions'

import { getDirective as vueDebounce } from 'vue-debounce'

import InputText from "primevue/inputtext"
import Divider from "primevue/divider"
import CycleManager from './components/CycleManager.vue'
import Languages from './components/Languages.vue'
import Uploads from './components/Uploads.vue'
import CycleColors from './components/CycleColors.vue'
import { inject } from 'vue'

export default {
  name: 'MenuGeneralInformation',
  props: { editorKey: String },
  data () {
    return {
      hasPreviousCycle: false
    }
  },
  setup (props) {
    const isPreviousCycle = inject('isPreviousCycle')

    return {
      ...useFlowEditor(props.editorKey),
      v$: useVuelidate(),
      isPreviousCycle
    }
  },
  directives: {
    debounce: vueDebounce(3)
  },
  components: {
    InputText, Divider,
    CycleManager, Languages,
    Uploads, CycleColors
  },
  mounted () {
    this.$pxstream.portalmanager.hasPreviousCycleInOneMenu(this.$route.params.id).then((res) => {
      this.hasPreviousCycle = res.data.id
    })
  },
  methods: {
    async closeAndUpdateClick () {
      this.$confirm.require({
        header: this.$t('menus.updatePreviousCycle'),
        message: this.$t('menus.messageUpdatePreviousCycle'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$router.push({ name: 'PortalManagerMenusEditingPrevious' })
        },
        reject: () => { /* do nothing */ }
      })
    }
  },
  computed: {
    name: {
      get () { return this.fieldGet('name') },
      set (value) { this.fieldSet({ field: 'name', value })}
    },
    isLoading () { return this.isSaving },
    nameError () {
      if (this.v$.name.required.$invalid) return 'Name is required'
      return ''
    }
  },
  watch: {
    'v$.name.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    }
  },
  validations: {
    name: { required }
  },
}
</script>

import genericEditor from '../generic/editor'

function updateField (field, value) {
  switch (field) {
    default: // do thing
  }
  return value
}

function onCreate (resource) {
  return {
    firstname: resource.firstname,
    name: resource.name
  }
}

const generic = genericEditor('resource:actor', updateField, onCreate)

const store = {
  namespaced: true,
  state: () => ({
    ...generic.state()
  }),
  getters: {
    ...generic.getters
  },
  mutations: {
    ...generic.mutations
  },
  actions: {
    ...generic.actions
  }
}

export default store

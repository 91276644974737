<template>
  <div id="mediaMore__details">
    <h4>{{ mediaFileMore.filename }}</h4>
    <div class="p-datatable-wrapper">
      <table role="table" class="p-datatable-table">
        <tbody class="p-datatable-tbody" role="rowgroup">

          <div class="p-datatable-separation" />
          <ViewRow label="Lab" field="name" :data="mediaFileMore.lab" />
          <ViewRow label="Codec" :data="mediaFileMore.content.codec" />
          <ViewVideoBitrateRow label="Bitrate video" :bitrate="mediaFileMore.content.videoBitrate" />
          <ViewRow label="Encrypted" :data="mediaFileMore.content.encrypted" />
          <ViewResolutionRow label="Resolution" :resolution="mediaFileMore.content.resolution" />
          <ViewRatioRow label="Ratio" :ratio="mediaFileMore.content.ratio" />
          <ViewRow label="BoxType" :data="mediaFileMore.content.boxType" />

          <div class="p-datatable-separation" />
          <ViewFlagRow label="Audio Track" field="language.id" :flags="mediaFileMore.content.audioTracks" flagWidth="25px" flagHeight="20px" /> 
          <ViewFlagRow label="Burned In Sub" field="language.id" :flags="mediaFileMore.content.burnedSubs" flagWidth="25px" flagHeight="20px" />
          <ViewFlagRow label="Dynamic Sub" field="language.id" :flags="mediaFileMore.content.dynamicSubs" flagWidth="25px" flagHeight="20px" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ViewRow from '../tableRows/ViewRow.vue'
import ViewFlagRow from '../tableRows/ViewFlagRow.vue'
import ViewRatioRow from '../tableRows/ViewRatioRow.vue'
import ViewResolutionRow from '../tableRows/ViewResolutionRow.vue'
import ViewVideoBitrateRow from '../tableRows/ViewVideoBitrateRow.vue'

export default {
  props: {
    mediaFileMore: {
    type: Object,
    default: () => {}
    }
  },
  components: {
    ViewRow,
    ViewFlagRow,
    ViewRatioRow,
    ViewResolutionRow,
    ViewVideoBitrateRow
  }
}
</script>

<style lang="postcss" scoped>
  h4 {
    font-weight: bold;
  }

  .p-datatable-table * {
    border : 0px !important;
  }

  .p-datatable-separation {
    margin-top: 1rem;
  }
</style>
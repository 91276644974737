
import { reactive } from 'vue'
import { defineAbility } from '@casl/ability';
import http from '@/services/http'
import ErrorService from '@/services/errors';
import Log from '@/services/logger';

class Auth {
  constructor () {
    this.store = reactive({user: null, token: null})
    this.ability = null
  }

  async login ({username, password}) {
    await http.core.post('/session/login', {username, password})
    return this.getMe()
  }

  async logout () {
    try {
      await http.core.post('/session/logout')
    } catch (err) {
      Log.Error('Logout error', err)
    }
    this.store.user = null
    this.ability = null
  }

  async getMe () {
    Log('[auth]getMe')
    return new Promise((resolve, reject) => {
      http.core.get('/session/me')
        .then( ({data}) => {
          this.store.token = data.token
          this.store.user = data.user
          this.update()
          resolve(data)
        })
        .catch(err => {
          this.store.user = null
          this.update()
          reject(err)
        })
    })
  }

  async isLogged () {
    Log('[auth]isLogged')
    if (this.store.user) {
      return new Promise(resolve => resolve(true) )
    } else {
      try {
        await this.getMe()
      } catch (err) {
        ErrorService.ignoreIt(err)
      }
      return !!this.store.user
    }
  }

  isLoggedSync () {
    return !!this.store.user
  }

  getGroups () {
    return this.store.user?.user?.groups || []
  }

  can (...args) {
    if (!this.ability) {
      return false
    }
    return this.ability.can(...args)
  }

  update () {
    if (!this.store.user) {
      this.ability = null
      return
    }
    this.ability = defineAbility((can) => {
      switch (this.store.user.type) {
        // case 'HortusTeam':
        //   can('read', 'landscaper')
        //   can('read', 'hostus-code')
        //   can('read', 'project')
        //   can('read', 'account')
        //   can('read', 'hortus-book')
        //   can('create', 'hostus-code')
        //   break
        // case 'Partner':
        //   can('read', 'hostus-code')
        //   can('create', 'hostus-code')
        //   break
        case 'Landscaper':
          can('read', 'project')
          break
        // case 'HortusCode':
          // can('read', 'project')
          // break
      }
    })
  }
}

const singleton = new Auth()

export const auth = singleton

export default {
  install: (app) => {
    app.config.globalProperties.$auth = singleton
    app.provide('auth', singleton)
  }
}
